import styles from "./Welcome.module.scss";
import logo from "../../assets/images/logo-vertical.svg";
import SubmitButton from "../../components/SubmitButton";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Welcome = () => {
  const { isCookSignUpFromUser } = useSelector((state) => state.auth);

  return (
    <>
      <div className={styles.mainWrapper}>
        <div className={styles.top_wrapper}>
          <img src={logo} alt="MeCook Vertical Logo" className={styles.logo} />
          <p className={styles.greeting}>
            😀 Thank you for submitting your interest in becoming a cook on our
            platform!
          </p>
          <p className={styles.message}>
            Next step, our support team will reach out to you shortly via email
            or phone call to help you with setup and onboarding.
          </p>
        </div>
        <div className={styles.bottom_wrapper}>
          <Link to="/" className={styles.login}>
            <SubmitButton text="Back to Homepage"></SubmitButton>
          </Link>
        </div>
        {/* BELLOW CODE COULD IS NOT VALID AT THIS STAGE */}
        {(typeof isCookSignUpFromUser == "undefined" ||
          isCookSignUpFromUser == null) && (
          <>
            {/* <div className={styles.top_wrapper}>
              <img
                src={logo}
                alt="MeCook Vertical Logo"
                className={styles.logo}
              />
              <p className={styles.greeting}>
                😀 Thank you! Please check your email and verify your account
              </p>
              <p className={styles.message}>
                Once your account is verified successfully, you can login by
                clicking on the button bellow.
              </p>
            </div>
            <div className={styles.bottom_wrapper}>
              <Link to="/login" className={styles.login}>
                <SubmitButton text="Login"></SubmitButton>
              </Link>
            </div> */}
          </>
        )}

        {isCookSignUpFromUser == true && (
          <>
            {/* <div className={styles.top_wrapper}>
              <img
                src={logo}
                alt="MeCook Vertical Logo"
                className={styles.logo}
              />
              <p className={styles.greeting}>
                😀 Thank you for submitting your interest in becoming a cook on
                our platform!
              </p>
              <p className={styles.message}>
                Next step, our support team will reach out to you shortly via
                email to help you with setup and onboarding.
              </p>
            </div> */}
            {/* <div className={styles.bottom_wrapper}>
              <Link to="/cooking/profile" className={styles.login}>
                <SubmitButton text="Cook Dashboard"></SubmitButton>
              </Link>
            </div> */}
          </>
        )}
      </div>
    </>
  );
};

export default Welcome;
