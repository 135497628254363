import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import api from '../../../redux/config/axiosApiConfig';


const initialState = {
    isSuccess: false,
    isError: false,
    errorMessage: null,
    Orders: []
}

export const getUserOrder = createAsyncThunk(
    'userOrder/getUserOrder',
    (currentUserId, thunkAPI) => {
        return api.get('/parse/classes/Order', {
            params: {
                where: {
                    orderBy: {
                        "__type": "Pointer",
                        "className": "_User",
                        "objectId": currentUserId
                    }
                },
                order: "orderDate",
                include: "cookBy"
            }
        }).then((res) => {
            console.log('getUserOrder return data: ', res);
            return res.data.results;
        }).catch((err) => {
            console.log('Error getUserOrder return data: ', err);
            return thunkAPI.rejectWithValue(err.response.data);
        });
    }
);

const userOrderSlice = createSlice({
    name: "userOrder",
    initialState,
    reducers: {},
    extraReducers: {
        [getUserOrder.fulfilled]: (state, action) => {
            state.isSuccess = true;
            state.Orders = action.payload;
        }
    }
})

export default userOrderSlice.reducer;