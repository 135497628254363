import HeaderStandard from "../../components/HeaderStandard";
import { Route, Switch } from "react-router-dom";
import AuthProtectedRoute from "../../hoc/AuthProtectedRoute";
import Footer from "../../components/Footer";
import pageNotFound from "../../routes/404";

import UserLayout from "./UserLayout";
import CookMenu from "../CookMenu";

import Home from "../Home";

const MainLayout = () => {
  return (
    <>
      <HeaderStandard />
      <div className="mainContentWrapper">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/cook/:cookId" component={CookMenu} />
          <AuthProtectedRoute path="/me" component={UserLayout} />

          <Route path="*" component={pageNotFound} />
        </Switch>
      </div>
      <Footer />
    </>
  );
};

export default MainLayout;
