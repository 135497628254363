import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../redux/config/axiosApiConfig";

export const setReadyForReview = createAsyncThunk(
  "applicationProcess/setReadyForReview",
  (data, thunkAPI) => {
    const updateData = {
      applicationStatus: "Pending Review",
    };
    const currentUserId = thunkAPI.getState().auth.currentUser.objectId;
    return api
      .put("/parse/users/" + currentUserId, updateData)
      .then(() => {
        return updateData;
      })
      .catch((err) => {
        console.log("Error setReadyForReview: ", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

const initialState = {
  isSuccess: false,
  isError: false,
  errorMessage: null,
};

const applicationChecklist = createSlice({
  name: "applicationChecklist",
  initialState,
  reducers: {
    cleanUpState: () => {
      return initialState;
    },
  },
  extraReducers: {
    [setReadyForReview.fulfilled]: (state) => {},
  },
});

export default applicationChecklist.reducer;
