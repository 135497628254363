import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isShowPickLocationPopup: false,
  deliveryLocation: "",
  deliveryCoordinates: "",
  isSavedLocationFound: "loading",
  todayDate: "",
  configDateRange: {
    days: 6,
    offset: 2,
  },
  platformConfig: {
    disableDays: ["Monday", "Tuesday", "Friday", "Wednesday"],
  },
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    getTodayDate: (state) => {
      state.todayDate = new Date().toDateString();
    },
    toggleChooseLocationPopup: (state) => {
      state.isShowPickLocationPopup = !state.isShowPickLocationPopup;
    },
    updateDeliveryLocation: (state, action) => {
      state.deliveryLocation = action.payload.deliveryLocation;
      state.deliveryCoordinates = action.payload.deliveryCoordinates;
      state.deliverySuburb = action.payload.deliverySuburb;
      state.placeId = action.payload.placeId;
    },
    saveLocationToLocalStorage: (state) => {
      let delivery_location = {
        location: state.deliveryLocation,
        coordinates: state.deliveryCoordinates,
        suburb: state.deliverySuburb,
        placeId: state.placeId,
      };

      localStorage.setItem(
        "delivery_location",
        JSON.stringify(delivery_location)
      );
      state.isSavedLocationFound = "found";
    },
    getDeliveryLocation: (state) => {
      const delivery_location = JSON.parse(
        localStorage.getItem("delivery_location")
      );
      if (delivery_location != null) {
        state.deliveryLocation = delivery_location.location;
        state.deliveryCoordinates = delivery_location.coordinates;
        state.deliverySuburb = delivery_location.suburb;
        state.isSavedLocationFound = "found";
      } else {
        state.isSavedLocationFound = "notFound";
      }
    },
  },
  extraReducers: {},
});

export const {
  getTodayDate,
  toggleChooseLocationPopup,
  updateDeliveryLocation,
  getDeliveryLocation,
  saveLocationToLocalStorage,
} = generalSlice.actions;

export default generalSlice.reducer;
