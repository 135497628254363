import styles from "./SignUp.module.scss";
import logo from "../../assets/images/logo-vertical.svg";
import SubmitButton from "../../components/SubmitButton";
import { emailSignUp } from "./signUpSlice";
import { Link, useHistory, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import jwt_decode from "jwt-decode";
import FacebookLogin from "react-facebook-login";
import { useGoogleLogin } from "@react-oauth/google";
import api from "../../redux/config/axiosApiConfig";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema as SignUpValidation } from "../../validations/userSignUp";

import {
  userFacebookLink,
  userLogin,
  userGoogleLink,
} from "../Login/loginSlice";

import Loading from "../../components/Loading";

import Footer from "../../components/Footer";

import { cleanUpState } from "./signUpSlice";

const SignUp = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isSuccess, isError, errorMessage } = useSelector(
    (state) => state.signUp
  );
  const { isLoading } = useSelector((state) => state.ui);
  const { isAuth } = useSelector((state) => state.auth);
  const [loginData, setLoginData] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignUpValidation),
  });

  const onSignUpSubmit = ({ email, firstName, lastName, password }) => {
    let SignUpData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
    };
    setLoginData(SignUpData);
    dispatch(emailSignUp(SignUpData));
  };

  const handleFacebookSignIn = (response) => {
    console.log(response);

    let authData = {
      facebook: {
        id: response.id,
        access_token: response.accessToken,
        expiration_date: response.data_access_expiration_time,
      },
    };
    let facebookData = {
      authData: authData,
      rawData: response,
    };
    dispatch(userFacebookLink(facebookData));
  };

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      console.log("codeResponse", codeResponse.code);
      const tokens = await api
        .post("/parse/functions/googleAuth", { code: codeResponse.code })
        .then((res) => {
          return res.data.result;
        });
      console.log("tokens return", tokens.tokens);
      var decoded_data = jwt_decode(tokens.tokens.id_token);
      console.log(decoded_data);
      let authData = {
        google: {
          id: decoded_data.sub,
          access_token: tokens.tokens.access_token,
          id_token: tokens.tokens.id_token,
        },
      };
      let googleData = {
        authData: authData,
        rawData: decoded_data,
      };
      console.log("googleData", googleData);
      dispatch(userGoogleLink(googleData));
    },
  });

  const facebookButton = (
    <FacebookLogin
      appId="225097305757740"
      autoLoad={false}
      fields="name,email,picture"
      callback={handleFacebookSignIn}
      cssClass={styles.facebook}
      textButton="Continue with Facebook"
      icon="fa-facebook"
    />
  );

  useEffect(() => {
    // if (isSuccess) history.push("/");
    if (isSuccess) {
      dispatch(
        userLogin({ username: loginData.email, password: loginData.password })
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    return () => {
      dispatch(cleanUpState());
    };
  }, []);
  console.log("debuging", location);
  useEffect(() => {
    if (isAuth === true) {
      if (location.state && location.state.from) {
        history.push(location.state.from);
      } else {
        history.push("/");
      }
    }
  }, [isAuth]);

  if (isAuth === true) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  return (
    <>
      <div className={styles.mainWrapper}>
        {isLoading && <Loading isOverlay={true}></Loading>}
        <div className={styles.loginPage}>
          <Link to="/">
            <img
              src={logo}
              alt="MeCook Vertical Logo"
              className={styles.logo}
            />
          </Link>

          <div className={styles.loginFormWrapper}>
            <p className={styles.greeting}>Sign Up</p>
            <div className={styles.google} onClick={() => googleLogin()}>
              <i className="lab la-google"></i>
              <span>Continue with Google</span>
            </div>
            {facebookButton}

            <p> Or use your email to Sign Up</p>

            <form
              className={styles.loginForm}
              onSubmit={handleSubmit(onSignUpSubmit)}
            >
              {/* Conditional Rendering Validation Errors */}
              {Object.keys(errors).length !== 0 ? (
                <div className={styles.errorMessages}>
                  {errors.firstName && <p>{errors.firstName.message}</p>}
                  {errors.lastName && <p>{errors.lastName.message}</p>}
                  {errors.email && <p>{errors.email.message}</p>}
                  {errors.password && <p>{errors.password.message}</p>}
                </div>
              ) : null}

              {/* Conditional Rendering SignUp Errors */}
              {isError ? (
                <div className={styles.errorMessages}>
                  <p>{errorMessage}</p>
                </div>
              ) : null}

              <div className={`${styles.inputWrap} ${styles.firstName}`}>
                <i className="las la-user"></i>
                <input
                  type="text"
                  placeholder="First Name"
                  {...register("firstName")}
                />
              </div>
              <div className={`${styles.inputWrap} ${styles.lastName}`}>
                <i className="las la-user"></i>
                <input
                  type="text"
                  placeholder="Last Name"
                  {...register("lastName")}
                />
              </div>
              <div className={`${styles.inputWrap} ${styles.email}`}>
                <i className="las la-envelope"></i>
                <input type="text" placeholder="Email" {...register("email")} />
              </div>
              <div className={`${styles.inputWrap} ${styles.password}`}>
                <i className="las la-lock"></i>
                <input
                  type="password"
                  placeholder="Password"
                  {...register("password")}
                />
              </div>

              <SubmitButton text="Sign Up"></SubmitButton>
            </form>
            <p className={styles.newAccount}>
              {" "}
              Already with MeCook? <Link to="/login">Login</Link>
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default SignUp;
