import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../redux/config/axiosApiConfig";

export const cookInterest = createAsyncThunk(
  "signUpCook/cookInterest",
  (data, thunkAPI) => {
    const sendEmailNotifyResult = api
      .post("/parse/functions/sendNotificationEmail", {
        type: "newCookInterest",
        cookEmail: data.email,
        cookAddress: data.address,
        suburb: data.suburb,
        postCode: data.postCode,
        cookPhone: data.phone,
      })
      .then((res) => {
        return res.data.result;
      })
      .catch((err) => {
        console.log("error send notification email");
        return err.response.data;
      });
    return api
      .post("/parse/functions/addNewCookContactToSendgrid", {
        email: data.email,
        phone: data.phone,
        firstName: data.firstName,
        lastName: data.lastName,
        address: data.address,
        stage: data.stage,
        postCode: data.postCode,
        suburb: data.suburb,
        applicationStatus: "Waiting on Submission",
      })
      .then((res) => {
        return res.data.result;
      })
      .catch((err) => {
        console.log("error adding contact to sendgrid");
        return err.response.data;
      });
  }
);

export const cookRegisterAndSignUp = createAsyncThunk(
  "signUpCook/cookRegisterAndSignUp",
  (data, thunkAPI) => {
    return api
      .post("/parse/users", {
        username: data.email.toLowerCase(),
        email: data.email.toLowerCase(),
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        address: data.address,
        phone: data.phone,
        stage: data.stage,
        postCode: data.postCode,
        suburb: data.suburb,
        deliverySuburb: [data.suburb],
        // location: {
        //     "__type": "GeoPoint",
        //     latitude: data.location.lat,
        //     longitude: data.location.lng,
        // },
        isCook: true,
        applicationStatus: "Waiting on Submission", // Waiting on Submission | Pending Review | Approved
      })
      .then((res) => {
        console.log(" return data cookRegisterAndSignUp: ", res.data);
        // Request add contact to sendgrid
        // const addingResult = api
        //   .post("/parse/functions/addNewCookContactToSendgrid", {
        //     email: data.email,
        //     phone: data.phone,
        //     firstName: data.firstName,
        //     lastName: data.lastName,
        //     address: data.address,
        //     stage: data.stage,
        //     postCode: data.postCode,
        //     suburb: data.suburb,
        //     applicationStatus: "Waiting on Submission",
        //   })
        //   .then((res) => {
        //     return res.data.result;
        //   })
        //   .catch((err) => {
        //     console.log("error adding contact to sendgrid");
        //     return err.response.data;
        //   });
        return res.data;
      })
      .catch((err) => {
        console.log("error cookRegisterAndSignUp: ", err.response.data);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const cookRegisterFromUser = createAsyncThunk(
  "signUpCook/cookRegisterFromUser",
  (data, thunkAPI) => {
    const currentUserId = thunkAPI.getState().auth.currentUser.objectId;
    const updatedData = {
      isCook: true,
      address: data.address,
      applicationStatus: "Waiting on Submission",
    };
    return api
      .put("/parse/users/" + currentUserId, updatedData)
      .then(() => {
        api
          .post("/parse/functions/cookRegisterWelcomeEmail", {
            firstName: thunkAPI.getState().auth.firstName,
          })
          .then()
          .catch();
        return updatedData;
      })
      .catch((err) => {
        console.log("error cookRegisterFromUser", err.response.data);
        return thunkAPI.rejectWithValue(err.rejectWithValue.data);
      });
  }
);

const initialState = {
  isSuccess: false,
  isError: false,
  errorMessage: null,
};

const signUpCookSlice = createSlice({
  name: "signUpCook",
  initialState,
  reducers: {
    cleanUpState: () => {
      return initialState;
    },
  },
  extraReducers: {
    [cookRegisterAndSignUp.fulfilled]: (state) => {
      state.isSuccess = true;
    },
    [cookInterest.fulfilled]: (state) => {
      state.isSuccess = true;
    },
    [cookRegisterAndSignUp.rejected]: (state, action) => {
      state.isError = true;
      switch (action.payload.code) {
        case 202:
          state.errorMessage = "[202] Account already exists for this email.";
          break;
        default:
          state.errorMessage =
            "[" + action.payload.code + "] " + action.payload.error;
          break;
      }
    },

    [cookRegisterFromUser.fulfilled]: (state) => {
      state.isSuccess = true;
    },
    [cookRegisterFromUser.rejected]: (state, action) => {
      state.isError = true;
      switch (action.payload.code) {
        case 202:
          state.errorMessage = "[202] Account already exists for this email.";
          break;
        default:
          state.errorMessage =
            "[" + action.payload.code + "] " + action.payload.error;
          break;
      }
    },
  },
});

export const { cleanUpState } = signUpCookSlice.actions;

export default signUpCookSlice.reducer;
