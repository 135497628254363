import * as Yup from 'yup';


export const validationSchema = Yup.object().shape({
    name: Yup.string().required("* Dish's name is required"),
    cuisineCulture: Yup.string().required("* Cuisine Culture is required"),
    description: Yup.string().required("* Dish's description is required"),
    ingredient: Yup.array().min(1, "* Dish's ingredients is required"),
    portion: Yup.string().required("* Dish's portion is required"),
    spicy: Yup.number().required("* Dish's spicy level is required").typeError("* Please use number from 0->3 for indicating spicy level").max(3,"Spicy level max only 3"),
    price: Yup.number().required("* Dish's price is required").typeError("* Please use number for price"),
    dishPhoto: Yup
    .mixed()
    .test("fileRequired", "* You need to upload a dish's photo", (value) => {
        return value && value.length >= 1;
    })
    .test("fileSize", "* The file is too large, max file size is 1M", (value) => {
        // console.log(value[0].size);
        return value[0] && value[0].size <= 1000000;
    })
    .test("type", "* Only the following formats are accepted: .jpeg, .jpg", (value) => {
        return value[0] && (
            value[0].type === "image/jpeg" ||
            value[0].type === "image/png"
        );
    }),
})