import { configureStore } from "@reduxjs/toolkit";

import signUpSlice from "../routes/SignUp/signUpSlice";
import loginSlice from "../routes/Login/loginSlice";
import userProfileSlice from "../routes/UserAccount/UserProfile/userProfileSlice";
import homeSlice from "../routes/Home/homeSlice";
import cookMenuSlice from "../routes/CookMenu/cookMenuSlice";
import userOrderSlice from "../routes/UserAccount/UserOrder/userOrderSlice";
import cookOrderSlice from "../routes/CookDashboard/CookOrder/CookOrderSlice";
import CookProfileSlice from "../routes/CookDashboard/CookProfile/CookProfileSlice";
import CookMyMenuSlice from "../routes/CookDashboard/CookMyMenu/CookMyMenuSlice";
import EditDishSlice from "../routes/CookDashboard/EditDish/EditDishSlice";
import CreateDishSlice from "../routes/CookDashboard/CreateDish/CreateDishSlice";
import DateFilterSlice from "../components/DateFilter/DateFilterSlice";
import MenuDateFilterSlice from "../routes/CookMenu/components/MenuDateFilter/MenuDateFilterSlice";
import resetPasswordSlice from "../routes/ResetPassword/resetPasswordSlice";
import CookPaymentSlice from "../routes/CookDashboard/CookPayment/CookPaymentSlice";
import CheckoutSlice from "../routes/CookMenu/components/Checkout/CheckoutSlice";
import applicationProcessSlice from "../routes/CookDashboard/ApplicationProcess/ApplicationProcessSlice";
import applicationChecklistSlice from "../routes/CookDashboard/components/ApplicationChecklist/ApplicationChecklistSlice";
import signUpCookSlice from "../routes/SignUpCook/signUpCookSlice";
import datePickingSlice from "../components/DatePicking/DatePickingSlice";

import uiSlice from "./uiSlice";
import authSlice from "./authSlice";
import generalSlice from "./generalSlice";
import formJoinWaitlistSlice from "../components/formJoinWaitlist/formJoinWaitlistSlice";

export default configureStore({
  reducer: {
    general: generalSlice,
    signUp: signUpSlice,
    ui: uiSlice,
    auth: authSlice,
    login: loginSlice,
    userProfile: userProfileSlice,
    home: homeSlice,
    cookMenu: cookMenuSlice,
    userOrder: userOrderSlice,
    cookOrder: cookOrderSlice,
    cookProfile: CookProfileSlice,
    cookMyMenu: CookMyMenuSlice,
    editDish: EditDishSlice,
    createDish: CreateDishSlice,
    dateFilter: DateFilterSlice,
    menuDateFilter: MenuDateFilterSlice,
    resetPassword: resetPasswordSlice,
    cookPayment: CookPaymentSlice,
    checkout: CheckoutSlice,
    applicationChecklist: applicationChecklistSlice,
    signUpCook: signUpCookSlice,
    datePicking: datePickingSlice,
    formJoinWaitlist: formJoinWaitlistSlice,
  },
  devTools: process.env.NODE_ENV === "development",
});
