import styles from "./InputPlacesAutoComplete.module.scss";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

const InputPlacesAutoComplete = ({
  customInputClass,
  onChange,
  preloadData,
  placeholder,
  hasIcon = true,
}) => {
  const dispatch = useDispatch();

  const inputRef = useRef();

  const [address, setAddress] = useState(
    preloadData ? preloadData.Address : ""
  );

  const [selectedAddress, setSelectedAddress] = useState(
    preloadData ? preloadData.Address : ""
  );

  const [returnData, setReturnData] = useState(preloadData ? preloadData : {});

  const options = {
    componentRestrictions: { country: ["au"] },
    types: ["address"],
  };

  const handleAutocompleteSelect = async (address, placeId) => {
    if (placeId) {
      setAddress(address);
      setSelectedAddress(address);
      const geocoderResults = await geocodeByAddress(address);
      const [place] = await geocodeByPlaceId(placeId);
      const Coordinate = await getLatLng(geocoderResults[0]);
      const data = {
        Address: address,
        Coordinates: Coordinate,
        Number: place.address_components.find((c) =>
          c.types.includes("street_number")
        ),
        StreetName: place.address_components.find((c) =>
          c.types.includes("route")
        ),
        Postcode: place.address_components.find((c) =>
          c.types.includes("postal_code")
        ),
        Suburb: place.address_components.find((c) =>
          c.types.includes("locality")
        ),
        Stage: place.address_components.find((c) =>
          c.types.includes("administrative_area_level_1")
        ),
      };
      setReturnData(data);
      // onChange(data);
      // dispatch(updateDeliveryLocation(data));
      // onLocationSelect && onLocationSelect({Coordinate,address});
    }
  };

  const handleOnchangeAddress = (value) => {
    setAddress(value);
    if (Object.keys(returnData).length !== 0) {
      console.log("set object empty");
      setReturnData({});
    }
  };

  useEffect(() => {
    onChange(returnData);
    return () => {
      // cleanup
    };
  }, [returnData]);

  return (
    <div className={styles.outerWrap}>
      <PlacesAutocomplete
        value={address}
        onChange={handleOnchangeAddress}
        onSelect={handleAutocompleteSelect}
        searchOptions={options}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <>
              <div className={styles.inputWrapper}>
                {hasIcon ? <i className="las la-map-marker-alt"></i> : null}
                <input
                  ref={inputRef}
                  type="text"
                  placeholder={placeholder}
                  className={`${styles.locationInput} ${customInputClass}`}
                  {...getInputProps()}
                  value={address}
                />
              </div>
              <div className={styles.suggest_box}>
                {loading ? (
                  <div className={styles.searching}> Searching... </div>
                ) : null}
                {
                  // <div><i className={`las la-map-pin`}></i> <p> test location</p></div>
                  suggestions.map((suggestion, index) => {
                    return (
                      <div
                        key={index}
                        {...getSuggestionItemProps(suggestion)}
                        className={suggestion.active ? styles.active : null}
                      >
                        <i className="las la-map-pin"></i>{" "}
                        <p>{suggestion.description}</p>
                      </div>
                    );
                  })
                }
              </div>
            </>
          );
        }}
      </PlacesAutocomplete>
      <p className={styles.selected}>
        Selected Address:
        <span>
          {" "}
          {selectedAddress == ""
            ? " Please enter your address"
            : selectedAddress}
        </span>
      </p>
    </div>
  );
};

export default InputPlacesAutoComplete;
