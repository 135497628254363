import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../redux/config/axiosApiConfig";

export const getPaymentIntent = createAsyncThunk(
  "checkout/getPaymentIntent",
  (data, thunkAPI) => {
    console.log(data.subTotal);
    return api
      .post("/parse/functions/createStripePaymentIntent", {
        subTotal: data.subTotal,
        userId: data.userId,
        cookName: data.cookName,
        customerName: data.customerName,
        cookEmail: data.cookEmail,
        customerEmail: data.customerEmail,
      })
      .then((res) => {
        return res.data.result;
      })
      .catch((err) => {
        console.log("PaymentIntent error:", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const updatePaymentIntent = createAsyncThunk(
  "checkout/updatePaymentIntent",
  (data, thunkAPI) => {
    console.log(data.subTotal);
    return api
      .post("/parse/functions/updateStripePaymentIntent", {
        subTotal: data.subTotal,
        userId: data.userId,
        paymentIntentsId: data.paymentIntentsId,
      })
      .then((res) => {
        return res.data.result;
      })
      .catch((err) => {
        console.log("PaymentIntent error:", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const verifyPromoCode = createAsyncThunk(
  "checkout/verifyPromoCode",
  (data, thunkAPI) => {
    return api
      .post("/parse/functions/verifyPromoCode", {
        code: data,
        userId: thunkAPI.getState().auth.currentUser.objectId,
      })
      .then((res) => {
        return res.data.result;
      })
      .catch((err) => {
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const orderConfirmationEmail = createAsyncThunk(
  "checkout/orderConfirmationEmail",
  (data, thunkAPI) => {
    return api
      .post("/parse/functions/emailOrderConfirmation", {
        customerName: data.customerName,
        customerEmail: data.customerEmail,
        orderDate: data.orderDate,
        cookName: data.cookName,
        subTotal: data.subTotal,
        orderItems: data.orderItems,
        pickupTime: data.pickupTime,
        deliveryAddress: data.deliveryAddress,
        deliveryFee: data.deliveryFee,
        total: data.total,
        discount: data.discount,
      })
      .then((res) => {
        console.log("orderConfirmationEmail success:", res);
        return res.data.result;
      })
      .catch((err) => {
        console.log("orderConfirmationEmail error:", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const newOrderNotificationEmail = createAsyncThunk(
  "checkout/newOrderNotificationEmail",
  (data, thunkAPI) => {
    return api
      .post("/parse/functions/newOrderNotificationEmail", {
        // cookEmail: data.cookEmail,
        cookEmail: [data.cookEmail, "tony@mecook.app"],
        cookFName: data.cookFName,
        customerFname: data.customerFname,
      })
      .then((res) => {
        return res.data.result;
      })
      .catch((err) => {
        console.log("newOrderNotificationEmail error:", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

const initialState = {
  isSuccess: false,
  isError: false,
  errorMessage: null,
  isCheckout: false,
  isSending: false,
  menuOrderDate: null,
  promoDiscount: 0,
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    cleanUpState: () => {
      return initialState;
    },
    goCheckout: (state, action) => {
      state.isCheckout = true;
      state.menuOrderDate = action.payload.menuDateFilter;
      state.pickupTime = action.payload.pickupTimeSelected;
    },
    goBackMenu: (state) => {
      state.isCheckout = false;
    },
    sendingRequest: (state) => {
      state.isSending = true;
    },
    gotResult: (state) => {
      state.isSending = false;
      state.isError = false;
      state.errorMessage = null;
    },
    paymentSuccess: (state) => {
      state.isSuccess = true;
    },
    showError: (state, action) => {
      state.errorMessage = action.payload;
      state.isError = true;
      state.isSending = false;
      state.isSuccess = false;
    },
  },
  extraReducers: {
    [getPaymentIntent.fulfilled]: (state, action) => {
      state.clientSecret = action.payload.client_secret;
      state.paymentID = action.payload.paymentID;
      state.paymentAmount = action.payload.amount;
      state.transferGroup = action.payload.transfer_group;
    },
    [updatePaymentIntent.fulfilled]: (state, action) => {
      state.clientSecret = action.payload.client_secret;
      state.paymentID = action.payload.paymentID;
      state.paymentAmount = action.payload.amount;
    },
    [verifyPromoCode.pending]: (state) => {
      state.promoCodeLoading = true;
    },
    [verifyPromoCode.fulfilled]: (state, action) => {
      state.promoCodeLoading = false;
      state.promoCodeResult = {
        isValid: action.payload.status,
        value: action.payload.value,
        message: action.payload.message,
      };
      if (action.payload.status) state.promoDiscount = action.payload.value;
    },
    [verifyPromoCode.rejected]: (state, action) => {
      state.promoCodeLoading = false;
      state.promoCodeResult = {
        isValid: false,
        message: "Sorry, something go wrong!",
      };
      state.promoCodeCatchServerError = action.payload.error;
    },
  },
});

export const {
  paymentSuccess,
  cleanUpState,
  goCheckout,
  goBackMenu,
  sendingRequest,
  gotResult,
  showError,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
