import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../redux/config/axiosApiConfig";

export const createDish = createAsyncThunk(
  "CreateDish/createDish",
  (data, thunkAPI) => {
    const currentUser = thunkAPI.getState().auth.currentUser;
    let photo = thunkAPI.getState().createDish.dishPhotoUploaded;
    let currentUserId = currentUser.objectId;
    const createData = {
      name: data.name,
      description: data.description,
      ingredients: data.ingredient,
      portionSize: data.portion,
      spicyLevel: data.spicy.toString(),
      weeklyAvailability: data.availableDays,
      price: parseFloat(data.price),
      Photo: photo,
      cuisineCulture: data.cuisineCulture,
      cookBy: {
        __type: "Pointer",
        className: "_User",
        objectId: currentUserId,
      },
      status: "Waiting Review",
    };
    return api
      .post("/parse/classes/Dish", createData)
      .then((res) => {
        // linking new created dish with current user
        const menuDishes = currentUser.menuDishes;
        const dishId = res.data.objectId;
        var updateMenuDishes = [];
        if (typeof menuDishes !== "undefined") {
          // menuDishes could get resolved and it's defined
          updateMenuDishes = [
            ...menuDishes,
            {
              __type: "Pointer",
              className: "Dish",
              objectId: dishId,
            },
          ];
        } else {
          // menuDishes could NOT get resolved and it's undefined
          updateMenuDishes = [
            {
              __type: "Pointer",
              className: "Dish",
              objectId: dishId,
            },
          ];
        }
        const updatedMenuDishes = {
          menuDishes: updateMenuDishes,
        };
        return api
          .put("/parse/users/" + currentUserId, updatedMenuDishes)
          .then((res) => {
            return updatedMenuDishes;
          })
          .catch((err) => {
            console.log("Error linkDishToCook: ", err);
            return thunkAPI.rejectWithValue(err.response.data);
          });
      })
      .catch((err) => {
        console.log("Error saveDish: ", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const uploadPhoto = createAsyncThunk(
  "CreateDish/uploadPhoto",
  (file, thunkAPI) => {
    return api
      .post("/parse/files/" + file.name, file, {
        headers: {
          "Content-Type": "image/jpeg",
        },
      })
      .then((res) => {
        return {
          name: res.data.name,
          url: res.data.url,
          __type: "File",
        };
      })
      .catch((err) => {
        console.log("uploadPhoto error:", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

const initialState = {
  isSuccess: false,
  isError: false,
  errorMessage: null,
  photoUploaded: false,
  photoUploading: false,
};

const CreateDishSlice = createSlice({
  name: "createDish",
  initialState,
  reducers: {
    cleanUpState: () => {
      return initialState;
    },
  },
  extraReducers: {
    [uploadPhoto.pending]: (state) => {
      state.photoUploading = true;
    },
    [uploadPhoto.fulfilled]: (state, action) => {
      state.dishPhotoUploaded = action.payload;
      state.photoUploaded = true;
      state.photoUploading = false;
    },
    [uploadPhoto.rejected]: (state, action) => {
      state.photoUploading = false;
      state.uploadingError = action.payload.error;
    },

    [createDish.fulfilled]: (state) => {
      state.isSuccess = true;
      state.isError = false;
    },
    [createDish.rejected]: (state) => {
      state.isError = true;
      state.isSuccess = false;
    },
  },
});

export const { cleanUpState } = CreateDishSlice.actions;
export default CreateDishSlice.reducer;
