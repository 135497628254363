import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../redux/config/axiosApiConfig';


export const resetPassword = createAsyncThunk(
    'resetPassword/resetPassword',
    (email,thunkAPI) => {
        return api.post('/parse/requestPasswordReset',{
            email: email,
        }).then((res) => {
            console.log('resetPassword return data: ',res.data);
            return res.data;
        }).catch((err) => {
            console.log('Error resetPassword return data: ',err.response.data);
            return thunkAPI.rejectWithValue(err.response.data);
        })
    }
)

const initialState = {
    isSuccess: false,
    isError:  false,
    errorMessage: null
}

const resetPasswordSlice = createSlice({
    name: "resetPassword",
    initialState,
    reducers: {
        cleanUpState: () => {
            return initialState;
        }
    },
    extraReducers: {
        [resetPassword.fulfilled]: (state) => {
            state.isSuccess = true;
        },
        [resetPassword.rejected]: (state,action) => {
            state.isError = true;
            state.errorMessage = action.payload;
        },
    }
})

export const {cleanUpState} = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
