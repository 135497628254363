import styles from './Order.module.scss';

const Order = () => {
    return (
        <div className={styles.order}>
            <div className={styles.orderHeader}>
                <div className={styles.title}>
                    <span>Orders from</span>
                    <p>Jenny Tran</p>
                </div>
                <div className={styles.orderDate}>08-Aug-21</div>
            </div>
            <div className={styles.orderList}>
                <div className={styles.orderItem}>
                    <div className={styles.dishName}>
                        <p className={styles.orderTitle}>Food's name</p>
                        Nice dish’s tittle put in place
                    </div>
                    <div className={styles.qty}>
                        <p className={styles.orderTitle}>Qty</p>
                        2
                    </div>
                    <div className={styles.subtotal}>
                        <p className={styles.orderTitle}>Subtotal</p>
                        $24.00
                    </div>
                </div>

                <div className={styles.orderItem}>
                    <div className={styles.dishName}>
                        <p className={styles.orderTitle}>Food's name</p>
                        Nice dish’s tittle put in place
                    </div>
                    <div className={styles.qty}>
                        <p className={styles.orderTitle}>Qty</p>
                        2
                    </div>
                    <div className={styles.subtotal}>
                        <p className={styles.orderTitle}>Subtotal</p>
                        $24.00
                    </div>
                </div>

                <div className={styles.orderItem}>
                    <div className={styles.dishName}>
                        <p className={styles.orderTitle}>Food's name</p>
                        Nice dish’s tittle put in place
                    </div>
                    <div className={styles.qty}>
                        <p className={styles.orderTitle}>Qty</p>
                        2
                    </div>
                    <div className={styles.subtotal}>
                        <p className={styles.orderTitle}>Subtotal</p>
                        $24.00
                    </div>
                </div>
            </div>
            <div className={styles.orderTotal}>
                Total: $48.00
            </div>
        </div>
    )
}

export default Order;