import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  dateFilter: "",
};

const dateFilterSlice = createSlice({
  name: "dateFilter",
  initialState,
  reducers: {
    autoSelectHomepageDateFilter: (state, action) => {
      // auto select soonest date in datePickingrange
      var weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";
      let disableDays = action.payload.disabledDays;
      let i = 0;
      var date = new Date(action.payload.datePickingRange[i]);
      while (disableDays.includes(weekday[date.getDay()])) {
        i++;
        date = new Date(action.payload.datePickingRange[i]);
      }
      state.dateFilter = action.payload.datePickingRange[i];
    },
    changeDateFilter: (state, action) => {
      state.dateFilter = action.payload;
    },
  },
});

export const { changeDateFilter, autoSelectHomepageDateFilter } =
  dateFilterSlice.actions;

export default dateFilterSlice.reducer;
