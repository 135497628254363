import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../redux/config/axiosApiConfig";

export const userLogin = createAsyncThunk(
  "login/userLogin",
  (data, thunkAPI) => {
    return api
      .get("/parse/login", {
        params: {
          username: data.username.toLowerCase(),
          password: data.password,
        },
      })
      .then((res) => {
        console.log("userLogin return data: ", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("Error userLogin return data: ", err.response.data);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const userFacebookLink = createAsyncThunk(
  "login/userFacebookLink",
  (facebookData, thunkAPI) => {
    // First check existing email
    return api
      .get("/parse/users", {
        params: {
          where: {
            email: facebookData.rawData.email,
          },
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          // Email registered found
          let userId = res.data.results[0].objectId;
          if (res.data.results[0].authData) {
            // Already Linked
            const linkedAuthData = res.data.results[0].authData;

            // Check if SignUp is Pending
            if (res.data.results[0].facebookId === "SignUp Pending") {
              return {
                status: 201,
                data: { objectId: res.data.results[0].objectId },
                facebookRes: facebookData.rawData,
              };
            } else {
              // All good just Sign In

              // Update User's Photo then SignIn (facebook api always return different picture url with different hash even same picture )
              // so just update then SignIn to keep facebook Picture always Up to Date.

              const updateData = {
                facebookPictureUrl: facebookData.rawData.picture.data.url,
              };
              return api
                .put("/parse/users/" + res.data.results[0].objectId, updateData)
                .then(() => {
                  // Login
                  return api
                    .post("/parse/users", {
                      authData: linkedAuthData,
                    })
                    .then((res) => {
                      console.log(
                        "Already Linked ->Log In user after update Photo"
                      );
                      return {
                        status: res.status,
                        data: res.data,
                        sessionToken: res.data.sessionToken,
                        facebookRes: facebookData.rawData,
                      };
                    })
                    .catch((err) => {
                      console.log(
                        "Error: Already Linked ->Log In user after update Photo",
                        err
                      );
                      return thunkAPI.rejectWithValue(err.response.data);
                    });
                })
                .catch((err) => {
                  console.log("Error update UserPhoto ", err);
                  return thunkAPI.rejectWithValue(err.response.data);
                });
            }
          } else {
            // NOT YET linked -> Link User then Log In
            return api
              .put("/parse/users/" + userId, {
                authData: facebookData.authData,
                facebookId: facebookData.rawData.id,
              })
              .then((res) => {
                // Then Log In User
                return api
                  .post("/parse/users", {
                    authData: facebookData.authData,
                  })
                  .then((res) => {
                    console.log("NOT YET linked -> Link User then Log In");
                    return {
                      status: res.status,
                      data: res.data,
                      sessionToken: res.data.sessionToken,
                      facebookRes: facebookData.rawData,
                    };
                  })
                  .catch((err) => {
                    console.log("Error: NOT YET linked", err);
                    return thunkAPI.rejectWithValue(err.response.data);
                  });
              })
              .catch((err) => {
                return thunkAPI.rejectWithValue(err.response.data);
              });
          }
        } else {
          // Email NOT found
          // Sign Up with Facebook
          return api
            .post("/parse/users", {
              authData: facebookData.authData,
              email: facebookData.rawData.email,
              firstName: facebookData.rawData.first_name,
              lastName: facebookData.rawData.last_name,
              facebookId: facebookData.rawData.id,
              facebookPictureUrl: facebookData.rawData.picture.data.url,
              isCook: false,
            })
            .then((res) => {
              if (res.status === 201) {
                return api
                  .get("/parse/users/" + res.data.objectId)
                  .then((response) => {
                    return {
                      status: res.status,
                      data: response.data,
                      sessionToken: res.data.sessionToken,
                    };
                  });
              }
            })
            .catch((err) => {
              return thunkAPI.rejectWithValue(err.response.data);
            });
        }
      })
      .catch((err) => {
        console.log("checkExistingEmail", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const userGoogleLink = createAsyncThunk(
  "login/userGoogleLink",
  (googleData, thunkAPI) => {
    // First check existing email
    return api
      .get("/parse/users", {
        params: {
          where: {
            email: googleData.rawData.email,
          },
        },
      })
      .then((res) => {
        console.log("checkExistingEmail", res);
        if (res.data.results.length > 0) {
          // Email registered found
          let userId = res.data.results[0].objectId;
          if (res.data.results[0].authData) {
            // Already Linked
            const linkedAuthData = res.data.results[0].authData;

            // All good just Update googlePicture then Sign In

            const updateData = {
              googlePictureUrl: googleData.rawData.picture,
            };
            return api
              .put("/parse/users/" + res.data.results[0].objectId, updateData)
              .then(() => {
                // Login
                return api
                  .post("/parse/users", {
                    authData: linkedAuthData,
                  })
                  .then((res) => {
                    console.log(
                      "Already Linked ->Log In user after update Photo"
                    );
                    return {
                      status: res.status,
                      data: res.data,
                      sessionToken: res.data.sessionToken,
                      googleRes: googleData.rawData,
                    };
                  })
                  .catch((err) => {
                    console.log(
                      "Error: Already Linked ->Log In user after update Photo",
                      err
                    );
                    return thunkAPI.rejectWithValue(err.response.data);
                  });
              })
              .catch((err) => {
                console.log("Error update UserPhoto ", err);
                return thunkAPI.rejectWithValue(err.response.data);
              });
          } else {
            // NOT YET linked -> Link User then Log In
            return api
              .put("/parse/users/" + userId, {
                authData: googleData.authData,
                googleId: googleData.rawData.sub,
              })
              .then((res) => {
                // Then Log In User
                return api
                  .post("/parse/users", {
                    authData: googleData.authData,
                  })
                  .then((res) => {
                    console.log("NOT YET linked -> Link User then Log In");
                    return {
                      status: res.status,
                      data: res.data,
                      sessionToken: res.data.sessionToken,
                      googleRes: googleData.rawData,
                    };
                  })
                  .catch((err) => {
                    console.log("Error: NOT YET linked", err);
                    return thunkAPI.rejectWithValue(err.response.data);
                  });
              })
              .catch((err) => {
                return thunkAPI.rejectWithValue(err.response.data);
              });
          }
        } else {
          // Email NOT found
          // Sign Up with Google
          return api
            .post("/parse/users", {
              authData: googleData.authData,
              email: googleData.rawData.email,
              firstName: googleData.rawData.given_name,
              lastName: googleData.rawData.family_name,
              googleId: googleData.rawData.sub,
              googlePictureUrl: googleData.rawData.picture,
              isCook: false,
            })
            .then((res) => {
              if (res.status === 201) {
                console.log("User created", res);
                return api
                  .get("/parse/users/" + res.data.objectId)
                  .then((response) => {
                    return {
                      status: res.status,
                      data: response.data,
                      sessionToken: res.data.sessionToken,
                    };
                  });
              } else {
                console.log("something wrong here", res);
              }
            })
            .catch((err) => {
              console.log("Catching Errors", err.response.data);
              return thunkAPI.rejectWithValue(err.response.data);
            });
        }
      })
      .catch((err) => {
        console.log("checkExistingEmail", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

const initialState = {
  isSuccess: false,
  isError: false,
  errorMessage: null,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: {
    [userFacebookLink.fulfilled]: (state, action) => {
      if (action.payload.status === 201) {
      }
    },
    [userGoogleLink.fulfilled]: (state, action) => {
      if (action.payload.status === 201) {
      }
    },
    [userLogin.fulfilled]: (state) => {
      state.isSuccess = true;
    },
    [userLogin.rejected]: (state, action) => {
      state.isError = true;
      switch (action.payload.code) {
        case 101:
          state.errorMessage = "Opps! Invalid username/password.";
          break;

        default:
          state.errorMessage =
            "[" + action.payload.code + "] " + action.payload.error;
          break;
      }
    },
  },
});

export default loginSlice.reducer;
