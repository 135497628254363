import styles from './CookAvailability.module.scss';
import styleDashboard from '../CookDashboard.module.scss';

const CookAvailability = () => {
    return (
        <div className={styleDashboard.contentContainer}>
            <p className={styleDashboard.heading}>
                My Availability
            </p>

            <p>This feature is not yet available</p>
        </div>
    )
}

export default CookAvailability;