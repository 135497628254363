import styles from "./HeaderStandard.module.scss";
import logo from "../../assets/images/logo.svg";

import Location from "./components/Location";
import LoginUser from "./components/LoginUser";
import OrderCartLink from "./components/OrderCartLink";
import SearchBox from "./components/SearchBox";
import MobileMenu from "./components/MobileMenu";
import { openMobileMenu } from "../../redux/uiSlice";

import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import { useEffect } from "react";

const HeaderStandard = () => {
  const dispatch = useDispatch();
  const { isAuth, currentUser } = useSelector((state) => state.auth);
  const { currentCart, cook, saveCookId } = useSelector(
    (state) => state.cookMenu
  );
  const { isSavedLocationFound } = useSelector((state) => state.general);
  const { showMobileMenu } = useSelector((state) => state.ui);

  const handleOpenMobileMenu = () => {
    dispatch(openMobileMenu());
  };

  return (
    <>
      <div className={styles.header}>
        {showMobileMenu && isAuth && <MobileMenu></MobileMenu>}
        <div className={styles.left_side}>
          <Link to="/">
            <img src={logo} alt="MeCook logo" className={styles.logo} />
            <span className={styles.logoBeta}>Beta</span>
          </Link>
          <div className={styles.location_comp}>
            <Location></Location>
          </div>
        </div>
        <div className={styles.center}>
          <div className={styles.searchBox_comp}>
            {/* <SearchBox></SearchBox> */}
          </div>
        </div>
        <div className={styles.right_side}>
          {isAuth ? (
            <div className={styles.becomeCook}>
              {!currentUser.isCook && (
                <Link to="/cookOnboarding">
                  <div className={styles.cookApplication}>
                    <i className="las la-mitten"></i> <span>Become a Cook</span>
                  </div>
                </Link>
              )}
              {currentUser.isCook &&
                (currentUser.applicationStatus == "Waiting on Submission" ||
                  currentUser.applicationStatus == "Pending Review") && (
                  <a href="/cooking/profile" target="_blank">
                    <div className={styles.cookApplication}>
                      <i className="las la-id-badge"></i>{" "}
                      <span>Your Application</span>
                    </div>
                  </a>
                )}
              {currentUser.isCook &&
                currentUser.applicationStatus == "waiting menu setup" && (
                  <a href="/cooking/menu" target="_blank">
                    <div className={styles.cookApplication}>
                      <i className="las la-id-badge"></i>{" "}
                      <span>Finish your menu setup</span>
                    </div>
                  </a>
                )}
            </div>
          ) : (
            <div className={styles.becomeCook}>
              <Link to="/cookOnboarding">
                <div className={styles.cookApplication}>
                  <i className="las la-mitten"></i> <span>Become a Cook</span>
                </div>
              </Link>
            </div>
          )}

          {isAuth ? (
            <>
              <div
                className={styles.mobileMenuOpen}
                onClick={handleOpenMobileMenu}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <LoginUser></LoginUser>
            </>
          ) : (
            <p className={styles.loginSignUp}>
              <Link to="/login" className={styles.loginLink}>
                {" "}
                Login{" "}
              </Link>{" "}
              <span>or</span> <Link to="/signup"> Signup </Link>
            </p>
          )}
          <div className={styles.orderCartLink_comp}>
            {currentCart.items.length > 0 ? (
              typeof cook == "undefined" ? (
                <>
                  {/* There is no cook loaded yet, so use cook ID that save on local storage */}
                  <Link to={`/cook/${saveCookId}`} className={styles.loginLink}>
                    <OrderCartLink></OrderCartLink>
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to={`/cook/${cook.objectId}`}
                    className={styles.loginLink}
                  >
                    <OrderCartLink></OrderCartLink>
                  </Link>
                </>
              )
            ) : (
              <>
                <OrderCartLink></OrderCartLink>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderStandard;
