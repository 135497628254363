import styles from './SearchBox.module.scss';

const SearchBox = () => {
    return (
        <div className={styles.search_box_outer_wrap}>
            <i className="las la-search"></i>
            <input type="text" className={styles.search_box} placeholder="Find foods, cooks"></input>
        </div>
    )
}

export default SearchBox