import styles from "./Loading.module.scss";
import { motion } from "framer-motion";

const Loading = ({ isOverlay = true, message }) => {
  const loadingContainerVariants = {
    end: {
      transition: {
        staggerChildren: 0.07,
      },
    },
  };

  const dotVariants = {
    start: {
      y: "0%",
    },
    end: {
      y: "70%",
    },
  };

  const dotTransition = {
    duration: 0.25,
    yoyo: Infinity,
    ease: "easeInOut",
  };

  return (
    <div
      className={`${styles.overlayWrapper} ${isOverlay && styles.isOverlay}`}
    >
      <motion.div
        className={styles.loadingContainer}
        variants={loadingContainerVariants}
        initial="start"
        animate="end"
      >
        <motion.span
          className={styles.dot}
          variants={dotVariants}
          transition={dotTransition}
        ></motion.span>
        <motion.span
          className={styles.dot}
          variants={dotVariants}
          transition={dotTransition}
        ></motion.span>
        <motion.span
          className={styles.dot}
          variants={dotVariants}
          transition={dotTransition}
        ></motion.span>
      </motion.div>
      {message && <p className={styles.mess}>{message}</p>}
    </div>
  );
};

export default Loading;
