import styles from "./MobileMenu.module.scss";
import logoOnly from "../../../../assets/images/Mecook_UI/logoIcon.svg";
import closeIcon from "../../../../assets/images/Mecook_UI/closeIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { userLogout } from "../../../../redux/authSlice";
import { closeMobileMenu } from "../../../../redux/uiSlice";
const MobileMenu = () => {
  const { isAuth, currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = async () => {
    await dispatch(userLogout());
    history.push("/login");
  };
  const handleClose = () => {
    dispatch(closeMobileMenu());
  };
  return (
    <div className={styles.mobileMenu}>
      <img
        src={closeIcon}
        alt="MeCook logo"
        className={styles.closeMenu}
        onClick={handleClose}
      />
      <img src={logoOnly} alt="MeCook logo" className={styles.menuLogo} />
      <div className={styles.menuList}>
        <Link to="/me/profile" onClick={handleClose}>
          <div className={styles.menuItem}>
            <i className="las la-user"></i> <p>Profile</p>
          </div>
        </Link>
        <Link to="/me/order" onClick={handleClose}>
          <div className={styles.menuItem}>
            <i className="las la-utensils"></i> <p>Orders</p>
          </div>
        </Link>
        {/* <div className={styles.menuItem}>
          <i className="las la-bell"></i> <p>Notification</p>
        </div> */}
        {currentUser.isCook && currentUser.applicationStatus == "Approved" && (
          <a href="/cooking/order" target="_blank">
            <div className={`${styles.menuItem} ${styles.red}`}>
              <i className="las la-mitten"></i> <p>Cook Dashboard</p>
            </div>
          </a>
        )}
        {((currentUser.isCook &&
          currentUser.applicationStatus == "Waiting on Submission") ||
          (currentUser.isCook &&
            currentUser.applicationStatus == "Pending Review")) && (
          <a href="/cooking/profile" target="_blank">
            <div className={`${styles.menuItem} ${styles.red}`}>
              <i className="las la-id-badge"></i> <p>Your Application</p>
            </div>
          </a>
        )}
        <Link to="/faq" onClick={handleClose}>
          <div className={styles.menuItem}>
            <i className="las la-life-ring"></i> <p>Help & Support</p>
          </div>
        </Link>
        <div className={styles.menuItem} onClick={handleLogout}>
          <i className="las la-sign-out-alt"></i> <p>Sign Out</p>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
