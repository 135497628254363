import { useEffect, useRef } from 'react';

const useClickOutSide = (handler) => {
    const domRef = useRef();

    useEffect(() => {
        let handleCallback = (event) => {
            if(!domRef.current.contains(event.target))
            {
                handler();
            }
        }

        document.addEventListener('mousedown', handleCallback);
        
        return () => {
            document.removeEventListener('mousedown', handleCallback);
        }
    })

    return {domRef};
}

export default useClickOutSide;