import HeaderStandard from "../../components/HeaderStandard";
import HeaderTeasing from "../../components/HeaderTeasing";
import Footer from "../../components/Footer";
import styles from "./PrivacyPolicy.module.scss";
import PageHeader from "../../layouts/pageHeader";
import PageFooter from "../../layouts/pageFooter";
const PrivacyPolicy = () => {
  return (
    <>
      <PageHeader></PageHeader>
      <div className="informativePage spacing--standardHeader">
        <div className="mainContainer mainContainer--small">
          <h1 className="informativePage__title">Privacy Policy</h1>
          <div className="informativePage__mainContent">
            <h2>
              <strong>Mecook Privacy Policy</strong>
            </h2>

            <p>Date of Last Revision: October 30, 2023</p>

            <p>
              Mecook Pty Ltd (&ldquo;Mecook&rdquo;, &ldquo;we&rdquo; or
              &ldquo;our&rdquo;) acknowledges and respects the privacy of
              individuals. This privacy policy explains how we collect, use,
              disclose, retain and manage your personal information in the
              course of our business.
            </p>

            <p>
              You are not required to provide your personal information to us,
              however, if you don&rsquo;t we may not be able to interact with
              you or it may limit our ability to engage with you and provide you
              access to our services.
            </p>

            <p>
              We reserve the right to make changes to this privacy policy from
              time to time. Any updates will be made available at our website:
              www.mecook.app Please check our website regularly to stay informed
              of any updates. Your continued engagement with Mecook, including
              your use of our Platform (website or any applicable application),
              constitutes your acceptance of the revised privacy policy.
            </p>

            <p>
              If you do not agree with our privacy policy, please do not provide
              us with your personal information or interact with our Platform
              (website or any applicable application).
            </p>

            <h2>
              <strong>Collection of your personal information</strong>
            </h2>

            <p>
              Depending on the service requested by you will determine what
              personal information we may collect. Generally, the kinds of
              personal information that Mecook collects include:
            </p>

            <ul>
              <li>
                contact and identification details (e.g. name, addresses, email
                address and phone number);
              </li>
              <li>gender and date of birth;</li>
              <li>financial information (e.g. credit card details); and</li>
              <li>other information relevant in the circumstances.</li>
            </ul>

            <p>
              &nbsp;We collect personal information in various ways including
              when you:
            </p>

            <ul>
              <li>
                visit our Platform (website or any application managed by us);
              </li>
              <li>interact with our advertisements;</li>
              <li>register to receive marketing communications;</li>
              <li>interact or follow our social media pages or profiles;</li>
              <li>enter into a competition or promotion;</li>
              <li>
                participate in a survey or other market research activities;
              </li>
              <li>
                request information from us or submit any feedback or query;
              </li>
              <li>submit job applications or other forms; and</li>
              <li>
                otherwise provide us with personal information in person or via
                telephone, email, fax, post or other means.
              </li>
            </ul>

            <p>
              In most cases, we collect personal information directly from you.
              There may be some occasions when we collect your personal
              information from other sources. Generally, we will only collect
              your personal information from other sources if it is unreasonable
              or impracticable to collect your personal information from you, or
              if those sources are authorised to do so.
            </p>

            <h2>
              <strong>Use of your personal information</strong>
            </h2>

            <p>
              We use your personal information for the purposes for which it is
              provided to us, other related purposes and as permitted or
              required by law. Generally, we will collect, use and hold your
              personal information for the purposes of:
            </p>

            <ul>
              <li>confirming your identity;</li>
              <li>facilitating payment for our services;</li>
              <li>communicating with you;</li>
              <li>facilitating transactions between buyers and our cooks;</li>
              <li>
                monitoring, auditing and evaluating our products and services;
              </li>
              <li>
                providing you with marketing information about other products or
                services that we be will be of interest to you;
              </li>
              <li>facilitating our internal business operations;</li>
              <li>complying with any legal or regulatory requirements;</li>
              <li>dealing with any complaints or enquiries;</li>
              <li>
                dealing with warranty and maintenance issues or enquiries;
              </li>
              <li>assessing your employment application;</li>
              <li>
                informing you of future activities or events and to facilitate
                improvements to the services provided to you;
              </li>
              <li>identifying users to prepare customised web pages; and</li>
              <li>any other purposes where consent has been provided.&nbsp;</li>
            </ul>

            <h2>
              <strong>Disclosure of personal information</strong>
            </h2>

            <p>
              We disclose your personal information for the same purposes for
              which we collect it. This may include disclosing your personal
              information to:
            </p>

            <ul>
              <li>
                our related entities to facilitate our and their internal
                business processes;
              </li>
              <li>
                third party service providers, who assist us in operating our
                business (including cooks, professional advisors, financial
                advisors and dealers, and technology service providers);
              </li>
              <li>
                state regulators such as the NSW Food Authority only if there is
                a complaint made and we are required to do so legally
              </li>
              <li>
                third party service providers and our related companies for the
                purpose of providing warranty, and maintenance services;
              </li>
              <li>
                our contractors and suppliers, including IT contractors and
                database designers;
              </li>
              <li>
                any other third parties engaged to perform functions or
                activities on our behalf, including direct marketing; and
              </li>
              <li>
                any other third parties engaged to perform administrative or
                other services
              </li>
            </ul>

            <h2>
              <strong>Data Retention And Deletion</strong>
            </h2>

            <p>
              We keep information for as long as reasonably necessary for the
              purposes described in this Privacy Policy, while we have a
              business need to do so, or as required by law (e.g. for tax,
              legal, accounting or other purposes), whichever is the longer
              period of time.
            </p>
            <p>
              Upon your request, we will take steps to delete the information we
              have collected from you; however, we may retain information that
              we have a legal obligation or business need to maintain so long as
              is reasonably necessary. You may request deletion of your
              information by emailing us at support@mecook.app.
            </p>

            <h2>
              <strong>Disclosure overseas</strong>
            </h2>

            <p>We do not disclose personal information overseas.</p>

            <h2>
              <strong>Direct Marketing</strong>
            </h2>

            <p>
              In the event you do not wish to receive marketing communications,
              you can opt-out by contacting us via the contact details set out
              below or through any opt-out mechanism contained in a marketing
              communication to you.
            </p>

            <h2>
              <strong>Sensitive Information</strong>
            </h2>

            <p>
              It is not our general practice to collect sensitive information
              (as defined under the Privacy Act) unless you have consented or
              the collection is required under law or for purpose of defending
              or taking legal action.
            </p>

            <h2>
              <strong>Quality and Security</strong>
            </h2>

            <p>
              We may store personal information on internal computer databases,
              local hard drives and/or in hard copy at our offices, and we
              employ reasonable commercial physical and electronic security
              measures to protect any records that we hold which contain your
              personal information. We may also engage third party service
              providers to assist in storing and processing certain types of
              personal information for us.
            </p>

            <p>
              We take reasonable steps to ensure the security and accuracy of
              personal information we hold, and to protect your personal
              information from misuse and loss, unauthorised access,
              interference, modification or disclosure, in accordance with the
              requirements of the Privacy Act.
            </p>

            <p>
              While we take reasonable steps to protect your personal
              information, when you supply information over the internet you
              understand that we cannot provide a guarantee that your personal
              information will not be accessed by unauthorized third parties.
            </p>

            <h2>
              <strong>Cookies</strong>
            </h2>

            <p>
              A Cookie is a piece of information that our web server may send to
              your machine when you visit one of our websites. The Cookie is
              stored on your machine, but does not identify you.
            </p>

            <p>
              A Cookie helps us to recognise you when you re-visit our websites
              and to co-ordinate your access to different pages on our websites.
              With most Internet Browsers, you can erase Cookies from your
              computer hard drive, block all Cookies, or receive a warning
              before a Cookie is stored.
            </p>

            <h2>
              <strong>Links to other sites</strong>
            </h2>

            <p>
              We may provide links to other websites or platforms. If you use
              these links, you will leave our website and we are not responsible
              for any of these websites or platforms, or their usage of your
              personal information. We recommend that you check the privacy
              policies on those websites.
            </p>

            <h2>
              <strong>Access, and Correction</strong>
            </h2>

            <p>
              You also have a right to access and seek correction of the
              personal information we hold about you. You can do so by by
              contacting us. You may need to provide proof of identity before we
              can grant access or make a requested change. In general, we will
              allow access to the personal information we hold about you,
              however, we may deny you access if we reasonably believe that
              providing access would pose a serious threat to the life or health
              of any individual, providing access would have an unreasonable
              impact upon the privacy of other individuals, or it is otherwise
              appropriate for us to deny access in accordance with the Act.
            </p>

            <p>
              If we agree with your request, we will make any changes
              requested.&nbsp; If we don&rsquo;t agree to make changes, we will
              provide you with the reasons for our decision.
            </p>

            <h2>
              <strong>Concerns or Complaints</strong>
            </h2>

            <p>
              If you have a concern or complaint in relation to our handling of
              your personal information or you believe that we have breached the
              Act or any other applicable privacy laws or codes, you can contact
              us. We will investigate your concerns and will respond to you in
              writing as soon as possible (usually within 14 working days).
              &nbsp;
            </p>

            <p>
              Further information about your privacy rights can be found at the
              website of the Office of the Australian Information Commissioner (
              <a href="http://www.oaic.gov.au/">http://www.oaic.gov.au</a>).
            </p>
          </div>
        </div>
      </div>
      <PageFooter></PageFooter>
    </>
  );
};

export default PrivacyPolicy;
