import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormSubmitButton, FormTextInput } from "../formInput/FormInput";
import { submitFormJoinWaitlist, cleanUpState } from "./formJoinWaitlistSlice";
import validationSchema from "./formValidation";
export const FormJoinWaitlist = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { submitStatus, isError, errorMessage } = useSelector(
    (state) => state.formJoinWaitlist,
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });
  const onFormSubmit = ({ fullName, emailAddress, localSuburb }) => {
    dispatch(submitFormJoinWaitlist({ fullName, emailAddress, localSuburb }));
  };
  // Redirect to thank you page when submitting waitlist successfully
  useEffect(() => {
    if (submitStatus) {
      history.push("/thankyou");
    }
    return () => {
      dispatch(cleanUpState());
    };
  }, [submitStatus]);
  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className=" flex flex-col items-center gap-10 rounded-2xl bg-gray-light px-7 py-10 tablet:px-14 tablet:py-12">
        <p className=" inline-block max-w-[312px] text-center font-Heading text-[25px] font-bold leading-8 text-black">
          We're launching soon. Join the waitlist!
        </p>
        <div className="flex w-full flex-col gap-7">
          <div>
            <FormTextInput
              title="Full name"
              placeholder="Enter your full name"
              {...register("fullName")}
              errors={errors.fullName}
            ></FormTextInput>
          </div>
          <div>
            <FormTextInput
              title="Email address"
              placeholder="Enter your email address"
              name="emailAddress"
              {...register("emailAddress")}
              errors={errors.emailAddress}
            ></FormTextInput>
          </div>
          <div>
            <FormTextInput
              title="Local Suburb"
              placeholder="Enter suburb of your local area"
              name="localSuburb"
              {...register("localSuburb")}
              errors={errors.localSuburb}
            ></FormTextInput>
          </div>
          {/* <div>
            <FormTextInput
              title="What is best describe your interest?"
              placeholder="Delivery home cooked food or selling homemade meal"
              name="interest"
              {...register("interest")}
              errors={errors.interest}
            ></FormTextInput>
          </div> */}
        </div>
        <div className="flex w-full flex-col gap-7">
          <FormSubmitButton text="Join the waitlist"></FormSubmitButton>
          <div className=" max-w-full text-center font-Para text-[15px] leading-[22px] text-gray">
            By submitting this form you consent to us emailing you occasionally
            about our products and services. You can unsubscribe from emails at
            any time, and we will never pass your email to third parties.
          </div>
        </div>
      </div>
    </form>
  );
};
