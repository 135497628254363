import styles from './Location.module.scss';
import LocationSelectModal from '../../../LocationSelectModal';
import {useSelector, useDispatch} from 'react-redux';
import {openLocationModal} from '../../../../redux/uiSlice';
import useClickOutSide from '../../../../hooks/useClickOutSide';
import {useState} from 'react';

const Location = () => {

    const dispatch = useDispatch();
    const {showLocationModal} = useSelector(state => state.ui);
    const { deliveryLocation } = useSelector(state => state.general);
    const [isShowPopup, setIsShowPopup] = useState(true);
    const handleOpenLocationModal = () => {
        dispatch(openLocationModal());
    }
    const toggleLocationPop = () => {
        setIsShowPopup(!isShowPopup);
    }
    const { domRef } = useClickOutSide(() => {
        isShowPopup && toggleLocationPop();
    });

    return (
        <>
            <div className={styles.location} onClick={handleOpenLocationModal}>
                <i className="las la-map-marker"></i>
                <span>{(deliveryLocation!=="") ? (
                    (deliveryLocation.substr(0, deliveryLocation.indexOf(",")).length > 30) ? deliveryLocation.substr(0, 28)+"...": deliveryLocation.substr(0, deliveryLocation.indexOf(","))
                ):"Pick your location"}</span>
                <div className={`${styles.locationPop} ${!isShowPopup && styles.hidden}`} ref={domRef}>
                    <div className={styles.inner}>{(deliveryLocation!=="") ? (
                    (deliveryLocation.substr(0, deliveryLocation.indexOf(",")).length > 20) ? deliveryLocation.substr(0, 18)+"...": deliveryLocation.substr(0, deliveryLocation.indexOf(","))
                ):"Pick your location"}</div>
                </div>
            </div>
            {
                showLocationModal && <LocationSelectModal/>
            }
        </>
    )
}

export default Location