import styles from "./Faq.module.scss";
import { useRef, useState } from "react";
import PageFooter from "../../layouts/pageFooter";
import PageHeader from "../../layouts/pageHeader";

const Faq = () => {
  const handleOpenQuestion = (i) => {
    const faq = faqItemRefs.current[i];
    if (faq.classList.contains(`${styles.opened}`)) {
      faq.classList.remove(`${styles.opened}`);
    } else {
      faq.classList.add(`${styles.opened}`);
    }
  };
  const faqItemRefs = useRef([]);
  const faqItems = [
    {
      q: "What is Mecook?",
      a: "Mecook is an online marketplace for home cooked food, helping to connect passionate cooks with beloved customers in their community, local area, and earn a meaningful income doing what they love.",
    },
    {
      q: "How does cooking on Mecook work?",
      a: "It is easy to sell dishes on Mecook. First, you set up the availability for your dishes then customers will place order from your menu on Mecook (At least one day in advance). We’ll notify you of all orders coming through the platform. You will be planning for your cooking and fulfill the orders freshly on delivery date. We will take care of payment, delivery and customer support.",
    },
    {
      q: "How does delivery work?",
      a: "You just cook and cool your foods, our delivery partners will pickup orders and delivery to your customers on delivery date.",
    },
    {
      q: "How many days a week can I cook?",
      a: "It’s completely up to you! You can cook and sell for 7 days a week or just one day. Feel free to go at your own pace!",
    },
    {
      q: "Can I take a break from cooking?",
      a: "Yes, absolute can! You are your own boss and manage your own schedule. It’s completely fine, if you would like to take a break. We’ll be right here to support whenever you want to come back!",
    },
    {
      q: "How do I get paid?",
      a: "We partner with an online payment system, which automatically issues payments soon after each day that you cook!",
    },
    {
      q: "Who can cook on Mecook?",
      a: "We are welcome everyone have passion for cooking. We work with a wide range of cooks, some of whom have worked in the restaurants and others who just love cooking. Please note: you must be over 18 year old and eligible to work in Australia. You are also required to comply with all legal requirements applicable to the type of food you’re selling in your location. Complete our cook onboarding application then you should be ready to sell food on Mecook.",
    },
    {
      q: "How does payments work when customers place an Order",
      a: "We partner with Stripe to secure our online payments. We put an authorization hold on your credit card when you place an order until your order is confirmed. If your order is canceled before the order cut-off date, the pending charge will drop off in about 3-5 business days depending on your bank.",
    },
    {
      q: "Not found the answer for your question?",
      a: "You could always contact us via email support@mecook.app. Do not hesitate cause we are here for help.",
    },
  ];

  return (
    <>
      <PageHeader></PageHeader>
      <div className={styles.pageTitle_overlayHeader}>
        <h1>Help and Support</h1>
      </div>
      <div className={styles.bodyContent}>
        <div className="mainContainer mainContainer--small">
          <div className={styles.faq}>
            <h2>Frequently Ask Questions</h2>
            {faqItems &&
              faqItems.map((el, i) => {
                return (
                  <div
                    key={i}
                    ref={(el) => {
                      faqItemRefs.current[i] = el;
                    }}
                    className={`${styles.faq__item}`}
                  >
                    <div
                      className={styles.faq__question}
                      onClick={() => {
                        handleOpenQuestion(i);
                      }}
                    >
                      <p>
                        <i className="las la-caret-right"></i> {el.q}
                      </p>
                    </div>
                    <div className={styles.faq__answer}>
                      <p> {el.a}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <PageFooter></PageFooter>
    </>
  );
};

export default Faq;
