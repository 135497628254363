import styles from "./TimePicking.module.scss";
import { useEffect, useState } from "react";
import useClickOutSide from "../../hooks/useClickOutSide";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(isSameOrBefore);

const TimePicking = ({
  onChange,
  customClass,
  minuteStep,
  rangeFrom,
  selectedTime,
  rangeTo,
}) => {
  const [selectOption, setSelectOption] = useState([]);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    selectedTime === "" || typeof selectedTime == "undefined"
      ? "Select time"
      : selectedTime
  );

  const get24hrsRange = (stepInMinute) => {
    var start = dayjs("00:00", "HH:mm");
    var end = dayjs("23:00", "HH:mm");
    var nextTimeSlot = start;
    var timeSlotOptions = new Array();
    while (nextTimeSlot.isSameOrBefore(end)) {
      var nextStepTimeSlot = nextTimeSlot.format("h:mm A") + " - ";
      nextTimeSlot = nextTimeSlot.add(stepInMinute, "minute");
      nextStepTimeSlot += nextTimeSlot.format("h:mm A");
      timeSlotOptions = [...timeSlotOptions, nextStepTimeSlot];
    }
    return timeSlotOptions;
  };

  const setCustomTimeRange = (from, to, stepInMinute) => {
    var start = dayjs(from, "h:mm A");
    var end = dayjs(to, "h:mm A");
    var nextTimeSlot = start;
    var timeSlotOptions = new Array();
    while (nextTimeSlot.isSameOrBefore(end)) {
      var nextStepTimeSlot = nextTimeSlot.format("h:mm A") + " - ";
      nextTimeSlot = nextTimeSlot.add(stepInMinute, "minute");
      nextStepTimeSlot += nextTimeSlot.format("h:mm A");
      timeSlotOptions = [...timeSlotOptions, nextStepTimeSlot];
    }
    return timeSlotOptions;
  };

  const getSelection = () => {
    if (typeof rangeFrom == "undefined") {
      setSelectOption(get24hrsRange(120));
    } else {
      setSelectOption(setCustomTimeRange(rangeFrom, rangeTo, minuteStep));
    }
  };

  const showOptions = () => {
    setOptionsVisible(true);
  };

  const setValue = (value) => {
    setSelectedValue(value);

    onChange(value);
    optionsVisible && setOptionsVisible(false);
  };

  const { domRef } = useClickOutSide(() => {
    optionsVisible && setOptionsVisible(false);
  });

  useEffect(() => {
    getSelection();
    return () => {
      setSelectOption([]);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.time} onClick={showOptions}>
        {selectedValue}
      </div>
      <i className={`${styles.icon} lar la-clock`}></i>
      <div ref={domRef}>
        {optionsVisible && (
          <div className={styles.selectionWrap}>
            <div className={styles.timeList}>
              {selectOption &&
                selectOption.map((item, index) => {
                  return (
                    <span
                      key={index}
                      onClick={() => {
                        setValue(item);
                      }}
                    >
                      {item}
                    </span>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimePicking;
