import axios from "axios";

const API_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY;
const BaseURL = "https://api.stripe.com";

const HEADER = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${API_KEY}`,
};
const api = axios.create({
  baseURL: BaseURL,
  headers: HEADER,
});

export default api;
