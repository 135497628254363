import styles from "./CookSupport.module.scss";
import styleDashboard from "../CookDashboard.module.scss";

const CookSupport = () => {
  return (
    <div className={styleDashboard.contentContainer}>
      <p className={styleDashboard.heading}>Support</p>
      <div className={styleDashboard.section}>
        <p className={styleDashboard.sectionTitle}>Need some help</p>
        <div className={styleDashboard.wrapperBox}>
          <div className={styles.messageBox}>
            <p>
              If you need help and support on something, you can email Mecook
              Support Team at{" "}
              <a href="mailto:support@mecook.app">support@mecook.app</a>
            </p>
            <p>One of our team will get in touch with you shortly!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookSupport;
