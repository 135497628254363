import styles from "./CookProfile.module.scss";
import styleDashboard from "../CookDashboard.module.scss";
import {
  TextInput,
  TextareaInput,
  PickupLocationInput,
  PasswordInput,
} from "../components/InputField";
import SubmitButton from "../../../components/SubmitButton";
import { useSelector, useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema as cookProfileValidation } from "../../../validations/cookProfile.js";

import {
  saveProfileUpdate,
  cleanUpState,
  uploadMenuMainPhoto,
} from "./CookProfileSlice";
import Loading from "../../../components/Loading";

import { uploadUserPhoto } from "../../UserAccount/UserProfile/userProfileSlice";

import { useEffect } from "react";
import { current } from "@reduxjs/toolkit";

const CookProfile = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const { submittingForm, isSaved } = useSelector((state) => state.cookProfile);
  const { userPhotoUploading } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();

  const handleUploadPicture = (file) => {
    const SUPPORTED_FORMATS = [
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png",
    ];
    const maxFileSize = 1000 * 1024; // 1000 kilobytes / 1 megabyte
    let fileType = file.type;
    if (SUPPORTED_FORMATS.includes(fileType) && file.size <= maxFileSize) {
      // Checking file Type before upload
      dispatch(uploadUserPhoto(file));
    } else {
      if (!SUPPORTED_FORMATS.includes(fileType)) {
        alert("Uploading error, file type not supported!");
      } else if (file.size > maxFileSize) {
        alert("Uploading error, file is too large");
      }
    }
  };

  const preLoadedValue = {
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    email: currentUser.email,
    phone: currentUser.phone,
    password: currentUser.password,
    intro: currentUser.cookIntro,
    stage: currentUser.stage,
    postCode: currentUser.postCode,
    suburb: currentUser.suburb,
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(cookProfileValidation),
    defaultValues: preLoadedValue,
  });

  const cookProfileSubmit = ({
    firstName,
    lastName,
    phone,
    intro,
    address,
    coordinates,
    postCode,
    stage,
    suburb,
    password,
  }) => {
    let saveData = {
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      intro: intro,
      address: address,
      location: coordinates,
      postCode: postCode,
      stage: stage,
      suburb: suburb,
    };
    if (password !== null && password !== "") {
      Object.assign(saveData, { password: password });
      console.log("change pass");
    } else {
      console.log("NOT change pass");
    }
    dispatch(saveProfileUpdate(saveData));
  };

  useEffect(() => {
    return () => {
      dispatch(cleanUpState());
    };
  }, []);

  return (
    <div className={styleDashboard.contentContainer}>
      <p className={styleDashboard.heading}>Cook Profile</p>
      <form
        onSubmit={handleSubmit(cookProfileSubmit)}
        className={styles.cookProfileForm}
      >
        {submittingForm && <Loading isOverlay={true}></Loading>}
        <div className={styleDashboard.section}>
          <p className={styleDashboard.sectionTitle}>Information</p>
          <div className={styleDashboard.wrapperBox}>
            <div className={styles.formWrapper}>
              <div className={styles.row2Col}>
                <div className={styles.col}>
                  <div className={styles.profilePhoto}>
                    <div
                      className={styles.avatar}
                      style={
                        currentUser.userPhoto
                          ? {
                              backgroundImage:
                                'url("' + currentUser.userPhoto.url + '")',
                            }
                          : currentUser.facebookPictureUrl && {
                              backgroundImage:
                                'url("' + currentUser.facebookPictureUrl + '")',
                            }
                      }
                    >
                      {currentUser.userPhoto ||
                      currentUser.facebookPictureUrl ? (
                        <>
                          <label
                            className={`${
                              userPhotoUploading
                                ? styles.label
                                : styles.upload_hover
                            }`}
                          >
                            <p>
                              {userPhotoUploading
                                ? "Uploading"
                                : "Upload Picture"}
                            </p>
                            <input
                              type="file"
                              name="updatePhoto"
                              id="profile_picture"
                              {...register("updatePhoto")}
                              onChange={(e) => {
                                handleUploadPicture(e.target.files[0]);
                              }}
                            />
                          </label>
                        </>
                      ) : (
                        <>
                          <label className={styles.label}>
                            <p>
                              {userPhotoUploading
                                ? "Uploading"
                                : "Upload profile photo (max size: 1Mb)"}
                            </p>
                            <input
                              type="file"
                              name="profilePhoto"
                              id="profile_picture"
                              {...register("profilePhoto")}
                              onChange={(e) => {
                                handleUploadPicture(e.target.files[0]);
                              }}
                            />
                          </label>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.col}>
                  <TextInput
                    disabled
                    label="Email"
                    name="email"
                    register={register("email")}
                  ></TextInput>
                </div>
              </div>

              <div className={styles.row2Col}>
                <div className={styles.col}>
                  <TextInput
                    label="First name"
                    name="firstName"
                    register={register("firstName")}
                  ></TextInput>
                </div>
                <div className={styles.col}>
                  <TextInput
                    label="Last name"
                    name="lastName"
                    register={register("lastName")}
                  ></TextInput>
                </div>
              </div>

              <div className={styles.row2Col}>
                <div className={styles.col}>
                  <TextareaInput
                    label="Your Bio"
                    name="intro"
                    register={register("intro")}
                    placeholder="Ex: While growing up in Northern India and traveling across the world, I had the opportunity to work alongside many chefs and have learned to create flavors that will make your mounth water by just the aroma alone. I have incorporated my family’s secret recipes with my knowledge to prepare these dished for you. I am passionate about create delicious flavors by using specialty home-made spices."
                    instruction="A brief paragraph that introduces you to your customers. Focus on what inspires you and the food you are serving"
                  ></TextareaInput>
                </div>
                <div className={styles.col}>
                  <TextInput
                    label="Mobile number"
                    name="phone"
                    register={register("phone")}
                  ></TextInput>
                  <PasswordInput
                    label="New Password"
                    name="password"
                    register={register("password")}
                  ></PasswordInput>
                  <PasswordInput
                    label="Confirm New Password"
                    name="passwordConfirm"
                    register={register("passwordConfirm")}
                  ></PasswordInput>
                </div>
              </div>

              {/* <div className={styles.row2Col}>
                                <div className={styles.col}>
                                    <TextareaInput label="Intro" name="intro" register={register("intro")} placeholder="Ex: While growing up in Northern India and traveling across the world, I had the opportunity to work alongside many chefs and have learned to create flavors that will make your mounth water by just the aroma alone. I have incorporated my family’s secret recipes with my knowledge to prepare these dished for you. I am passionate about create delicious flavors by using specialty home-made spices." instruction="A brief paragraph that introduces you to your customers. Focus on what inspires you and the food you are serving"></TextareaInput>
                                </div>
                                <div className={styles.col}>
                                    <PhotoUpload photo={currentUser.menuCoverPhoto} label="Menu main image" instruction="This image will be used as your menu preview image. So pick the best one to be seen" onChange={(e) => {
                                        handleUploadPicture(e.target.files[0]);
                                    }} />

                                </div>
                            </div> */}

              <div className={styles.row2Col}>
                <div className={styles.col}>
                  <PickupLocationInput
                    instruction="For your privacy, this address will only be shown after order sent"
                    label="Pick-up address"
                    registerAddress={register("address")}
                    registerCoordinates={register("coordinates")}
                    preloadData={
                      currentUser.location && {
                        Address: currentUser.address,
                        Coordinates: {
                          lat: currentUser.location.latitude,
                          lng: currentUser.location.longitude,
                        },
                      }
                    }
                    setValueAddress={(data) => {
                      setValue("address", data);
                    }}
                    setValueCoordinates={(data) => {
                      setValue("coordinates", data);
                    }}
                    setValueFullAddress={(data) => {
                      if (data.hasOwnProperty("Postcode")) {
                        setValue("postCode", data.Postcode.long_name);
                      }
                      if (data.hasOwnProperty("Stage")) {
                        setValue("stage", data.Stage.short_name);
                      }
                      if (data.hasOwnProperty("Suburb")) {
                        setValue("suburb", data.Suburb.short_name);
                      }
                    }}
                  />
                  <input type="hidden" {...register("postCode")} />
                  <input type="hidden" {...register("suburb")} />
                  <input type="hidden" {...register("stage")} />
                  {/* <CuisineInput label="Cuisine's Culture" preloadData={currentUser.cuisine} register={register("cuisine")} setValue={(data) => {
                                        setValue("cuisine", data);
                                    }} instruction="Name all related Cuisine that you would cook. Exp: Asian, Japanese ect."></CuisineInput> */}
                </div>
                <div className={styles.col}>
                  {/* <TextInput instruction="For your privacy, this address will only be shown after order sent" label="Pick-up address" name="phone" register={register("phone")}></TextInput> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Todo - setup pickup time frame window*/}

        {/* <div className={styleDashboard.section}>
                    <p className={styleDashboard.sectionTitle}>Settings</p>
                    <div className={styleDashboard.wrapperBox}>
                        <div className={styles.formWrapper}>
                            <div className={styles.row2Col}>
                                <div className={styles.col}>
                                    <TextInput label="Pre-order cut-off time" name="preOrderCutOff"></TextInput>
                                </div>
                                <div className={styles.col}>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}

        {/* Todo - setup custom cut-off time */}

        {/* <div className={styleDashboard.section}>
                    <p className={styleDashboard.sectionTitle}>Settings</p>
                    <div className={styleDashboard.wrapperBox}>
                        <div className={styles.formWrapper}>
                            <div className={styles.row2Col}>
                                <div className={styles.col}>
                                    <TextInput label="Pre-order cut-off time" name="preOrderCutOff"></TextInput>
                                </div>
                                <div className={styles.col}>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}

        {/* Conditional Rendering Validation Errors */}
        {Object.keys(errors).length !== 0 ? (
          <div className={styles.errorMessages}>
            {errors.firstName && <p>{errors.firstName.message}</p>}
            {errors.lastName && <p>{errors.lastName.message}</p>}
            {errors.email && <p>{errors.email.message}</p>}
            {errors.phone && <p>{errors.phone.message}</p>}
            {errors.intro && <p>{errors.intro.message}</p>}
            {errors.address && <p>{errors.address.message}</p>}
            {errors.passwordConfirm && <p>{errors.passwordConfirm.message}</p>}
            {/* {errors.cuisine && (<p>{errors.cuisine.message}</p>)} */}
          </div>
        ) : null}

        {isSaved && isSaved ? (
          <div className={styles.successMessages}>
            <p>Your changes have been saved, successfully!</p>
          </div>
        ) : (
          <div></div>
        )}
        <SubmitButton text="Save changes"></SubmitButton>
      </form>
    </div>
  );
};

export default CookProfile;
