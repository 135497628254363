export const FormTextInput = ({ title, errors, ...inputProps }) => {
  return (
    <>
      <p className=" mb-2 font-Heading text-base">{title}</p>
      <input
        {...inputProps}
        type="text"
        className=" w-full rounded-md bg-white p-3.5 font-Heading text-base text-black placeholder:text-gray"
      />
      {errors && <p className=" -mb-4 mt-2 text-red">{errors.message}</p>}
    </>
  );
};

export const FormSubmitButton = ({ text, ...inputProps }) => {
  return (
    <>
      <input
        {...inputProps}
        type="submit"
        value={text}
        className=" w-full rounded-lg bg-red px-5 py-4 text-center font-Heading text-base font-semibold text-white hover:cursor-pointer"
      />
    </>
  );
};
