import styles from "./StripeReturnLandingPage.module.scss";
import SubmitButton from "../../components/SubmitButton";
import logo from "../../assets/images/logo-vertical.svg";
import { Link, useHistory, Redirect } from "react-router-dom";
const StripeReturnLandingPage = () => {
  return (
    <>
      <div className={styles.mainWrapper}>
        <div className={styles.stripeReturnLandingPage}>
          <Link to="/">
            <img
              src={logo}
              alt="MeCook Vertical Logo"
              className={styles.logo}
            />
          </Link>

          <div className={styles.contentWrapper}>
            <p className={styles.message}>
              Great! You have connected to Stripe successfully.
            </p>
            <a href="/cooking/payment">
              <SubmitButton text="Go back to Dashboard"></SubmitButton>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default StripeReturnLandingPage;
