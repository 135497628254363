import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const datePickingSlice = createSlice({
  name: "datePicking",
  initialState,
  reducers: {
    loadDatePickingRange: (state, action) => {
      if (!action.payload.preloadData) {
        const numberOfDateRange = action.payload.days;
        const dayOffset = action.payload.offset;
        const todayDate = action.payload.todayDate;
        let startOfDateRange = new Date(todayDate);
        startOfDateRange.setDate(startOfDateRange.getDate() + dayOffset);
        var tempDateRanges = new Array();
        for (let i = 0; i < numberOfDateRange; i++) {
          const dateAfter = new Date(startOfDateRange);
          dateAfter.setDate(startOfDateRange.getDate() + i);
          tempDateRanges = [
            ...tempDateRanges,
            new Date(dateAfter).toDateString(),
          ];
        }
        state.datePickingRange = tempDateRanges;
      } else {
        state.datePickingRange = action.payload.preloadData;
      }
    },
  },
});

export const { loadDatePickingRange } = datePickingSlice.actions;

export default datePickingSlice.reducer;
