import { createSlice } from "@reduxjs/toolkit";

import { emailSignUp } from "../routes/SignUp/signUpSlice";
import {
  userLogin,
  userFacebookLink,
  userGoogleLink,
} from "../routes/Login/loginSlice";
import { resetPassword } from "../routes/ResetPassword/resetPasswordSlice";
import { getAllLocalCook } from "../routes/Home/homeSlice";
import { getMenuBy } from "../routes/CookMenu/cookMenuSlice";
import { getUserOrder } from "../routes/UserAccount/UserOrder/userOrderSlice";
import {
  getNewCookOrder,
  getCookOrder,
  getCookOrderReady,
  getCookOrderDelivered,
} from "../routes/CookDashboard/CookOrder/CookOrderSlice";
import { getMenuByCurrentCook } from "../routes/CookDashboard/CookMyMenu/CookMyMenuSlice";
import { getDishContent } from "../routes/CookDashboard/EditDish/EditDishSlice";
import { getPaymentIntent } from "../routes/CookMenu/components/Checkout/CheckoutSlice";
import {
  connectNewStripeAccount,
  resumeConnectNewStripeAccount,
} from "../routes/CookDashboard/CookPayment/CookPaymentSlice";
import {
  cookRegisterAndSignUp,
  cookInterest,
} from "../routes/SignUpCook/signUpCookSlice";
import { createDish } from "../routes/CookDashboard/CreateDish/CreateDishSlice";

const initialState = {
  isLoading: false,
  showUserQuickMenu: false,
  showDatePicking: false,
  showLocationModal: false,
  showApplicationChecklist: true,
  showMobileMenu: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleUserQuickMenu: (state) => {
      state.showUserQuickMenu = !state.showUserQuickMenu;
    },
    toggleDatePicking: (state) => {
      state.showDatePicking = !state.showDatePicking;
    },
    toggleApplicationChecklist: (state) => {
      state.showApplicationChecklist = !state.showApplicationChecklist;
    },
    openMobileMenu: (state) => {
      state.showMobileMenu = true;
    },
    closeMobileMenu: (state) => {
      state.showMobileMenu = false;
    },
    closeLocationModal: (state) => {
      state.showLocationModal = false;
    },
    openLocationModal: (state) => {
      state.showLocationModal = true;
      state.showMobileMenu = false;
    },
  },
  extraReducers: {
    [createDish.pending]: (state) => {
      state.isLoading = true;
    },
    [createDish.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [createDish.rejected]: (state) => {
      state.isLoading = false;
    },

    [cookRegisterAndSignUp.pending]: (state) => {
      state.isLoading = true;
    },
    [cookRegisterAndSignUp.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [cookRegisterAndSignUp.rejected]: (state) => {
      state.isLoading = false;
    },

    [cookInterest.pending]: (state) => {
      state.isLoading = true;
    },
    [cookInterest.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [cookInterest.rejected]: (state) => {
      state.isLoading = false;
    },

    [resumeConnectNewStripeAccount.pending]: (state) => {
      state.isLoading = true;
    },
    [resumeConnectNewStripeAccount.fulfilled]: (state) => {
      // This is no need because the user will be redirect to Stripe to start setup payout
      // state.isLoading = false
    },
    [resumeConnectNewStripeAccount.rejected]: (state) => {
      state.isLoading = false;
    },

    [connectNewStripeAccount.pending]: (state) => {
      state.isLoading = true;
    },
    [connectNewStripeAccount.fulfilled]: (state) => {
      // This is no need because the user will be redirect to Stripe to start setup payout
      // state.isLoading = false
    },
    [connectNewStripeAccount.rejected]: (state) => {
      state.isLoading = false;
    },

    [getPaymentIntent.pending]: (state) => {
      state.isLoading = true;
    },
    [getPaymentIntent.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [getPaymentIntent.rejected]: (state) => {
      state.isLoading = false;
    },

    [resetPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [resetPassword.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [resetPassword.rejected]: (state) => {
      state.isLoading = false;
    },

    [getDishContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getDishContent.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [getDishContent.rejected]: (state) => {
      state.isLoading = false;
    },

    [getMenuByCurrentCook.pending]: (state) => {
      state.isLoading = true;
    },
    [getMenuByCurrentCook.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [getMenuByCurrentCook.rejected]: (state) => {
      state.isLoading = false;
    },

    [getCookOrder.pending]: (state) => {
      state.isLoading = true;
    },
    [getCookOrder.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [getCookOrder.rejected]: (state) => {
      state.isLoading = false;
    },

    [getCookOrderReady.pending]: (state) => {
      state.isLoading = true;
    },
    [getCookOrderReady.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [getCookOrderReady.rejected]: (state) => {
      state.isLoading = false;
    },

    [getCookOrderDelivered.pending]: (state) => {
      state.isLoading = true;
    },
    [getCookOrderDelivered.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [getCookOrderDelivered.rejected]: (state) => {
      state.isLoading = false;
    },

    [getNewCookOrder.pending]: (state) => {
      state.isLoading = true;
    },
    [getNewCookOrder.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [getNewCookOrder.rejected]: (state) => {
      state.isLoading = false;
    },

    [getUserOrder.pending]: (state) => {
      state.isLoading = true;
    },
    [getUserOrder.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [getUserOrder.rejected]: (state) => {
      state.isLoading = false;
    },

    [emailSignUp.pending]: (state) => {
      state.isLoading = true;
    },
    [emailSignUp.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [emailSignUp.rejected]: (state) => {
      state.isLoading = false;
    },

    // [facebookSignUp.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [facebookSignUp.fulfilled]: (state) => {
    //   state.isLoading = false;
    // },
    // [facebookSignUp.rejected]: (state) => {
    //   state.isLoading = false;
    // },

    [userLogin.pending]: (state) => {
      state.isLoading = true;
    },
    [userLogin.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [userLogin.rejected]: (state) => {
      state.isLoading = false;
    },

    [userFacebookLink.pending]: (state) => {
      state.isLoading = true;
    },
    [userFacebookLink.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [userFacebookLink.rejected]: (state) => {
      state.isLoading = false;
    },

    [userGoogleLink.pending]: (state) => {
      state.isLoading = true;
    },
    [userGoogleLink.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [userGoogleLink.rejected]: (state) => {
      state.isLoading = false;
    },

    [getAllLocalCook.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllLocalCook.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [getAllLocalCook.rejected]: (state) => {
      state.isLoading = false;
    },

    [getMenuBy.pending]: (state) => {
      state.isLoading = true;
    },
    [getMenuBy.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [getMenuBy.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  toggleUserQuickMenu,
  toggleDatePicking,
  closeLocationModal,
  openLocationModal,
  toggleApplicationChecklist,
  openMobileMenu,
  closeMobileMenu,
} = uiSlice.actions;

export default uiSlice.reducer;
