import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuDateFilter: "",
};

const menuDateFilterSlice = createSlice({
  name: "menuDateFilter",
  initialState,
  reducers: {
    defaultAvailableDateFilter: (state, action) => {
      var weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";
      let availableDateRange = action.payload.cookAvailableDateRange;
      let disableDays = action.payload.disableDays;
      let today = new Date();
      let nextAvailableDate = new Date();
      nextAvailableDate.setDate(today.getDate() + 2);
      let i = 2;
      // Make sure auto select date within available date range of the cook
      // and date not being force disable
      while (
        !availableDateRange.includes(nextAvailableDate.toDateString()) ||
        disableDays.includes(weekday[nextAvailableDate.getDay()])
      ) {
        i += 1;
        nextAvailableDate.setDate(today.getDate() + i);
      }
      state.menuDateFilter = nextAvailableDate.toDateString();
    },
    changeDateFilter: (state, action) => {
      state.menuDateFilter = action.payload;
    },
    cleanUpState: () => {
      return initialState;
    },
  },
});

export const { changeDateFilter, defaultAvailableDateFilter, cleanUpState } =
  menuDateFilterSlice.actions;

export default menuDateFilterSlice.reducer;
