import styles from "./Footer.module.scss";
import logo from "../../assets/images/LogoIncTextRedWhite.svg";
import amexLogo from "../../assets/images/amex.png";
import visaLogo from "../../assets/images/visa.png";
import masterLogo from "../../assets/images/master.png";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footer__ContentWrapper}>
        <div className="mainContainer">
          <div className={styles.mainFooterRow}>
            <div className={styles.leftCol}>
              <img src={logo} alt="MeCook logo" className={styles.logo} />
            </div>
            <div className={styles.footerNav}>
              <div className={styles.navGroup}>
                <p className={styles.navGroup__title}>Get Started</p>
                <a href="/signup" className={styles.navGroup__link}>
                  Sign Up
                </a>
                <a href="/cookOnboarding" className={styles.navGroup__link}>
                  Become a Cook
                </a>
              </div>
              <div className={styles.navGroup}>
                <p className={styles.navGroup__title}>How it work</p>
                <a href="/" className={styles.navGroup__link}>
                  About Mecook
                </a>
                <a href="/faq" className={styles.navGroup__link}>
                  FAQ and Support
                </a>
              </div>
              <div className={styles.navGroup}>
                <p className={styles.navGroup__title}>Secure Payment</p>
                <div className={styles.navGroup__paymentMethod}>
                  <img src={masterLogo} alt="" />
                  <img src={visaLogo} alt="" />
                  <img src={amexLogo} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.socialGroup}>
            <i className="lab la-facebook-square"></i>
            <i className="lab la-instagram"></i>
            <i className="lab la-twitter"></i>
          </div>
        </div>
      </div>
      <div className={styles.footer__secondary}>
        <div className="mainContainer">
          <p>© 2023 Mecook</p>
          <a href="/terms">Terms of Service</a>
          <a href="/privacy">Privacy Policy</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
