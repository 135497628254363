import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";

export const PageHeader = () => {
  return (
    <div className="flex items-center justify-between border-b border-solid border-gray-light px-4 py-2 tablet:px-10 tablet:py-2.5 desktop:px-16">
      <Link to="/">
        <img
          className="w-[125px] medium-mobile:w-[140px] tablet:w-[164px]"
          src={logo}
          alt="logo"
        />
      </Link>
      <div className="flex gap-2 font-Heading text-[13px] font-bold text-black medium-mobile:gap-6 medium-mobile:text-[15px] tablet:text-base">
        <Link to="/" className="duration-300 ease-in-out hover:text-red">
          Join our Waitlist
        </Link>
        <Link className="duration-300 ease-in-out hover:text-red" to="/faq">
          Faq
        </Link>
      </div>
    </div>
  );
};
