import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../redux/config/axiosApiConfig";

export const submitFormJoinWaitlist = createAsyncThunk(
  "formJoinWaitlist/submitFormJoinWaitlist",
  (data, thunkAPI) => {
    return api
      .post("/parse/functions/addNewProspectContactToSendgrid", {
        email: data.emailAddress,
        fullName: data.fullName,
        localSuburb: data.localSuburb,
        // interest: data.interest,
      })
      .then((res) => {
        return res.data.result;
      })
      .catch((err) => {
        return err.response.data;
      });
  },
);

const initialState = {
  submitStatus: false,
  isError: false,
  errorMessage: null,
};
const formJoinWaitlist = createSlice({
  name: "formJoinWaitlist",
  initialState,
  reducers: {
    cleanUpState: () => {
      return initialState;
    },
  },
  extraReducers: {
    [submitFormJoinWaitlist.fulfilled]: (state) => {
      state.submitStatus = true;
    },
    [submitFormJoinWaitlist.rejected]: (state, action) => {
      state.submitStatus = false;
      state.isError = true;
      switch (action.payload.code) {
        default:
          state.errorMessage =
            "[" + action.payload.code + "] " + action.payload.error;
          break;
      }
    },
  },
});

export const { cleanUpState } = formJoinWaitlist.actions;
export default formJoinWaitlist.reducer;
