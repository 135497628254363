import styles from "./CookOrder.module.scss";
import styleDashboard from "../CookDashboard.module.scss";
import Order from "../../../components/Order";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getNewCookOrder,
  getCookOrder,
  getCookOrderReady,
  getCookOrderDelivered,
} from "./CookOrderSlice";

import Loading from "../../../components/Loading";
import OrderCard from "../components/OrderCard";

const CookOrder = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.ui);
  const {
    newOrders,
    isSuccess,
    cookOrders,
    isOrderStatusUpdating,
    cookOrdersReady,
    cookOrdersDelivered,
  } = useSelector((state) => state.cookOrder);

  // useEffect(() => {
  //     let currentUserId = currentUser.objectId;
  //     dispatch(getNewCookOrder(currentUserId));
  //     dispatch(getCookOrder(currentUserId));
  //     return () => {
  //         // cleanup
  //     }
  // }, [])

  useEffect(() => {
    if (isOrderStatusUpdating == false) {
      let currentUserId = currentUser.objectId;
      dispatch(getNewCookOrder(currentUserId));
      dispatch(getCookOrder(currentUserId));
      dispatch(getCookOrderReady(currentUserId));
      dispatch(getCookOrderDelivered(currentUserId));
    }
    return () => {
      // cleanup
    };
  }, [isOrderStatusUpdating]);

  return (
    <div className={styleDashboard.contentContainer}>
      <p className={styleDashboard.heading}>My Orders</p>
      <div className={styles.newOrder}>
        <p className={styles.newOrder__title}>
          <i className="las la-rss"></i> New Coming order{" "}
        </p>
        <div className={styleDashboard.wrapperBox}>
          {isLoading ? (
            <Loading></Loading>
          ) : (
            <>
              {newOrders.length > 0 ? (
                <div className={styles.orderList}>
                  {newOrders.map((order) => (
                    <div key={order.objectId}>
                      <OrderCard order={order} />
                    </div>
                  ))}
                </div>
              ) : (
                isSuccess && <p className={styles.noNewOrder}>No Order Found</p>
              )}
            </>
          )}
        </div>
      </div>

      <p className={styles.confirmOrderTitle}>
        <i className="las la-clipboard-list"></i> My Order Schedule{" "}
      </p>

      <div className={styleDashboard.wrapperBox}>
        <p className={styles.orderDate}>All Confirmed Order</p>
        {isLoading ? (
          <Loading></Loading>
        ) : (
          <>
            {cookOrders.length > 0 ? (
              <div className={styles.orderList}>
                {cookOrders.map((order) => (
                  <div key={order.objectId}>
                    <OrderCard order={order} />
                  </div>
                ))}
              </div>
            ) : (
              isSuccess && <p className={styles.noNewOrder}>No Order Found</p>
            )}
          </>
        )}
      </div>

      <p className={styles.confirmOrderTitle}>
        <i className="las la-check-square"></i> Ready for Delivery{" "}
      </p>
      <div className={styleDashboard.wrapperBox}>
        {isLoading ? (
          <Loading></Loading>
        ) : (
          <>
            {cookOrdersReady.length > 0 ? (
              <div className={styles.orderList}>
                {cookOrdersReady.map((order) => (
                  <div key={order.objectId}>
                    <OrderCard order={order} />
                  </div>
                ))}
              </div>
            ) : (
              isSuccess && <p className={styles.noNewOrder}>No Order Found</p>
            )}
          </>
        )}
      </div>

      <p className={styles.confirmOrderTitle}>
        <i className="las la-check-square"></i> Already Delivered or Canceled
      </p>
      <div className={styleDashboard.wrapperBox}>
        {isLoading ? (
          <Loading></Loading>
        ) : (
          <>
            {cookOrdersDelivered.length > 0 ? (
              <div className={styles.orderList}>
                {cookOrdersDelivered.map((order) => (
                  <div key={order.objectId}>
                    <OrderCard order={order} />
                  </div>
                ))}
              </div>
            ) : (
              isSuccess && <p className={styles.noNewOrder}>No Order Found</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CookOrder;
