import styles from "./HeaderCook.module.scss";
import logo from "../../assets/images/logo-redwhite.svg";
import LoginUser from "./components/LoginUser";

import { Link } from "react-router-dom";

const HeaderCook = () => {
  return (
    <div className={styles.header}>
      <Link to="/">
        <img src={logo} alt="MeCook Logo" className={styles.logo} />
        <span className={styles.logoBeta}>Beta</span>
      </Link>
      <div className={styles.userMenu}>
        <LoginUser></LoginUser>
      </div>
    </div>
  );
};

export default HeaderCook;
