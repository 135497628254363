import styles from "./CookPayment.module.scss";
import styleDashboard from "../CookDashboard.module.scss";

import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  connectNewStripeAccount,
  resumeConnectNewStripeAccount,
  getStripeConnectedAccount,
  cleanUpState,
  getStripeConnectedLink,
} from "./CookPaymentSlice";
import Loading from "../../../components/Loading";

const CookPayment = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.ui);
  const {
    accountLinkUrl,
    connectedAccount,
    isLoading: loadingConnectedAccount,
    stripeConnectedLink,
  } = useSelector((state) => state.cookPayment);
  const { currentUser } = useSelector((state) => state.auth);

  const handleConnectStripe = () => {
    if (
      currentUser.stripeConnectedAccount != "" &&
      typeof currentUser.stripeConnectedAccount !== "undefined"
    ) {
      // resume stripeConnect -- Create new Link
      dispatch(resumeConnectNewStripeAccount(currentUser.objectId));
    } else {
      // create new stripeConnect
      dispatch(connectNewStripeAccount(currentUser.objectId));
    }
  };

  useEffect(() => {
    if (accountLinkUrl) {
      window.location.href = accountLinkUrl;
    }
    return () => {
      dispatch(cleanUpState());
    };
  }, [accountLinkUrl]);

  useEffect(() => {
    if (
      currentUser.stripeConnectedAccount != "" &&
      typeof currentUser.stripeConnectedAccount !== "undefined"
    ) {
      if (typeof connectedAccount == "undefined") {
        dispatch(getStripeConnectedAccount(currentUser.stripeConnectedAccount));
        dispatch(getStripeConnectedLink(currentUser.stripeConnectedAccount));
      }
    }
    return () => {
      // cleanup
    };
  }, []);

  return (
    <>
      {isLoading && (
        <Loading
          isOverlay={true}
          message="Please wait! You will be redirected to Stripe to complete the onboarding process."
        ></Loading>
      )}
      <div className={styleDashboard.contentContainer}>
        <p className={styleDashboard.heading}>Payouts</p>
        {loadingConnectedAccount && <Loading isOverlay={false}></Loading>}
        {currentUser.stripeConnectedAccount == "" ||
        typeof currentUser.stripeConnectedAccount == "undefined" ? (
          <>
            <div className={styleDashboard.section}>
              <p className={styleDashboard.sectionTitle}>Setup Payouts</p>
              <div className={styleDashboard.wrapperBox}>
                <p className={styles.paymentIntro}>
                  We partners with{" "}
                  <img
                    className={styles.stripeIcon}
                    src="https://upload.wikimedia.org/wikipedia/commons/b/ba/Stripe_Logo%2C_revised_2016.svg"
                    alt="stripe logo"
                  />{" "}
                  to transfer earnings to your bank account.
                </p>

                <div
                  className={styles.connectStripe}
                  onClick={handleConnectStripe}
                >
                  Set up payouts
                </div>

                <p>
                  You will be redirected to Stripe to complete the onboarding
                  process.
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            {connectedAccount && (
              <>
                {connectedAccount.charges_enabled &&
                connectedAccount.details_submitted ? (
                  <>
                    <div className={styleDashboard.section}>
                      <p className={styleDashboard.sectionTitle}>
                        Setup Payouts
                      </p>
                      <div className={styleDashboard.wrapperBox}>
                        <p className={styles.paymentIntro}>
                          We partners with{" "}
                          <img
                            className={styles.stripeIcon}
                            src="https://upload.wikimedia.org/wikipedia/commons/b/ba/Stripe_Logo%2C_revised_2016.svg"
                            alt="stripe logo"
                          />{" "}
                          to transfer earnings to your bank account.
                        </p>

                        <div
                          className={`${styles.connectStripe} ${styles.success}`}
                        >
                          Your payouts has been set up successfully
                        </div>

                        {stripeConnectedLink && (
                          <>
                            <a
                              target="_blank"
                              href={stripeConnectedLink.url}
                              className={`${styles.connectStripeLink}`}
                            >
                              Go to Stripe account Dashboard
                            </a>
                            <p className={styles.stripeDashIntro}>
                              Stripe dashboard is where you can track your
                              earning and payouts bank details.
                            </p>
                          </>
                        )}

                        {/* <p>You will be redirected to Stripe to complete the onboarding process.</p> */}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styleDashboard.section}>
                      <p className={styleDashboard.sectionTitle}>
                        Connect Your Bank Account To Receive Payouts
                      </p>
                      <div className={styleDashboard.wrapperBox}>
                        <p className={styles.paymentIntro}>
                          We partners with{" "}
                          <img
                            className={styles.stripeIcon}
                            src="https://upload.wikimedia.org/wikipedia/commons/b/ba/Stripe_Logo%2C_revised_2016.svg"
                            alt="stripe logo"
                          />{" "}
                          to transfer earnings to your bank account.
                        </p>

                        <div
                          className={styles.connectStripe}
                          onClick={handleConnectStripe}
                        >
                          Set up payouts
                        </div>

                        <p>
                          You will be redirected to Stripe to complete the
                          onboarding process.
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CookPayment;
