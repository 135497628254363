import axios from 'axios';

const APP_ID = 'mvpappid';
const API_KEY = 'myRestAPIKEY';
const MASTER_KEY = 'mvpmasterkey';
// const BaseURL = "https://server.mecook.app";
const BaseURL = process.env.REACT_APP_LOCALHOST_SERVER_URL || "https://server.mecook.app";

const HEADER = {
    'Content-Type':'application/json',
    'X-Parse-Application-Id':APP_ID,
    'X-Parse-REST-API-Key':API_KEY,
    'X-Parse-Master-Key':MASTER_KEY
}
const api = axios.create({
    baseURL: BaseURL,
    headers: HEADER,
})

export default api;