import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import api from "../../../redux/config/axiosApiConfig";

const initialState = {
  isSuccess: false,
  isError: false,
  errorMessage: null,
  isOrderStatusUpdating: false,
  newOrders: [],
  cookOrders: [],
  cookOrdersReady: [],
  cookOrdersDelivered: [],
};

export const getNewCookOrder = createAsyncThunk(
  "cookOrder/getNewCookOrder",
  (currentUserId, thunkAPI) => {
    return api
      .get("/parse/classes/Order", {
        params: {
          where: {
            cookBy: {
              __type: "Pointer",
              className: "_User",
              objectId: currentUserId,
            },
            orderStatus: "waiting confirm",
          },
          order: "orderDate",
          include: "orderBy",
        },
      })
      .then((res) => {
        console.log("getNewCookOrder return data: ", res);
        return res.data.results;
      })
      .catch((err) => {
        console.log("Error getNewCookOrder return data: ", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const getCookOrder = createAsyncThunk(
  "cookOrder/getCookOrder",
  (currentUserId, thunkAPI) => {
    return api
      .get("/parse/classes/Order", {
        params: {
          where: {
            cookBy: {
              __type: "Pointer",
              className: "_User",
              objectId: currentUserId,
            },
            orderStatus: "confirmed",
          },
          include: "orderBy",
        },
      })
      .then((res) => {
        console.log("getCookOrder return data: ", res);
        return res.data.results;
      })
      .catch((err) => {
        console.log("Error getCookOrder return data: ", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const getCookOrderReady = createAsyncThunk(
  "cookOrder/getCookOrderReady",
  (currentUserId, thunkAPI) => {
    return api
      .get("/parse/classes/Order", {
        params: {
          where: {
            cookBy: {
              __type: "Pointer",
              className: "_User",
              objectId: currentUserId,
            },
            orderStatus: "ready",
          },
          include: "orderBy",
        },
      })
      .then((res) => {
        console.log("getCookOrderReady return data: ", res);
        return res.data.results;
      })
      .catch((err) => {
        console.log("Error getCookOrderReady return data: ", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const getCookOrderDelivered = createAsyncThunk(
  "cookOrder/getCookOrderDelivered",
  (currentUserId, thunkAPI) => {
    return api
      .get("/parse/classes/Order", {
        params: {
          where: {
            cookBy: {
              __type: "Pointer",
              className: "_User",
              objectId: currentUserId,
            },
            $or: [
              {
                orderStatus: "delivered",
              },
              {
                orderStatus: "declined",
              },
            ],
          },
          include: "orderBy",
        },
      })
      .then((res) => {
        console.log("getCookOrderDelivered return data: ", res);
        return res.data.results;
      })
      .catch((err) => {
        console.log("Error getCookOrderDelivered return data: ", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const acceptOrder = createAsyncThunk(
  "cookOrder/acceptOrder",
  (data, thunkAPI) => {
    const updateData = {
      orderStatus: "confirmed",
    };
    let currentUserFirstName = thunkAPI.getState().auth.currentUser.firstName;
    let pickupAddress = thunkAPI.getState().auth.currentUser.address;
    return api
      .put("/parse/classes/Order/" + data.orderId, updateData)
      .then(() => {
        // Call could function to Capture a PaymentIntent
        return api
          .post("/parse/functions/captureStripePaymentIntent", {
            paymentID: data.paymentID,
          })
          .then((res) => {
            console.log("return capture payment", res.data.result);
            return res.data.result;
          })
          .catch((err) => {
            console.log("Capture PaymentIntent error:", err);
            return thunkAPI.rejectWithValue(err.response.data);
          });
        // Temporary Disable email notification order confirm by cook
        // api
        //   .post("/parse/functions/orderConfirmedNotificationEmail", {
        //     email: data.email,
        //     customerName: data.customerName,
        //     cookName: currentUserFirstName,
        //     orderItems: data.orderItems,
        //     pickupTime: data.pickupTime,
        //     orderDate: data.orderDate,
        //     subTotal: data.subTotal,
        //     pickupAddress: pickupAddress,
        //   })
        //   .then()
        //   .catch();
        return updateData;
      })
      .catch((err) => {
        console.log("Error acceptOrder return data: ", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const setOrderReady = createAsyncThunk(
  "cookOrder/setOrderReady",
  (data, thunkAPI) => {
    const updateData = {
      orderStatus: "ready",
    };
    let currentUserName = thunkAPI.getState().auth.currentUser.firstName;
    let pickupAddress = thunkAPI.getState().auth.currentUser.address;
    return api
      .put("/parse/classes/Order/" + data.orderId, updateData)
      .then(() => {
        // Temporary Disable email notification Order Ready
        // api
        //   .post("/parse/functions/orderReadyNotificationEmail", {
        //     email: data.email,
        //     customerName: data.customerName,
        //     cookName: currentUserName,
        //     orderItems: data.orderItems,
        //     pickupTime: data.pickupTime,
        //     orderDate: data.orderDate,
        //     subTotal: data.subTotal,
        //     pickupAddress: pickupAddress,
        //   })
        //   .then()
        //   .catch();
        return updateData;
      })
      .catch((err) => {
        console.log("Error setOrderReady return data: ", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const setOrderDelivered = createAsyncThunk(
  "cookOrder/setOrderDelivered",
  (data, thunkAPI) => {
    const updateData = {
      orderStatus: "delivered",
    };
    return api
      .put("/parse/classes/Order/" + data.orderId, updateData)
      .then(() => {
        api
          .post("/parse/functions/createStripePaymentTransfer", {
            groupTransfer: data.groupTransfer,
            connectedAccount: data.connectedAccount,
            amount: data.amount, // before taken commission
          })
          .then((res) => {
            console.log("success transfer result", res);
          })
          .catch((err) => {
            console.log("Error transfer return data: ", err);
          });
        return updateData;
      })
      .catch((err) => {
        console.log("Error setOrderDelivered return data: ", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const declineOrder = createAsyncThunk(
  "cookOrder/declineOrder",
  (data, thunkAPI) => {
    const updateData = {
      orderStatus: "declined",
    };
    let currentUserName = thunkAPI.getState().auth.currentUser.firstName;
    return api
      .put("/parse/classes/Order/" + data.orderId, updateData)
      .then(() => {
        api
          .post("/parse/functions/orderDeclineNotificationEmail", {
            email: data.email,
            customerName: data.customerName,
            cookName: currentUserName,
            orderItems: data.orderItems,
            pickupTime: data.pickupTime,
            orderDate: data.orderDate,
            subTotal: data.subTotal,
          })
          .then()
          .catch();
        return updateData;
      })
      .catch((err) => {
        console.log("Error declineOrder return data: ", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

const cookOrderSlice = createSlice({
  name: "cookOrder",
  initialState,
  reducers: {},
  extraReducers: {
    [acceptOrder.pending]: (state) => {
      state.isOrderStatusUpdating = true;
    },
    [acceptOrder.fulfilled]: (state) => {
      state.isOrderStatusUpdating = false;
    },
    [acceptOrder.rejected]: (state) => {
      state.isOrderStatusUpdating = false;
    },

    [setOrderReady.pending]: (state) => {
      state.isOrderStatusUpdating = true;
    },
    [setOrderReady.fulfilled]: (state) => {
      state.isOrderStatusUpdating = false;
    },
    [setOrderReady.rejected]: (state) => {
      state.isOrderStatusUpdating = false;
    },

    [setOrderDelivered.pending]: (state) => {
      state.isOrderStatusUpdating = true;
    },
    [setOrderDelivered.fulfilled]: (state) => {
      state.isOrderStatusUpdating = false;
    },
    [setOrderDelivered.rejected]: (state) => {
      state.isOrderStatusUpdating = false;
    },

    [declineOrder.pending]: (state) => {
      state.isOrderStatusUpdating = true;
    },
    [declineOrder.fulfilled]: (state) => {
      state.isOrderStatusUpdating = false;
    },
    [declineOrder.rejected]: (state) => {
      state.isOrderStatusUpdating = false;
    },

    [getNewCookOrder.fulfilled]: (state, action) => {
      state.isSuccess = true;
      state.newOrders = action.payload;
    },
    [getCookOrder.fulfilled]: (state, action) => {
      state.isSuccess = true;
      state.cookOrders = action.payload;
    },
    [getCookOrderReady.fulfilled]: (state, action) => {
      state.isSuccess = true;
      state.cookOrdersReady = action.payload;
    },
    [getCookOrderDelivered.fulfilled]: (state, action) => {
      state.isSuccess = true;
      state.cookOrdersDelivered = action.payload;
    },
  },
});

export default cookOrderSlice.reducer;
