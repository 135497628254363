import styles from "./EditDish.module.scss";
import styleDashboard from "../CookDashboard.module.scss";
import goodPhoto from "../../../assets/images/goodPhotoSample.jpg";
import goodPhoto2 from "../../../assets/images/goodPhotoSample2.jpg";
import badPhoto from "../../../assets/images/rejectedPhotoSample.jpg";
import badPhoto2 from "../../../assets/images/rejectedPhotoSample2.jpg";
import {
  TextInput,
  TextareaInput,
  PhotoUpload,
  IngredientInput,
  WeeklyInput,
} from "../components/InputField";
import SubmitButton from "../../../components/SubmitButton";

import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema as dishValidation } from "../../../validations/dishInfoForm";

import {
  getDishContent,
  cleanUpState,
  saveDishPhoto,
  linkDishToCook,
  saveDish,
} from "./EditDishSlice";
import Loading from "../../../components/Loading";

const EditDish = () => {
  let { dishId } = useParams();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.ui);
  const { dishContent, sendingRequest, isSaved } = useSelector(
    (state) => state.editDish
  );

  // const preLoadedValue = {
  //     name: dishContent.name,
  //     description: dishContent.description,
  //     // ingredient: dishContent.ingredients,
  //     portion: dishContent.portionSize,
  //     spicy: dishContent.spicyLevel,
  //     price: dishContent.price
  // }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    // resolver: yupResolver(dishValidation)
  });

  const handleSaveEdit = (data) => {
    let dataObj = {
      id: dishId,
      content: data,
    };
    // dispatch(linkDishToCook(dishId));
    dispatch(saveDish(dataObj));
  };

  const handleUploadPhoto = (file) => {
    let data = {
      id: dishId,
      file: file,
    };
    dispatch(saveDishPhoto(data));
  };

  useEffect(() => {
    if (JSON.stringify(dishContent) != "{}") {
      setValue("name", dishContent.name);
      setValue("description", dishContent.description);
      setValue("price", dishContent.price);
      setValue("spicy", dishContent.spicyLevel);
      setValue("portion", dishContent.portionSize);
      setValue("ingredient", dishContent.ingredients);
      setValue("cuisineCulture", dishContent.cuisineCulture);
    }
    return () => {
      // cleanup
    };
  }, [dishContent]);

  useEffect(() => {
    dispatch(getDishContent(dishId));
    return () => {
      dispatch(cleanUpState());
    };
  }, []);

  return (
    <div className={styleDashboard.contentContainer}>
      <p className={styleDashboard.heading}>
        Editing {dishContent && dishContent.name}
      </p>

      {isLoading && <Loading></Loading>}

      {JSON.stringify(dishContent) != "{}" && (
        <form
          onSubmit={handleSubmit(handleSaveEdit)}
          className={styles.dishForm}
        >
          {sendingRequest && <Loading isOverlay={true}></Loading>}
          <div className={styleDashboard.section}>
            {/* Conditional Rendering Validation Errors */}
            {Object.keys(errors).length !== 0 ? (
              <div className={styles.errorMessages}>
                {errors.name && <p>{errors.name.message}</p>}
                {errors.description && <p>{errors.description.message}</p>}
                {errors.ingredient && <p>{errors.ingredient.message}</p>}
                {errors.portion && <p>{errors.portion.message}</p>}
                {errors.spicy && <p>{errors.spicy.message}</p>}
                {errors.price && <p>{errors.price.message}</p>}
              </div>
            ) : null}

            {isSaved && isSaved ? (
              <div className={styles.successMessages}>
                <p>Your changes have been saved, successfully!</p>
              </div>
            ) : (
              <div></div>
            )}
            <p className={styleDashboard.sectionTitle}>Information</p>
            <div className={styleDashboard.wrapperBox}>
              <div className={styles.formWrapper}>
                <div className={styles.row2Col}>
                  <div className={styles.col}>
                    <div className={styles.innerRow}>
                      <TextInput
                        label="Name"
                        register={register("name")}
                      ></TextInput>
                    </div>
                    <div className={styles.innerRow}>
                      <TextInput
                        label="Cuisine's Culture"
                        instruction="Use comma to separate if more than one cuisine. Ex: Asian, Japanese"
                        register={register("cuisineCulture")}
                      ></TextInput>
                    </div>
                    <div className={styles.innerRow}>
                      <TextareaInput
                        label="Description"
                        register={register("description")}
                        instruction={[
                          "Describe your dish and mention the following aspects",
                          <ul>
                            <li>
                              Top two main ingredients used (examples: slow cook
                              beef, slow roasted chicken, chickpeas)
                            </li>
                            <li>
                              Top two flavors (examples: sweet, tangy, sweet,
                              aromatic, spicy)
                            </li>
                            <li>
                              Consistency (examples: juicy, crunchy, smooth,
                              creamy, rich)
                            </li>
                            <li>
                              A personal or historical story/fact about the dish
                              (example: This recipe was handed down from my
                              mother and is a family favorite!)
                            </li>
                            <li>
                              Customers will NOT be able to modify their dish.
                              Please do not give an option such as “spicy or not
                              spicy”, “this dish can be made vegan”, etc. in the
                              description section.
                            </li>
                            <li>
                              Please do NOT make health claims such as "very
                              health", "will make you lose weight", "will heal
                              you", etc.
                            </li>
                          </ul>,
                        ]}
                      ></TextareaInput>
                    </div>
                    <div className={styles.innerRow}>
                      <IngredientInput
                        label="Ingredients"
                        preloadData={dishContent.ingredients}
                        register={register("ingredient")}
                        setValue={(data) => {
                          setValue("ingredient", data);
                        }}
                        instruction={[
                          "Add one by one, to list ALL main ingredients used in this dish. Some customers have allergies, so please be detailed! Include the specific type of oil, liquid, and seasoning used as well.",
                          <br />,
                          "Ex: Organic bone-in chicken, coriander, cashew nuts, fenugreek, black pepper, mustard seeds, cinnamon stick, onions, garlic, ginger, tomatoes, turmeric, salt, olive oil, lemon juice, green chilies, and coconut milk.",
                        ]}
                      ></IngredientInput>
                    </div>
                    <div className={styles.innerRow}>
                      <TextInput
                        label="Portion Size"
                        instruction="Ex: 100ml container, 2 pieces, ect."
                        register={register("portion")}
                      ></TextInput>
                    </div>
                    <div className={styles.innerRow}>
                      <TextInput
                        label="Spicy level"
                        instruction="Please use number from 0 -> 3 to specify the spicy level of your dish."
                        register={register("spicy")}
                      ></TextInput>
                    </div>
                    <div className={styles.innerRow}>
                      <TextInput
                        label="Price"
                        name="price"
                        instruction="Ex: 11.99"
                        register={register("price")}
                      ></TextInput>
                    </div>
                  </div>
                  <div className={styles.col}>
                    <PhotoUpload
                      label="Photo"
                      customClass={styles.foodPhoto}
                      //   uploadingError={uploadingError}
                      //   isUploading={photoUploading}
                      photo={dishContent.Photo}
                      //   register={register("dishPhoto")}
                      onChange={(e) => {
                        handleUploadPhoto(e.target.files[0]);
                      }}
                    />
                    <div className={styles.photoInst}>
                      <p className={styles.title}>Photo instructions</p>
                      <ul>
                        <li>Take the photo from directly above. No tilting!</li>
                        <li>
                          Use natural sunlight during the day. No lighting from
                          lamps or flash!
                        </li>
                        <li>Use a circular, white bowl or plate.</li>
                        <li>
                          Include the entire plate/bowl in the photo—don't cut
                          off any edges.
                        </li>
                        <li>
                          Dish's photo will be edited and place in a suitable
                          background image.
                        </li>
                      </ul>
                      <div className={styles.examplePhoto}>
                        <p>Approved</p>
                        <div className={styles.samplePhoto}>
                          <img src={goodPhoto} alt="" />
                          <span>
                            <i className="las la-check-circle"></i>Good one
                          </span>
                        </div>
                        <div className={styles.samplePhoto}>
                          <img src={goodPhoto2} alt="" />
                          <span>
                            <i className="las la-check-circle"></i>Good one
                          </span>
                        </div>
                        <p>Rejected</p>
                        <div className={styles.samplePhoto}>
                          <img src={badPhoto} alt="" />
                          <span className={styles.reject}>
                            <i className="las la-ban"></i>Dish is cut off!
                          </span>
                        </div>
                        <div className={styles.samplePhoto}>
                          <img src={badPhoto2} alt="" />
                          <span className={styles.reject}>
                            <i className="las la-ban"></i>No natural sunlight,
                            it's also too yellow
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styleDashboard.section}>
            <p className={styleDashboard.sectionTitle}>Settings</p>
            <div className={styleDashboard.wrapperBox}>
              <div className={styles.formWrapper}>
                <div className={styles.row2Col}>
                  <div className={styles.col}>
                    <WeeklyInput
                      label="Weekly availability"
                      preloadData={dishContent.weeklyAvailability}
                      register={register("availableDays")}
                      setValue={(data) => {
                        setValue("availableDays", data);
                      }}
                      instruction="Pick days that this dish will be available"
                    />
                  </div>
                  <div className={styles.col}></div>
                </div>
              </div>
            </div>
          </div>

          {/* Conditional Rendering Validation Errors */}
          {Object.keys(errors).length !== 0 ? (
            <div className={styles.errorMessages}>
              {errors.name && <p>{errors.name.message}</p>}
              {errors.description && <p>{errors.description.message}</p>}
              {errors.ingredient && <p>{errors.ingredient.message}</p>}
              {errors.portion && <p>{errors.portion.message}</p>}
              {errors.spicy && <p>{errors.spicy.message}</p>}
              {errors.price && <p>{errors.price.message}</p>}
            </div>
          ) : null}

          {isSaved && isSaved ? (
            <div className={styles.successMessages}>
              <p>Your changes have been saved, successfully!</p>
            </div>
          ) : (
            <div></div>
          )}
          <SubmitButton text="Submit for approval"></SubmitButton>
        </form>
      )}
    </div>
  );
};

export default EditDish;
