import styles from "./DateFilter.module.scss";
import DatePicking from "../DatePicking";
import { toggleDatePicking } from "../../redux/uiSlice";

import { useSelector, useDispatch } from "react-redux";
import useClickOutSide from "../../hooks/useClickOutSide";
import {
  changeDateFilter,
  autoSelectHomepageDateFilter,
} from "./DateFilterSlice";
import { useEffect, useState } from "react";
import {
  cleanUpState as clearCookMenuState,
  clearCurrentCart,
} from "../../routes/CookMenu/cookMenuSlice";

const DateFilter = () => {
  const dispatch = useDispatch();

  const { dateFilter } = useSelector((state) => state.dateFilter);
  const { todayDate, platformConfig } = useSelector((state) => state.general);
  const { currentCart } = useSelector((state) => state.cookMenu);
  const { datePickingRange } = useSelector((state) => state.datePicking);

  const [dateSelected, setDateSelected] = useState("");

  const onDateFilterChange = (date) => {
    if (currentCart.items.length > 0) {
      if (
        window.confirm(
          "Switching your delivery date will discard your current order, are you sure? "
        )
      ) {
        dispatch(changeDateFilter(date));
        dispatch(clearCookMenuState());
      }
    } else {
      dispatch(changeDateFilter(date));
    }
  };
  useEffect(() => {
    const disabledDays = platformConfig.disableDays;
    if (dateFilter == "") {
      // No dateFilter selected
      if (currentCart.deliveryDate) {
        // have CurrentCart Saved
        if (datePickingRange) {
          if (
            datePickingRange.includes(currentCart.deliveryDate) // Check if saved delivery still available
          ) {
            dispatch(changeDateFilter(currentCart.deliveryDate));
          } else {
            // Clear not available Cart cause is was in the past
            dispatch(clearCurrentCart());
            dispatch(
              autoSelectHomepageDateFilter({ datePickingRange, disabledDays })
            );
          }
        }
      } else {
        if (datePickingRange) {
          dispatch(
            autoSelectHomepageDateFilter({ datePickingRange, disabledDays })
          );
        }
      }
    }
    return () => {
      // cleanup
    };
  }, [datePickingRange]);

  useEffect(() => {
    if (dateFilter != "") {
      const date = new Date(dateFilter);
      var weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";

      var month = new Array();
      month[0] = "Jan";
      month[1] = "Feb";
      month[2] = "Mar";
      month[3] = "Apr";
      month[4] = "May";
      month[5] = "Jun";
      month[6] = "Jul";
      month[7] = "Aug";
      month[8] = "Sep";
      month[9] = "Oct";
      month[10] = "Nov";
      month[11] = "Dec";
      var today = new Date(todayDate);
      var tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      if (tomorrow.toDateString() == dateFilter)
        setDateSelected(
          "Tomorrow, " + date.getDate() + "-" + month[date.getMonth()]
        );
      else {
        setDateSelected(
          weekday[date.getDay()] +
            ", " +
            date.getDate() +
            "-" +
            month[date.getMonth()]
        );
      }
    } else {
      setDateSelected("Pick preferable date");
    }
    return () => {
      // cleanup
    };
  }, [dateFilter]);

  return (
    <div className={styles.filter}>
      <p className={`${styles.title} title-h4`}>
        Choose your delivery day{" "}
        <span>
          Ordering ahead allowing cooks time to freshly prepared and cook your
          order.
        </span>
      </p>

      <DatePicking
        currentSelect={dateFilter}
        onChange={(date) => {
          onDateFilterChange(date);
        }}
      />
    </div>
  );
};

export default DateFilter;
