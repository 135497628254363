import styles from "./PageFooter.module.scss";
import logo from "../../assets/images/LogoIncTextRedWhite.svg";
import amexLogo from "../../assets/images/amex.png";
import visaLogo from "../../assets/images/visa.png";
import masterLogo from "../../assets/images/master.png";

const PageFooter = () => {
  return (
    <div className=" mt-24 bg-bgBlack py-12">
      <div className="px-4 tablet:px-10 desktop:px-16">
        <div className="flex flex-col justify-between gap-12 desktop:flex-row">
          <div>
            <img src={logo} className="w-[164px]" alt="" />
          </div>
          <div className="flex flex-col gap-10 text-white desktop:flex-row desktop:gap-32">
            <div>
              <p className="mb-4 font-Heading text-base font-semibold text-white desktop:mb-6">
                Get Started
              </p>
              <div className="flex flex-col gap-5 font-Para">
                <a href="#" className=" text-[15px]">
                  Sign Up to Order
                </a>
                <a href="#" className="text-[15px]">
                  Become a cook
                </a>
              </div>
            </div>
            <div>
              <p className="mb-4 font-Heading text-base font-semibold text-white desktop:mb-6">
                Get to Know Us
              </p>
              <div className="flex flex-col gap-5 font-Para">
                <a href="#" className="text-[15px]">
                  About Mecook
                </a>
              </div>
            </div>
            <div>
              <p className="mb-4 font-Heading text-base font-semibold text-white desktop:mb-6">
                Secure Payment
              </p>
              <div className="flex items-center justify-start gap-4 desktop:justify-center">
                <img src={masterLogo} className=" w-11" alt="" />
                <img src={visaLogo} className=" w-11" alt="" />
                <img src={amexLogo} className=" w-11" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 flex justify-start gap-5 text-white">
          <a href="#">
            <i className="lab la-facebook-square text-[25px]"></i>
          </a>
          <a href="#">
            <i className="lab la-instagram text-[25px]"></i>
          </a>
          <a href="#">
            <i className="lab la-twitter text-[25px]"></i>
          </a>
        </div>
      </div>
      <div className=" my-4 h-[1px] bg-[#474747]"></div>
      <div className=" px-4 tablet:px-10 desktop:px-16">
        <div className="flex flex-col justify-center gap-4 text-center font-Para text-[14px] text-white tablet:flex-row tablet:justify-start tablet:gap-14">
          <span>© 2024 Mecook</span>
          <a href="/terms">Terms of Service</a>
          <a href="/privacy">Privacy Policy</a>
        </div>
      </div>
    </div>
  );
};

export default PageFooter;
