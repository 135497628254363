import PageHeader from "../../layouts/pageHeader";
import icon_meal_delivery from "../../assets/images/icon-meal-delivery.png";
import icon_shop_meal from "../../assets/images/icon-shop-meal.png";
import icon_choose_delivery from "../../assets/images/icon-choose-delivery.png";
import FormJoinWaitlist from "../../components/formJoinWaitlist";
import PageFooter from "../../layouts/pageFooter";

const Waitlist = () => {
  return (
    <>
      <PageHeader></PageHeader>
      <div className="mx-auto max-w-main-wrapper px-4 tablet:px-10 desktop:px-16">
        {/* Hero section */}
        <section className=" mt-12">
          <div className="mx-auto flex flex-col items-center desktop:flex-row">
            <div className=" mb-14 text-center tablet:max-w-4xl tablet:pr-0 desktop:max-w-[792px] desktop:pr-32 desktop:text-left">
              <h1 className="mb-4 font-Heading text-h1-mb text-black tablet:text-h1-tb desktop:mb-7 desktop:text-h1-dt">
                Delivery a taste of home from{" "}
                <span className=" text-red">authentic homemade food,</span>{" "}
                simplify mealtime!
              </h1>
              <p className=" mb-10 font-Para text-paragraph-mb text-black tablet:mb-12 tablet:text-paragraph desktop:mb-20">
                We are an online marketplace for homemade food that help to
                connect local cooks with customers in their community and earn a
                meaningful income selling homemade dishes.
              </p>
              <div className="flex items-center justify-between gap-3">
                <div className="max-w-[100px] text-center">
                  <img
                    src={icon_choose_delivery}
                    className="mb-2.5 inline-block w-[31px] tablet:w-[34px]"
                    alt=""
                  />
                  <p className="font-Heading text-[15px] font-semibold text-black">
                    Choose a delivery day
                  </p>
                </div>
                <span className="h-[2px] w-9 bg-red"></span>
                <div className="max-w-[100px] text-center">
                  <img
                    src={icon_shop_meal}
                    className="mb-2.5 inline-block w-[39px] tablet:w-[43px]"
                    alt=""
                  />
                  <p className="font-Heading text-[15px] font-semibold text-black">
                    Shop meals in your area
                  </p>
                </div>
                <span className="h-[2px] w-9 bg-red"></span>
                <div className="max-w-[124px] text-center">
                  <img
                    src={icon_meal_delivery}
                    className="mb-2.5 inline-block w-[42px] tablet:w-[47px]"
                    alt=""
                  />
                  <p className="font-Heading text-[15px] font-semibold text-black">
                    Meals delivered cool, reheat to enjoy
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-grow tablet:max-w-4xl desktop:min-w-[520px] desktop:max-w-lg">
              <FormJoinWaitlist></FormJoinWaitlist>
            </div>
          </div>
        </section>
        {/* Feature section  */}
        <section className=" mt-11 tablet:mt-24">
          <h3 className=" mb-7 font-Heading text-h3-mb text-red tablet:text-h3-tb desktop:text-h3-dt">
            Why try Mecook?
          </h3>
          <div className="flex flex-col justify-between gap-9 desktop:flex-row">
            <div className=" w-full desktop:max-w-[393.33px]">
              <p className=" mb-6 font-Heading text-h4-tb desktop:text-h4-dt ">
                Support local cooks
              </p>
              <p className=" font-Para text-paragraph-mb text-black tablet:text-paragraph">
                Cooks are incredible members of our community who pour their
                hearts and souls into creating amazing food and sharing their
                unique cultural perspectives with you. So why not show them some
                love, it's a win-win for everyone
              </p>
            </div>
            <div className=" w-full desktop:max-w-[393.33px]">
              <p className=" mb-6 font-Heading text-h4-tb desktop:text-h4-dt ">
                Discover new culture
              </p>
              <p className=" font-Para text-paragraph-mb text-black tablet:text-paragraph">
                Reconnect with your own heritage or discover something
                completely new, one great way to do it is through trying
                traditional homemade dishes which offer a truly authentic taste
                of a particular place or community.
              </p>
            </div>
            <div className=" w-full desktop:max-w-[393.33px]">
              <p className=" mb-6 font-Heading text-h4-tb desktop:text-h4-dt ">
                Simplifying homemade dishes
              </p>
              <p className=" font-Para text-paragraph-mb text-black tablet:text-paragraph">
                Enjoy delicious homemade meals without overspending or
                overworking in the kitchen. Get delicious and affordable food
                with Mecook - it's convenient and easy!
              </p>
            </div>
          </div>
        </section>
      </div>
      <PageFooter></PageFooter>
    </>
  );
};

export default Waitlist;
