import "./App.scss";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Login from "./routes/Login";
import MainLayout from "./routes/Layout/MainLayout";
import SignUp from "./routes/SignUp";
import Welcome from "./routes/Welcome";
import ResetPassword from "./routes/ResetPassword";
import Waitlist from "./pages/Waitlist";
import HowItWork from "./routes/HowItWork";
import Faq from "./routes/Faq";
import CookDashboardLayout from "./routes/Layout/CookDashboardLayout";
import SignUpCook from "./routes/SignUpCook";
import PrivacyPolicy from "./routes/PrivacyPolicy";

import CookAuthProtectedRoute from "./hoc/CookAuthProtectedRoute";

import { authByToken } from "./redux/authSlice";
import {
  getTodayDate,
  getDeliveryLocation,
  saveLocationToLocalStorage,
} from "./redux/generalSlice";
import {
  loadSaveCart,
  loadUserFirstOrderPromotion,
} from "./routes/CookMenu/cookMenuSlice";
import Terms from "./routes/Terms";
import StripeReturnLandingPage from "./routes/StripeReturnLandingPage";
import StripeReturnLandingPageRefresh from "./routes/StripeReturnLandingPageRefresh";
import pageNotFound from "./routes/404";
import InterestWelcome from "./pages/interestWelcome";

function App() {
  const dispatch = useDispatch();
  const { authLoaded, isAuth, currentUser } = useSelector(
    (state) => state.auth,
  );
  const { isSavedLocationFound, deliveryLocation } = useSelector(
    (state) => state.general,
  );
  const { localCook } = useSelector((state) => state.home);
  const currentCookmenu = useSelector((state) => state.cookMenu);
  useEffect(() => {
    dispatch(authByToken());
    dispatch(getTodayDate());
    dispatch(getDeliveryLocation());
    dispatch(loadSaveCart());
  }, [dispatch]);

  useEffect(() => {
    if (typeof currentCookmenu !== "undefined") {
      if (currentCookmenu.currentCart.items.length > 0) {
        var saveCart = {
          cartContent: currentCookmenu.currentCart,
          byCookId: currentCookmenu.saveCookId,
        };
        localStorage.setItem("saveCart", JSON.stringify(saveCart));
      } else {
        localStorage.removeItem("saveCart");
      }
    }
  }, [currentCookmenu]);

  useEffect(() => {
    if (isSavedLocationFound === "found") {
      if (typeof localCook !== "undefined") {
        if (localCook.length > 0) {
          dispatch(saveLocationToLocalStorage());
        }
      }
    }
  }, [localCook]);

  useEffect(() => {
    if (isAuth) {
      console.log("load user promotions");
      dispatch(loadUserFirstOrderPromotion(currentUser.objectId));
    }
  }, [isAuth, currentUser, dispatch]);

  return (
    <>
      {authLoaded && isSavedLocationFound != "loading" ? (
        <Router>
          <Switch>
            <CookAuthProtectedRoute
              path="/cooking"
              component={CookDashboardLayout}
            />
            <Route path="/cookOnboarding" component={SignUpCook}></Route>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
            {/* <Route path="/fbsignup" component={SignUpFacebook} /> */}
            <Route path="/welcome" component={Welcome} />

            <Route path="/waitlist" component={Waitlist} />
            <Route path="/thankyou" component={InterestWelcome} />
            <Route path="/howitworks" component={HowItWork} />
            <Route path="/faq" component={Faq} />
            {/* <Route path="/homepage" component={LocationWelcome} /> */}
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/terms" component={Terms} />
            <Route path="/stripe_return" component={StripeReturnLandingPage} />
            <Route
              path="/stripe_refresh"
              component={StripeReturnLandingPageRefresh}
            />

            <Route path="/reset_password" component={ResetPassword} />
            {/* <Route path="/demo" component={MainLayout} /> */}
            <Route path="/" component={Waitlist} />
            {/* <Route path="/" exact={true} component={Teasing} /> */}
            <Route path="*" component={pageNotFound} />
          </Switch>
        </Router>
      ) : null}
    </>
  );
}

export default App;
