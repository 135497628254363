import styles from './LoginUser.module.scss';
import { useSelector, useDispatch } from 'react-redux';

const LoginUser = () => {

    const { currentUser } = useSelector(state => state.auth);

    return (
        <div className={styles.login_user}>
            <div className={styles.inner_wrap}>
                <div className={styles.avatar} style={currentUser.userPhoto ? (
                    { backgroundImage: 'url("' + currentUser.userPhoto.url + '")' }
                ) : (currentUser.facebookPictureUrl && { backgroundImage: 'url("' + currentUser.facebookPictureUrl + '")' })}>
                    {
                        !currentUser.userPhoto && (
                            !currentUser.facebookPictureUrl && (<i className="las la-user-circle"></i>)
                        )
                    }
                </div>
                <p className={styles.greeting}> {currentUser.firstName}</p>
            </div>
        </div>
    )
}

export default LoginUser