import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../redux/config/axiosApiConfig";

export const emailSignUp = createAsyncThunk(
  "signUp/emailSignup",
  (data, thunkAPI) => {
    return api
      .post("/parse/users", {
        username: data.email.toLowerCase(),
        email: data.email.toLowerCase(),
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        isCook: false,
      })
      .then((res) => {
        console.log("SignUp return data: ", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("Error SignUp return data: ", err.response.data);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

// export const facebookSignUp = createAsyncThunk(
//   "signUp/facebookSignUp",
//   (data, thunkAPI) => {
//     const updateData = {
//       firstName: data.firstName,
//       lastName: data.lastName,
//       facebookId: data.facebookId,
//     };

//     return api
//       .put("/parse/users/" + data.objectId, updateData)
//       .then((res) => {
//         let authData = {
//           facebook: {
//             id: data.facebookRaw.id,
//             access_token: data.facebookRaw.accessToken,
//             expiration_date: data.facebookRaw.data_access_expiration_time,
//           },
//         };
//         return api
//           .post("/parse/users", {
//             authData: authData,
//           })
//           .then((res) => {
//             return res.data;
//           });
//       })
//       .catch((err) => {
//         console.log("Error facebookSignUp return data: ", err.response.data);
//         return thunkAPI.rejectWithValue(err.response.data);
//       });
//   }
// );

const initialState = {
  isSuccess: false,
  isError: false,
  errorMessage: null,
};

const signUpSlice = createSlice({
  name: "signUp",
  initialState,
  reducers: {
    cleanUpState: () => {
      return initialState;
    },
  },
  extraReducers: {
    [emailSignUp.fulfilled]: (state) => {
      state.isSuccess = true;
    },
    [emailSignUp.rejected]: (state, action) => {
      state.isError = true;
      switch (action.payload.code) {
        case 202:
          state.errorMessage = "[202] Account already exists for this email.";
          break;

        default:
          state.errorMessage =
            "[" + action.payload.code + "] " + action.payload.error;
          break;
      }
    },
  },
});

export const { cleanUpState } = signUpSlice.actions;

export default signUpSlice.reducer;
