import HeaderCook from "../../components/HeaderCook";
import SideMenuCook from "../../components/SideMenuCook";
import { Route, Switch } from "react-router-dom";

import CookOrder from "../CookDashboard/CookOrder";
import CookProfile from "../CookDashboard/CookProfile";
import CookMyMenu from "../CookDashboard/CookMyMenu";
import EditDish from "../CookDashboard/EditDish";
import CreateDish from "../CookDashboard/CreateDish";
import CookAvailability from "../CookDashboard/CookAvailability";
import CookSupport from "../CookDashboard/CookSupport";
import CookPayment from "../CookDashboard/CookPayment";
import ApplicationProcess from "../CookDashboard/ApplicationProcess";
import ApplicationChecklist from "../CookDashboard/components/ApplicationChecklist";
import { useSelector } from "react-redux";

import { Helmet } from "react-helmet";

const CookDashboardLayout = () => {
  const { currentUser } = useSelector((state) => state.auth);
  console.log(currentUser.applicationStatus);
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1024" />
      </Helmet>
      <HeaderCook />
      <SideMenuCook />
      {currentUser.applicationStatus !== "Approved" && (
        <ApplicationChecklist></ApplicationChecklist>
      )}
      <Switch>
        <Route
          exact
          path="/cooking/application"
          component={ApplicationProcess}
        />
        <Route exact path="/cooking/order" component={CookOrder} />
        <Route exact path="/cooking/menu" component={CookMyMenu} />
        <Route exact path="/cooking/profile" component={CookProfile} />
        <Route
          exact
          path="/cooking/availability"
          component={CookAvailability}
        />
        <Route exact path="/cooking/dish/create" component={CreateDish} />
        <Route exact path="/cooking/dish/:dishId" component={EditDish} />
        <Route exact path="/cooking/payment" component={CookPayment} />
        <Route exact path="/cooking/support" component={CookSupport} />
      </Switch>
    </>
  );
};

export default CookDashboardLayout;
