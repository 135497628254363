import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import api from "./config/axiosApiConfig";
import {
  userLogin,
  userFacebookLink,
  userGoogleLink,
} from "../routes/Login/loginSlice";
// import { facebookSignUp } from "../routes/SignUp/signUpSlice";
import {
  uploadUserPhoto,
  saveUserUpdate,
} from "../routes/UserAccount/UserProfile/userProfileSlice";

import {
  saveProfileUpdate,
  uploadMenuMainPhoto,
  saveMenuGeneral,
} from "../routes/CookDashboard/CookProfile/CookProfileSlice";

import { createDish } from "../routes/CookDashboard/CreateDish/CreateDishSlice";

import {
  cookRegisterFromUser,
  cookRegisterAndSignUp,
} from "../routes/SignUpCook/signUpCookSlice";

import { setReadyForReview } from "../routes/CookDashboard/components/ApplicationChecklist/ApplicationChecklistSlice";

export const userLogout = createAsyncThunk("auth/userLogout", (thunkAPI) => {
  return api
    .post(
      "/parse/logout",
      {},
      {
        headers: {
          "X-Parse-Session-Token": localStorage.getItem("token"),
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return thunkAPI.rejectWithValue(err.response.data);
    });
});

export const authByToken = createAsyncThunk("auth/authByToken", (thunkAPI) => {
  return api
    .get("/parse/users/me", {
      headers: {
        "X-Parse-Session-Token": localStorage.getItem("token"),
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return thunkAPI.rejectWithValue(err.response.data);
    });
});

export const checkExistingEmail = createAsyncThunk(
  "auth/checkExistingEmail",
  (email, thunkAPI) => {
    return api
      .get("/parse/users", {
        params: {
          where: {
            email: email,
          },
        },
      })
      .then((res) => {
        console.log("checkExistingEmail", res);
        return res.data;
      })
      .catch((err) => {
        console.log("checkExistingEmail", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

const initialState = {
  isAuth: false,
  authLoaded: false,
  isFacebookLinking: false,
  currentUser: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    cleanUpFBSignUp: (state) => {
      state.isFacebookLinking = false;
      state.facebookServerReturn = null;
      state.facebookRaw = null;
    },
  },
  extraReducers: {
    [cookRegisterFromUser.fulfilled]: (state, action) => {
      state.currentUser = Object.assign(state.currentUser, action.payload);
      state.isCookSignUpFromUser = true;
    },
    [cookRegisterAndSignUp.fulfilled]: (state, action) => {
      state.isCookSignUpFromUser = true;
    },

    [userGoogleLink.fulfilled]: (state, action) => {
      state.isAuth = true;
      state.authLoaded = true;
      state.currentUser = action.payload.data;
      localStorage.setItem("token", action.payload.sessionToken);
    },

    [userFacebookLink.fulfilled]: (state, action) => {
      // if(action.payload.status === 200)
      // {
      //     // Existing Facebook linked account or Existing Account just Link with Facebook auth
      //     // state.isAuth = true;
      //     // state.authLoaded = true;
      //     // state.currentUser = action.payload.data;
      //     // localStorage.setItem('token', action.payload.data.sessionToken);
      // }
      // else{
      //     // New account created using Facebook auth - status 201

      //     // The following code is to navigate to facebook signup page for extra info required,
      //     // but everything should be enough with what facebook provided.
      //     // state.isFacebookLinking = true;
      //     // state.facebookServerReturn = action.payload.data;
      //     // state.facebookRaw = action.payload.facebookRes;
      // }
      state.isAuth = true;
      state.authLoaded = true;
      state.currentUser = action.payload.data;
      localStorage.setItem("token", action.payload.sessionToken);
    },

    // [facebookSignUp.fulfilled]: (state, action) => {
    //   state.isAuth = true;
    //   state.authLoaded = true;
    //   state.currentUser = action.payload;
    //   state.isFacebookLinking = false;
    //   localStorage.setItem("token", action.payload.sessionToken);
    // },

    [userLogin.fulfilled]: (state, action) => {
      state.isAuth = true;
      state.authLoaded = true;
      state.currentUser = action.payload;
      localStorage.setItem("token", action.payload.sessionToken);
    },

    [userLogout.fulfilled]: (state, action) => {
      localStorage.removeItem("token");
      state.isAuth = false;
      state.currentUser = null;
    },

    [authByToken.fulfilled]: (state, action) => {
      state.isAuth = true;
      state.authLoaded = true;
      state.currentUser = action.payload;
      localStorage.setItem("token", action.payload.sessionToken);
    },
    [authByToken.rejected]: (state) => {
      state.authLoaded = true;
    },

    [saveUserUpdate.fulfilled]: (state, action) => {
      state.currentUser = Object.assign(state.currentUser, action.payload);
    },

    [uploadUserPhoto.fulfilled]: (state, action) => {
      state.currentUser = Object.assign(state.currentUser, action.payload);
    },

    [saveProfileUpdate.fulfilled]: (state, action) => {
      state.currentUser = Object.assign(state.currentUser, action.payload);
    },

    [saveMenuGeneral.fulfilled]: (state, action) => {
      state.currentUser = Object.assign(state.currentUser, action.payload);
    },

    [createDish.fulfilled]: (state, action) => {
      console.log("test update create Dish", action.payload);
      state.currentUser = Object.assign(state.currentUser, action.payload);
    },

    [uploadMenuMainPhoto.fulfilled]: (state, action) => {
      state.currentUser = Object.assign(state.currentUser, action.payload);
    },

    [setReadyForReview.fulfilled]: (state, action) => {
      state.currentUser = Object.assign(state.currentUser, action.payload);
    },
  },
});

export const { cleanUpFBSignUp } = authSlice.actions;

export default authSlice.reducer;
