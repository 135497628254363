import styles from "./InputTags.module.scss";
import { useState, useEffect } from "react";

const InputTags = ({ preloadData, onChange }) => {
  const [tags, setTags] = useState(preloadData ? preloadData.split(",") : []);
  const [currentValue, setCurrentValue] = useState("");
  const addTag = () => {
    // Check if tag already exist.
    if (
      tags.find((tag) => tag.toLowerCase() === currentValue.toLowerCase()) ||
      currentValue.trim() === ""
    ) {
      return;
    }
    setTags([...tags, currentValue.trim()]);
    setCurrentValue("");
  };

  const removeTag = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags);
  };

  const onInputChange = (e) => {
    setCurrentValue(e.target.value);
  };

  useEffect(() => {
    onChange(tags);
    return () => {
      // cleanup
    };
  }, [tags]);

  return (
    <div className={styles.inputTags}>
      <ul className={styles.inputTags__tags}>
        {tags &&
          tags.map((tag, i) => {
            return (
              <li key={tag}>
                {tag}{" "}
                <i
                  className="las la-times-circle"
                  onClick={() => {
                    removeTag(i);
                  }}
                ></i>
              </li>
            );
          })}
      </ul>
      <div className={styles.inputTags__input}>
        <input
          value={currentValue}
          type="text"
          onChange={(e) => {
            onInputChange(e);
          }}
        />
        <span className={styles.addTag} onClick={addTag}>
          Add
        </span>
      </div>
    </div>
  );
};

export default InputTags;
