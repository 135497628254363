import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../redux/config/axiosApiConfig";

export const submitProfile = createAsyncThunk(
  "applicationProcess/submitProfile",
  (data, thunkAPI) => {
    const updateData = {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      cookIntro: data.intro,
      cuisine: data.cuisine,
      address: data.address,
      location: {
        __type: "GeoPoint",
        latitude: data.location.lat,
        longitude: data.location.lng,
      },
      // THIS ONE SHOULD NOT BE USED NEED TO DOUBLE CHECK THIS SLICE HAS EVER BEEN CALLED
      applicationStatus: "pending profile review", // waiting submit - pending profile review - success - waiting update - waiting menu setup. // THIS ONE SHOULD NOT BE USED
    };
    const currentUserId = thunkAPI.getState().auth.currentUser.objectId;
    return api
      .put("/parse/users/" + currentUserId, updateData)
      .then(() => {
        return updateData;
      })
      .catch((err) => {
        console.log("Error save user update in profile page: ", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

const initialState = {
  isSuccess: false,
  isError: false,
  errorMessage: null,
  currentStep: 1,
  profileSubmitting: false,
  profileSaved: false,
};

const applicationProcessSlice = createSlice({
  name: "applicationProcess",
  initialState,
  reducers: {
    cleanUpState: () => {
      return initialState;
    },
  },
  extraReducers: {
    [submitProfile.pending]: (state) => {
      state.profileSubmitting = true;
    },
    [submitProfile.fulfilled]: (state) => {
      state.currentStep += 1;
      state.profileSubmitting = false;
      state.profileSaved = true;
    },
  },
});

export default applicationProcessSlice.reducer;
