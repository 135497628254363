import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const CookAuthProtectedRoute = ({
  component: Component,
  ...restRouteProps
}) => {
  let location = useLocation();
  const { isAuth, currentUser } = useSelector((state) => state.auth);

  return (
    <>
      {window.location.pathname !== "/cooking" ? (
        <Route
          {...restRouteProps}
          render={(props) => {
            return isAuth ? (
              currentUser.isCook ? (
                <Component {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: { from: location.pathname },
                  }}
                />
              )
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location.pathname },
                }}
              />
            );
          }}
        />
      ) : (
        <Redirect to={{ pathname: "/cooking/profile" }} />
      )}
    </>
  );
};

export default CookAuthProtectedRoute;
