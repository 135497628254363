import styles from "./ApplicationChecklist.module.scss";
import { useSelector, useDispatch } from "react-redux";

import { useEffect, useState } from "react";

import { toggleApplicationChecklist } from "../../../../redux/uiSlice";
import {
  getStripeConnectedAccount,
  getStripeConnectedLink,
} from "../../CookPayment/CookPaymentSlice";
import { setReadyForReview } from "./ApplicationChecklistSlice";

const ApplicationChecklist = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const { showApplicationChecklist } = useSelector((state) => state.ui);
  const { connectedAccount } = useSelector((state) => state.cookPayment);
  const [totalProcess, setTotalProcess] = useState(0);
  const [processCount, setProcessCount] = useState(0);
  const dispatch = useDispatch();
  const toggleChecklist = () => {
    dispatch(toggleApplicationChecklist());
  };
  const submitApplicationForReview = () => {
    dispatch(setReadyForReview());
  };

  useEffect(() => {
    let processCount = 0;
    let totalProcess = 8;

    if (typeof currentUser.userPhoto !== "undefined") processCount++;
    if (typeof currentUser.phone !== "undefined") processCount++;
    if (typeof currentUser.cookIntro !== "undefined") processCount++;
    if (typeof currentUser.location !== "undefined") processCount++;
    if (typeof currentUser.cuisines !== "undefined") processCount++;
    if (typeof currentUser.menuCoverPhoto !== "undefined") processCount++;
    if (
      typeof currentUser.menuDishes !== "undefined" &&
      currentUser.menuDishes != null
    ) {
      if (currentUser.menuDishes.length >= 4) {
        processCount++;
      }
    }
    if (typeof connectedAccount !== "undefined") {
      if (
        connectedAccount.charges_enabled &&
        connectedAccount.details_submitted
      ) {
        processCount++;
      }
    } else if (
      currentUser.stripeConnectedAccount != "" &&
      typeof currentUser.stripeConnectedAccount !== "undefined"
    ) {
      dispatch(getStripeConnectedAccount(currentUser.stripeConnectedAccount));
      dispatch(getStripeConnectedLink(currentUser.stripeConnectedAccount));
    }

    // if(processCount == totalProcess){
    //     // dispatch(setReadyForReview());
    // }

    setProcessCount(processCount);
    setTotalProcess(totalProcess);

    return () => {
      // cleanup
    };
  }, [currentUser, connectedAccount]);

  return (
    <div className={styles.applicationChecklist}>
      {processCount !== totalProcess ? (
        <div className={styles.heading} onClick={toggleChecklist}>
          {showApplicationChecklist ? "Hide" : "Show"} application checklist (
          {processCount} of {totalProcess})
        </div>
      ) : (
        <div
          className={`${styles.heading} ${
            currentUser.applicationStatus === "Waiting on Submission"
              ? styles.submitNow
              : styles.waitingReview
          }`}
          onClick={submitApplicationForReview}
        >
          {currentUser.applicationStatus !== "Pending Review" &&
          currentUser.applicationStatus === "Waiting on Submission"
            ? "Submit Your Application"
            : "Your Application is submitted and being review!"}
        </div>
      )}
      <div
        className={`${styles.processList} ${
          showApplicationChecklist && styles.show
        }`}
      >
        <div className={styles.triangleShape}></div>
        <ul>
          <li
            className={`${styles.listItem} ${
              currentUser.userPhoto && styles.done
            }`}
          >
            <a href="#">
              <span className={styles.cir}></span>{" "}
              <span>Upload profile photo</span>
            </a>
          </li>
          <li
            className={`${styles.listItem} ${currentUser.phone && styles.done}`}
          >
            <a href="#">
              <span className={styles.cir}></span>{" "}
              <span>Update mobile number</span>
            </a>
          </li>
          <li
            className={`${styles.listItem} ${
              currentUser.cookIntro && styles.done
            }`}
          >
            <a href="#">
              <span className={styles.cir}></span> <span>Write your bio</span>
            </a>
          </li>
          <li
            className={`${styles.listItem} ${
              currentUser.location && styles.done
            }`}
          >
            <a href="#">
              <span className={styles.cir}></span>{" "}
              <span>Enter your address for pickup</span>
            </a>
          </li>
          <li
            className={`${styles.listItem} ${
              currentUser.cuisines && styles.done
            }`}
          >
            <a href="#">
              <span className={styles.cir}></span>{" "}
              <span>Add Cuisine's Culture for your menu</span>
            </a>
          </li>
          <li
            className={`${styles.listItem} ${
              currentUser.menuCoverPhoto && styles.done
            }`}
          >
            <a href="/cooking/menu">
              <span className={styles.cir}></span>{" "}
              <span>Upload photo main menu</span>
            </a>
          </li>
          <li
            className={`${styles.listItem} ${
              connectedAccount &&
              connectedAccount.charges_enabled &&
              connectedAccount.details_submitted &&
              styles.done
            }`}
          >
            <a href="#">
              <span className={styles.cir}></span>{" "}
              <span>Connect your bank to Stripe</span>
            </a>
          </li>
          <li
            className={`${styles.listItem} ${
              currentUser.menuDishes &&
              currentUser.menuDishes.length >= 4 &&
              styles.done
            }`}
          >
            <a href="#">
              <span className={styles.cir}></span>{" "}
              <span>Submit at least 4 menu items</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ApplicationChecklist;
