import styles from "./InputField.module.scss";
import noImage from "../../../../assets/images/default-food-vector.svg";
import InputTags from "../../../../components/InputTags";
import InputPlacesAutoComplete from "../../../../components/InputPlacesAutoComplete";
import TimePicking from "../../../../components/TimePicking";
import { useState, useEffect } from "react";

const WeeklyPickupTimeInput = ({
  instruction,
  label,
  register,
  preLoadData,
  setValue,
  ...inputProps
}) => {
  const [weeklyPickupTime, setWeeklyPickupTime] = useState(
    preLoadData && preLoadData.length > 0
      ? preLoadData
      : [
          { day: "Mon", time: "" },
          { day: "Tue", time: "" },
          { day: "Wed", time: "" },
          { day: "Thu", time: "" },
          { day: "Fri", time: "" },
          { day: "Sat", time: "" },
          { day: "Sun", time: "" },
        ]
  );

  const setPickupTime = (index, selectedTime) => {
    let updatedWeeklyPickupTime = [...weeklyPickupTime];
    let cloneDayWillBeUpdatedObj = Object.assign(
      {},
      updatedWeeklyPickupTime[index]
    );
    cloneDayWillBeUpdatedObj.time = selectedTime;
    updatedWeeklyPickupTime[index] = cloneDayWillBeUpdatedObj;
    setValue(updatedWeeklyPickupTime);
    setWeeklyPickupTime(updatedWeeklyPickupTime);
  };

  useEffect(() => {
    setValue(weeklyPickupTime);
  }, []);

  return (
    <div className={styles.inputWrapper}>
      <p className={styles.title}>{label}</p>
      {instruction ? <p className={styles.instruction}>{instruction}</p> : null}
      <div className={styles.dayListWrap}>
        {weeklyPickupTime.map((item, index) => {
          return (
            <div key={index} className={styles.dayItem}>
              <div className={styles.day}>{item.day}</div>
              <TimePicking
                selectedTime={item.time}
                onChange={(value) => {
                  setPickupTime(index, value);
                }}
              ></TimePicking>
            </div>
          );
        })}
      </div>
      <input type="hidden" {...register} />
    </div>
  );
};

const TextInput = ({ register, label, instruction, ...inputProps }) => {
  return (
    <div className={styles.inputWrapper}>
      <p className={styles.title}>{label}</p>
      {instruction ? <p className={styles.instruction}>{instruction}</p> : null}
      <input
        type="text"
        {...inputProps}
        className={styles.textInput}
        {...register}
      />
    </div>
  );
};

const PasswordInput = ({ register, label, instruction, ...inputProps }) => {
  return (
    <div className={styles.inputWrapper}>
      <p className={styles.title}>{label}</p>
      {instruction ? <p className={styles.instruction}>{instruction}</p> : null}
      <input
        type="password"
        {...inputProps}
        className={styles.textInput}
        {...register}
      />
    </div>
  );
};

const TextareaInput = ({ register, label, instruction, ...inputProps }) => {
  return (
    <div className={styles.inputWrapper}>
      <p className={styles.title}>{label}</p>
      {instruction ? <p className={styles.instruction}>{instruction}</p> : null}
      <textarea className={styles.textInput} {...inputProps} {...register} />
    </div>
  );
};

const PhotoUpload = ({
  label,
  instruction,
  customClass,
  uploadingError,
  photo,
  register,
  onChange,
  isUploading,
  ...inputProps
}) => {
  return (
    <div className={styles.inputWrapper}>
      <p className={styles.title}>{label}</p>
      {instruction ? <p className={styles.instruction}>{instruction}</p> : null}
      <div
        className={`${styles.photoUpload} ${customClass}`}
        style={photo && { backgroundImage: 'url("' + photo.url + '")' }}
      >
        <label className={`${styles.upload_hover} ${!photo && styles.noPhoto}`}>
          <p>
            <span>Select</span> and <span>upload</span> photo from your device
            (max size: 1Mb)
          </p>
          <input
            type="file"
            {...inputProps}
            {...register}
            onChange={(e) => {
              onChange(e);
            }}
          />
        </label>
        {isUploading && (
          <label className={styles.uploading}>
            <p>Uploading ...</p>
          </label>
        )}
        {/* {!photo && <img src={noImage} className={styles.noImage} alt="" />} */}
      </div>
      {uploadingError && (
        <p className={styles.uploadError}>Error: {uploadingError}</p>
      )}
    </div>
  );
};

const IngredientInput = ({
  register,
  setValue,
  label,
  instruction,
  preloadData,
  ...inputProps
}) => {
  return (
    <div className={styles.inputWrapper}>
      <p className={styles.title}>{label}</p>
      {instruction ? <p className={styles.instruction}>{instruction}</p> : null}
      {preloadData ? (
        <InputTags
          preloadData={preloadData.toString()}
          onChange={(data) => {
            setValue(data);
          }}
        ></InputTags>
      ) : (
        <InputTags
          onChange={(data) => {
            setValue(data);
          }}
        ></InputTags>
      )}
      <input type="hidden" {...register} />
    </div>
  );
};

const CuisineInput = ({
  register,
  setValue,
  label,
  instruction,
  preloadData,
  ...inputProps
}) => {
  return (
    <div className={styles.inputWrapper}>
      <p className={styles.title}>{label}</p>
      {instruction ? <p className={styles.instruction}>{instruction}</p> : null}
      <InputTags
        preloadData={preloadData}
        onChange={(data) => {
          setValue(data.toString()); // Todo: convert this data to string type
        }}
      ></InputTags>
      <input type="hidden" {...register} />
    </div>
  );
};

const PickupLocationInput = ({
  registerAddress,
  registerCoordinates,
  setValueAddress,
  setValueCoordinates,
  setValueFullAddress,
  label,
  instruction,
  preloadData,
  ...inputProps
}) => {
  return (
    <div className={styles.inputWrapper}>
      <p className={styles.title}>{label}</p>
      {instruction ? <p className={styles.instruction}>{instruction}</p> : null}
      <InputPlacesAutoComplete
        preloadData={preloadData}
        customInputClass={styles.textInput}
        onChange={(selectedAddress) => {
          setValueFullAddress(selectedAddress);
          setValueAddress(selectedAddress.Address);
          setValueCoordinates(selectedAddress.Coordinates);
        }}
        placeholder="Search and select on the correct address"
      ></InputPlacesAutoComplete>
      <input type="hidden" {...registerAddress} />
      <input type="hidden" {...registerCoordinates} />
    </div>
  );
};

const WeeklyInput = ({
  register,
  setValue,
  label,
  instruction,
  preloadData,
  ...inputProps
}) => {
  const [weekDays, setWeekDays] = useState(
    preloadData
      ? preloadData
      : [
          { day: "Mon", isAvailable: false },
          { day: "Tue", isAvailable: false },
          { day: "Wed", isAvailable: false },
          { day: "Thu", isAvailable: false },
          { day: "Fri", isAvailable: false },
          { day: "Sat", isAvailable: false },
          { day: "Sun", isAvailable: false },
        ]
  );

  const selectDay = (index) => {
    let updateArray = [...weekDays];
    let cloneDayObj = Object.assign({}, updateArray[index]);
    // Toggle available day
    cloneDayObj.isAvailable = !cloneDayObj.isAvailable;
    updateArray[index] = cloneDayObj;
    setWeekDays(updateArray);
    setValue(updateArray);
  };

  useEffect(() => {
    setValue(weekDays);
    return () => {
      // cleanup
    };
  }, []);

  return (
    <div className={styles.inputWrapper}>
      <p className={styles.title}>{label}</p>
      {instruction ? <p className={styles.instruction}>{instruction}</p> : null}
      <div className={styles.dayList}>
        <ul>
          {weekDays.map((day, index) => {
            return (
              <li
                key={index}
                className={`${day.isAvailable ? styles.selected : null}`}
                onClick={() => {
                  selectDay(index);
                }}
              >
                {day.day}
              </li>
            );
          })}
        </ul>
      </div>
      <input type="hidden" {...register} />
    </div>
  );
};

export {
  TextInput,
  PasswordInput,
  TextareaInput,
  PhotoUpload,
  IngredientInput,
  WeeklyInput,
  CuisineInput,
  PickupLocationInput,
  WeeklyPickupTimeInput,
};
