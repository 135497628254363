import * as Yup from "yup";
const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("* Full name is required"),
  emailAddress: Yup.string()
    .email("* Invalid email address")
    .required("* Email is required"),
  localSuburb: Yup.string().required("* Local Suburb is required"),
  // interest: Yup.string().required("* Please enter your interest"),
});
export default validationSchema;
