import styles from "./Login.module.scss";
import logo from "../../assets/images/logo-vertical.svg";
import SubmitButton from "../../components/SubmitButton";
import { Link, useHistory, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import FacebookLogin from "react-facebook-login";
import { useGoogleLogin } from "@react-oauth/google";

import Loading from "../../components/Loading";
import api from "../../redux/config/axiosApiConfig";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema as LoginValidation } from "../../validations/userLogin";

import { userLogin, userFacebookLink, userGoogleLink } from "./loginSlice";

import Footer from "../../components/Footer";

const Login = ({ location }) => {
  const { isLoading } = useSelector((state) => state.ui);
  const { isError, errorMessage } = useSelector((state) => state.login);
  const { isAuth, isFacebookLinking } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginValidation),
  });

  const onLoginSubmit = ({ email, password }) => {
    let loginData = {
      username: email,
      password: password,
    };
    dispatch(userLogin(loginData));
  };

  const handleFacebookSignIn = (response) => {
    console.log(response);

    let authData = {
      facebook: {
        id: response.id,
        access_token: response.accessToken,
        expiration_date: response.data_access_expiration_time,
      },
    };
    let facebookData = {
      authData: authData,
      rawData: response,
    };
    dispatch(userFacebookLink(facebookData));
  };

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      console.log("codeResponse", codeResponse.code);
      const tokens = await api
        .post("/parse/functions/googleAuth", { code: codeResponse.code })
        .then((res) => {
          return res.data.result;
        });
      console.log("tokens return", tokens.tokens);
      var decoded_data = jwt_decode(tokens.tokens.id_token);
      console.log(decoded_data);
      let authData = {
        google: {
          id: decoded_data.sub,
          access_token: tokens.tokens.access_token,
          id_token: tokens.tokens.id_token,
        },
      };
      let googleData = {
        authData: authData,
        rawData: decoded_data,
      };
      console.log("googleData", googleData);
      dispatch(userGoogleLink(googleData));
    },
  });

  const facebookButton = (
    <FacebookLogin
      appId="225097305757740"
      autoLoad={false}
      fields="name,email,picture.width(480),first_name,last_name"
      callback={handleFacebookSignIn}
      cssClass={styles.facebook}
      textButton="Continue with Facebook"
      icon="fa-brands fa-facebook"
      isMobile={false}
    />
  );
  useEffect(() => {
    if (isAuth === true) {
      if (location.state && location.state.from) {
        history.push(location.state.from);
      } else {
        history.push("/");
      }
    }
  }, [isAuth]);

  useEffect(() => {
    if (isFacebookLinking === true) {
      // redirect User to SignedUp using facebook
      history.push("/fbsignup");
    }
  }, [isFacebookLinking]);

  if (isAuth === true) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  return (
    <>
      <div className={styles.mainWrapper}>
        {isLoading && <Loading isOverlay={true}></Loading>}
        <div className={styles.loginPage}>
          <Link to="/">
            <img
              src={logo}
              alt="MeCook Vertical Logo"
              className={styles.logo}
            />
          </Link>

          <div className={styles.loginFormWrapper}>
            <p className={styles.greeting}>Log In</p>
            <div className={styles.google} onClick={() => googleLogin()}>
              <i className="lab la-google"></i>
              <span>Continue with Google</span>
            </div>
            {facebookButton}
            {/* <div className={styles.facebook}>
                        <i className="lab la-facebook-square"></i>
                        <span>Log In with Facebook</span>
                    </div> */}
            <p> Or use your email registration</p>
            <form
              className={styles.loginForm}
              onSubmit={handleSubmit(onLoginSubmit)}
            >
              {/* Conditional Rendering Validation Errors */}
              {Object.keys(errors).length !== 0 ? (
                <div className={styles.errorMessages}>
                  {errors.firstName && <p>{errors.firstName.message}</p>}
                  {errors.lastName && <p>{errors.lastName.message}</p>}
                  {errors.email && <p>{errors.email.message}</p>}
                  {errors.password && <p>{errors.password.message}</p>}
                </div>
              ) : null}

              {/* Conditional Rendering Login Errors */}
              {isError ? (
                <div className={styles.errorMessages}>
                  <p>{errorMessage}</p>
                </div>
              ) : null}

              <div className={`${styles.inputWrap} ${styles.email}`}>
                <i className="las la-envelope"></i>
                <input type="text" placeholder="Email" {...register("email")} />
              </div>
              <div className={`${styles.inputWrap} ${styles.password}`}>
                <i className="las la-lock"></i>
                <input
                  type="password"
                  placeholder="Password"
                  {...register("password")}
                />
              </div>
              <Link to="/reset_password" className={styles.forgotPass}>
                Forgot Password?
              </Link>

              <SubmitButton text="Login"></SubmitButton>
            </form>
            <p className={styles.newAccount}>
              {" "}
              New to Mecook?{" "}
              {typeof location.state != "undefined" ? (
                <>
                  <Link
                    to={{
                      pathname: "/signup",
                      state: { from: location.state.from },
                    }}
                  >
                    Create an account
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to={{
                      pathname: "/signup",
                    }}
                  >
                    Create an account
                  </Link>
                </>
              )}
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Login;
