import styles from './ResetPassword.module.scss';
import logo from '../../assets/images/logo-vertical.svg';
import SubmitButton from '../../components/SubmitButton';
import {useEffect} from 'react';

import {useSelector, useDispatch} from 'react-redux';
import { Link } from 'react-router-dom';

import Loading from '../../components/Loading';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema as ResetPasswordValidation } from '../../validations/resetPassword';

import {resetPassword,cleanUpState} from './resetPasswordSlice';

const ResetPassword = () => {

    const dispatch = useDispatch();

    const {register, handleSubmit, formState: {errors}} = useForm({
        resolver: yupResolver(ResetPasswordValidation)
    })

    const {isLoading} = useSelector(state => state.ui);
    const {isError, errorMessage, isSuccess} = useSelector(state => state.resetPassword);


    const onResetSubmit = ({email}) => {
        dispatch(resetPassword(email));
    }

    useEffect(() => {
        return () => {
            dispatch(cleanUpState());
        }
    }, [])

    return(
        <>
            <div className={styles.mainWrapper}>
                {
                    isLoading && <Loading isOverlay={true}></Loading>
                }
                <div className={styles.top_wrapper}>
                    <Link to="/">
                        <img src={logo} alt="MeCook Vertical Logo" className={styles.logo} />
                    </Link>
                    <p className={styles.greeting}>Reset Your Password</p>
                </div>
                <div className={styles.bottom_wrapper}>
                    {!isSuccess?(
                        <>
                        <p> Enter your account's email bellow</p>
                        <form className={styles.resetPasswordForm} onSubmit={handleSubmit(onResetSubmit)}>
                        {/* Conditional Rendering Validation Errors */}
                        {Object.keys(errors).length !== 0 ? (
                            <div className={styles.errorMessages}>
                                {errors.email && (<p>{errors.email.message}</p>)}
                            </div>
                        ) : null}

                        {/* Conditional Rendering Login Errors */}
                        {isError ? (
                            <div className={styles.errorMessages}>
                                <p>{errorMessage}</p>
                            </div>
                        ) : null}

                        <div className={`${styles.inputWrap} ${styles.email}`}>
                            <i className="las la-envelope"></i>
                            <input type="text" placeholder="Email" {...register("email")} />
                        </div>

                        <SubmitButton text="Reset Password"></SubmitButton>
                    </form>
                    </>
                    ):(
                        <>
                            <p> Please check your email for a link to reset your password</p>
                            <div className={styles.outerWrap}>
                                <Link to="/" className={styles.goBackHome}>Go Back to Home Page</Link>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default ResetPassword;