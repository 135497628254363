import {createSlice, createAsyncThunk, current} from '@reduxjs/toolkit';
import api from '../../../redux/config/axiosApiConfig';



const initialState = {
    isSuccess: false,
    isError:  false,
    errorMessage: null,
    userPhotoUploading: false,
}

export const uploadUserPhoto = createAsyncThunk(
    'userProfile/uploadUserPhoto',
    (file,thunkAPI) => {
        return api.post('/parse/files/'+file.name,file,{
            headers: {
                'Content-Type': 'image/jpeg'
            }
        }
        ).then((res) => {
            let currentUserId = thunkAPI.getState().auth.currentUser.objectId;
            let pictureObj = {
                userPhoto: {
                    "name": res.data.name,
                    "url": res.data.url,
                    "__type": "File"
                }
            }
            return api.put('/parse/users/'+currentUserId,pictureObj).then((res) => {
                console.log('return upload user photo: ',res.data);
                return pictureObj;
            }).catch((err) => {
                console.log('Error upload user photo: ',err);
                return thunkAPI.rejectWithValue(err.response.data);
            })
        }).catch((err) => {
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        })
    }
)

export const saveUserUpdate = createAsyncThunk(
    'userProfile/saveUserUpdate',
    (data,thunkAPI) => {
        const updateData = {
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone
        }
        const currentUserId = thunkAPI.getState().auth.currentUser.objectId;
        return api.put('/parse/users/'+currentUserId,updateData).then(() => {
            return updateData;
        }).catch((err) => {
            console.log('Error save user update: ',err);
            return thunkAPI.rejectWithValue(err.response.data);
        })
    }
)

const userProfileSlice = createSlice({
    name: "userProfile",
    initialState,
    reducers: {},
    extraReducers: {
        [uploadUserPhoto.pending]: (state) => {
            state.userPhotoUploading = true
        },
        [uploadUserPhoto.fulfilled]: (state) => {
            state.userPhotoUploading = false
        },
       [uploadUserPhoto.rejected]: (state) => {
           state.userPhotoUploading = false
       }
    }
})

export default userProfileSlice.reducer;