import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../redux/config/axiosApiConfig";

export const getAllLocalCook = createAsyncThunk(
  "home/getAllLocalCook",
  (filter, thunkAPI) => {
    // const localCoordinate = thunkAPI.getState().general.deliveryCoordinates;
    const deliverySuburb = thunkAPI.getState().general.deliverySuburb;
    return api
      .get("/parse/users", {
        params: {
          where: {
            isCook: true,
            applicationStatus: "Approved",
            deliverySuburb: deliverySuburb,
            // $or: [
            //   {
            //     suburb: deliverySuburb,
            //   },
            // ],
            // location: {
            //   $nearSphere: {
            //     __type: "GeoPoint",
            //     latitude: localCoordinate.lat,
            //     longitude: localCoordinate.lng,
            //   },
            //   $maxDistanceInKilometers: 5.0,
            // },
          },
          limit: 10,
          include: "menuDishes",
        },
      })
      .then((res) => {
        let formatData = {
          results: res.data.results,
          filter: filter,
        };
        return formatData;
      })
      .catch((err) => {
        console.log("Error getAllLocalCook return data: ", err.response.data);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

const initialState = {
  isSuccess: false,
  isError: false,
  errorMessage: null,
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    cleanUpState: () => {
      return initialState;
    },
    getDishesByFilter: (state, action) => {
      const filter = action.payload;
      // At the moment, filter is only Date
      let dishesResult = new Array();
      state.localCook.map((cook) => {
        for (let i = 0; i < cook.menuDishes.length; i++) {
          const dish = cook.menuDishes[i]; // These dish should be filter of approval only
          if (
            dish.weeklyAvailability.filter(
              (e) =>
                // Dish availability weekly match filter date
                e.day === filter.date.substring(0, 3) &&
                e.isAvailable &&
                // Cook is setup pickupTime for that same matching filter date
                cook.weeklyPickupTime.filter(
                  (i) => i.day === e.day && i.time !== ""
                ).length > 0
            ).length > 0
          ) {
            var dishData = {
              cook: cook,
              dishContent: dish,
            };
            dishesResult.push(dishData);
          }
        }
        state.localDishesBySelectedDate = dishesResult;
      });
    },
  },
  extraReducers: {
    [getAllLocalCook.fulfilled]: (state, action) => {
      // Set all cook base on local area - and Approved on their application
      let allLocalCooks = action.payload.results;
      let availableCooks = new Array();
      let isDishAvailable = false;
      allLocalCooks.map((cook) => {
        // Filter only approval Dishes
        cook.menuDishes = cook.menuDishes.filter(
          (e) => e.status === "Approved" || e.status === "Live - Approved"
        );
        for (let i = 0; i < cook.menuDishes.length; i++) {
          const dish = cook.menuDishes[i];
          if (
            dish.weeklyAvailability.filter(
              (e) =>
                e.isAvailable &&
                // check if pickup time has been set for this dish available day.
                cook.weeklyPickupTime.filter(
                  (i) => i.day === e.day && i.time !== ""
                ).length > 0
            ).length > 0
          ) {
            isDishAvailable = true;
            break;
          }
        }
        if (isDishAvailable) {
          availableCooks.push(cook);
        }
      });
      state.localCook = availableCooks;

      // Set cook filter by Date
      if (action.payload.filter) {
        var dateFilter = action.payload.filter.date;
        if (action.payload.results.length > 0) {
          let isDishAvailable = false;
          let filterResults = new Array();
          action.payload.results.map((cook) => {
            for (let i = 0; i < cook.menuDishes.length; i++) {
              const dish = cook.menuDishes[i];
              if (
                dish.weeklyAvailability.filter(
                  (e) =>
                    e.day === dateFilter.substring(0, 3) &&
                    e.isAvailable &&
                    // check if pickup time has been set for this dish available day.
                    cook.weeklyPickupTime.filter(
                      (i) => i.day === e.day && i.time !== ""
                    ).length > 0
                ).length > 0
              ) {
                isDishAvailable = true;
                break;
              }
            }
            if (isDishAvailable) {
              filterResults.push(cook);
            }
          });
          state.localCookBySelectedDate = filterResults;
        }
      }
    },
  },
});

export const { cleanUpState, getDishesByFilter } = homeSlice.actions;

export default homeSlice.reducer;
