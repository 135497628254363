import styles from "./ApplicationProcess.module.scss";
import styleDashboard from "../CookDashboard.module.scss";

import {
  TextInput,
  TextareaInput,
  PhotoUpload,
  CuisineInput,
  PickupLocationInput,
} from "../components/InputField";
import SubmitButton from "../../../components/SubmitButton";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema as cookProfileValidation } from "../../../validations/applicationProcess";

import { useEffect } from "react";

// import { saveProfileUpdate, uploadMenuMainPhoto } from '../CookProfile/CookProfileSlice';

import { uploadUserPhoto } from "../../UserAccount/UserProfile/userProfileSlice";

import {
  connectNewStripeAccount,
  resumeConnectNewStripeAccount,
  getStripeConnectedAccount,
  cleanUpState,
} from "../CookPayment/CookPaymentSlice";

import { submitProfile } from "./ApplicationProcessSlice";

import Loading from "../../../components/Loading";

const ApplicationProcess = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.ui);
  const {
    accountLinkUrl,
    connectedAccount,
    isLoading: loadingConnectedAccount,
  } = useSelector((state) => state.cookPayment);
  const { currentStep, profileSubmitting, profileSaved } = useSelector(
    (state) => state.applicationProcess
  );

  const preLoadedValue = {
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    email: currentUser.email,
    phone: currentUser.phone,
    intro: currentUser.cookIntro,
  };

  const handleConnectStripe = () => {
    if (
      currentUser.stripeConnectedAccount != "" &&
      typeof currentUser.stripeConnectedAccount !== "undefined"
    ) {
      // resume stripeConnect -- Create new Link
      dispatch(resumeConnectNewStripeAccount(currentUser.objectId));
    } else {
      // create new stripeConnect
      dispatch(connectNewStripeAccount(currentUser.objectId));
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(cookProfileValidation),
    defaultValues: preLoadedValue,
  });

  const dispatch = useDispatch();

  const cookProfileSubmit = ({
    firstName,
    lastName,
    phone,
    intro,
    cuisine,
    address,
    coordinates,
    profilePhoto,
  }) => {
    let saveData = {
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      intro: intro,
      cuisine: cuisine,
      address: address,
      location: coordinates,
    };
    dispatch(submitProfile(saveData));
  };

  const handleUploadPicture = (file) => {
    dispatch(uploadUserPhoto(file));
  };

  useEffect(() => {
    if (accountLinkUrl) {
      window.location.href = accountLinkUrl;
    }
    return () => {
      dispatch(cleanUpState());
    };
  }, [accountLinkUrl]);

  useEffect(() => {
    if (
      currentUser.stripeConnectedAccount != "" &&
      typeof currentUser.stripeConnectedAccount !== "undefined"
    ) {
      dispatch(getStripeConnectedAccount(currentUser.stripeConnectedAccount));
    }
    return () => {
      // cleanup
    };
  }, []);

  if (currentUser.applicationStatus === "Approved") {
    return (
      <Redirect
        to={{
          pathname: "/cooking/order",
        }}
      />
    );
  }

  return (
    <div className={styleDashboard.contentContainer}>
      <p className={styleDashboard.heading}>
        Cook Application Process{" "}
        <span className={styles.status}>{currentUser.applicationStatus} </span>
      </p>

      {profileSubmitting && <Loading isOverlay={true}></Loading>}
      {isLoading && (
        <Loading
          isOverlay={true}
          message="Please wait! You will be redirected to Stripe to complete the onboarding process."
        ></Loading>
      )}

      {profileSaved && profileSaved == true ? (
        <div>
          <div className={styleDashboard.section}>
            <p className={`${styleDashboard.sectionTitle} ${styles.stepTitle}`}>
              <span className={currentStep == "1" ? styles.current : null}>
                Step 1: Submit your profiles
              </span>{" "}
              <span className={currentStep == "2" ? styles.current : null}>
                Step 2: Setup your payout
              </span>
            </p>
            {currentUser.stripeConnectedAccount == "" ||
            typeof currentUser.stripeConnectedAccount == "undefined" ? (
              <>
                <div className={styleDashboard.wrapperBox}>
                  <p className={styles.paymentIntro}>
                    Mecook partners with{" "}
                    <img
                      className={styles.stripeIcon}
                      src="https://upload.wikimedia.org/wikipedia/commons/b/ba/Stripe_Logo%2C_revised_2016.svg"
                      alt="stripe logo"
                    />{" "}
                    to transfer earnings to your bank account.
                  </p>

                  <div
                    className={styles.connectStripe}
                    onClick={handleConnectStripe}
                  >
                    Set up payouts
                  </div>

                  <p>
                    You will be redirected to Stripe to complete the onboarding
                    process.
                  </p>
                </div>
              </>
            ) : (
              <>
                {connectedAccount && (
                  <>
                    {connectedAccount.charges_enabled &&
                    connectedAccount.details_submitted ? (
                      <>
                        <div className={styleDashboard.wrapperBox}>
                          <p className={styles.paymentIntro}>
                            Mecook partners with{" "}
                            <img
                              className={styles.stripeIcon}
                              src="https://upload.wikimedia.org/wikipedia/commons/b/ba/Stripe_Logo%2C_revised_2016.svg"
                              alt="stripe logo"
                            />{" "}
                            to transfer earnings to your bank account.
                          </p>

                          <div
                            className={`${styles.connectStripe} ${styles.success}`}
                          >
                            Your payouts has been set up successfully
                          </div>

                          <p>
                            Thank you! Your application has been submitted. You
                            will receive an email for your application outcome.
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={styleDashboard.wrapperBox}>
                          <p className={styles.paymentIntro}>
                            Mecook partners with{" "}
                            <img
                              className={styles.stripeIcon}
                              src="https://upload.wikimedia.org/wikipedia/commons/b/ba/Stripe_Logo%2C_revised_2016.svg"
                              alt="stripe logo"
                            />{" "}
                            to transfer earnings to your bank account.
                          </p>

                          <div
                            className={styles.connectStripe}
                            onClick={handleConnectStripe}
                          >
                            Resume your payout setup
                          </div>

                          <p>
                            You will be redirected to Stripe to complete the
                            onboarding process.
                          </p>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(cookProfileSubmit)}
          className={styles.cookProfileForm}
        >
          <div className={styleDashboard.section}>
            <p className={`${styleDashboard.sectionTitle} ${styles.stepTitle}`}>
              <span className={currentStep == "1" ? styles.current : null}>
                Step 1: Submit your profiles
              </span>{" "}
              <span className={currentStep == "2" ? styles.current : null}>
                Step 2: Setup your payout
              </span>
            </p>
            <div className={styleDashboard.wrapperBox}>
              <div className={styles.formWrapper}>
                <div className={styles.row2Col}>
                  <div className={styles.col}>
                    <div className={styles.profilePhoto}>
                      <div
                        className={styles.avatar}
                        style={
                          currentUser.userPhoto
                            ? {
                                backgroundImage:
                                  'url("' + currentUser.userPhoto.url + '")',
                              }
                            : currentUser.facebookPictureUrl && {
                                backgroundImage:
                                  'url("' +
                                  currentUser.facebookPictureUrl +
                                  '")',
                              }
                        }
                      >
                        {currentUser.userPhoto ||
                        currentUser.facebookPictureUrl ? (
                          <>
                            <label className={`${styles.upload_hover}`}>
                              <p>Upload Picture</p>
                              <input
                                type="file"
                                name="updatePhoto"
                                id="profile_picture"
                                {...register("updatePhoto")}
                                onChange={(e) => {
                                  handleUploadPicture(e.target.files[0]);
                                }}
                              />
                            </label>
                          </>
                        ) : (
                          <>
                            <label className={styles.label}>
                              <p>Your profile Photo</p>
                              <input
                                type="file"
                                name="profilePhoto"
                                id="profile_picture"
                                {...register("profilePhoto")}
                                onChange={(e) => {
                                  handleUploadPicture(e.target.files[0]);
                                }}
                              />
                            </label>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.col}></div>
                </div>

                <div className={styles.row2Col}>
                  <div className={styles.col}>
                    <TextInput
                      label="First name"
                      name="firstName"
                      register={register("firstName")}
                    ></TextInput>
                  </div>
                  <div className={styles.col}>
                    <TextInput
                      label="Last name"
                      name="lastName"
                      register={register("lastName")}
                    ></TextInput>
                  </div>
                </div>

                <div className={styles.row2Col}>
                  <div className={styles.col}>
                    <TextInput
                      disabled
                      label="Email"
                      name="email"
                      register={register("email")}
                    ></TextInput>
                  </div>
                  <div className={styles.col}>
                    <TextInput
                      label="Mobile phone"
                      name="phone"
                      register={register("phone")}
                    ></TextInput>
                  </div>
                </div>

                <div className={styles.row2Col}>
                  <div className={styles.col}>
                    <TextareaInput
                      label="Intro"
                      name="intro"
                      register={register("intro")}
                      placeholder="Ex: While growing up in Northern India and traveling across the world, I had the opportunity to work alongside many chefs and have learned to create flavors that will make your mounth water by just the aroma alone. I have incorporated my family’s secret recipes with my knowledge to prepare these dished for you. I am passionate about create delicious flavors by using specialty home-made spices."
                      instruction="A brief paragraph that introduces you to your customers. Focus on what inspires you and the food you are serving"
                    ></TextareaInput>
                  </div>
                  <div className={styles.col}>
                    <CuisineInput
                      label="Cuisine's Culture"
                      preloadData={currentUser.cuisine}
                      register={register("cuisine")}
                      setValue={(data) => {
                        setValue("cuisine", data);
                      }}
                      instruction="Name all related Cuisine that you would cook. Exp: Asian, Japanese ect."
                    ></CuisineInput>
                    {/* <PhotoUpload photo={currentUser.menuCoverPhoto} label="Menu main image" instruction="This image will be used as your menu preview image. So pick the best one to be seen" onChange={(e) => {
                                                    handleUploadPicture(e.target.files[0]);
                                                }} /> */}
                  </div>
                </div>

                <div className={styles.row2Col}>
                  <div className={styles.col}>
                    <PickupLocationInput
                      instruction="For your privacy, this address will only be shown after order sent"
                      label="Pick-up address"
                      registerAddress={register("address")}
                      registerCoordinates={register("coordinates")}
                      preloadData={{
                        Address: currentUser.address,
                        Coordinates: {
                          lat: currentUser.location.latitude,
                          lng: currentUser.location.longitude,
                        },
                      }}
                      setValueAddress={(data) => {
                        setValue("address", data);
                      }}
                      setValueCoordinates={(data) => {
                        setValue("coordinates", data);
                      }}
                    />
                  </div>
                  <div className={styles.col}>
                    {/* <TextInput instruction="For your privacy, this address will only be shown after order sent" label="Pick-up address" name="phone" register={register("phone")}></TextInput> */}
                  </div>
                </div>
              </div>
            </div>
            {Object.keys(errors).length !== 0 ? (
              <div className={styles.errorMessages}>
                {/* {errors.profilePhoto && (<p>{errors.profilePhoto.message}</p>)} */}
                {errors.firstName && <p>{errors.firstName.message}</p>}
                {errors.lastName && <p>{errors.lastName.message}</p>}
                {errors.email && <p>{errors.email.message}</p>}
                {errors.phone && <p>{errors.phone.message}</p>}
                {errors.intro && <p>{errors.intro.message}</p>}
                {errors.cuisine && <p>{errors.cuisine.message}</p>}
                {errors.address && <p>{errors.address.message}</p>}
              </div>
            ) : null}
          </div>
          <SubmitButton text="Submit your profile"></SubmitButton>
        </form>
      )}
    </div>
  );
};

export default ApplicationProcess;
