import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("* First name is required"),
  lastName: Yup.string().required("* Last name is required"),
  email: Yup.string().email("* Invalid email").required("* Email is required"),
  // password: Yup.string().required("* Password is required"),
  address: Yup.string().required("* Your location is required"),
  mobile: Yup.string()
    .required("* Mobile number is required")
    .matches(phoneRegExp, "* Your mobile number is invalid"),
  // passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Password not match')
});

// For existed User
export const validationSchemaFU = Yup.object().shape({
  address: Yup.string().required("* Your location is required"),
  mobile: Yup.string()
    .required("* Mobile number is required")
    .matches(phoneRegExp, "* Your mobile number is invalid"),
  // passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Password not match')
});
