import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../redux/config/axiosApiConfig";

export const getMenuByCurrentCook = createAsyncThunk(
  "cookMyMenu/getMenuByCurrentCook",
  (data, thunkAPI) => {
    const currentCookId = thunkAPI.getState().auth.currentUser.objectId;
    return api
      .get(`/parse/classes/Dish`, {
        params: {
          where: {
            cookBy: {
              __type: "Pointer",
              className: "_User",
              objectId: currentCookId,
            },
          },
        },
      })
      .then((res) => {
        console.log("getMenuByCurrentCook return data: ", res);
        return res.data.results;
      })
      .catch((err) => {
        console.log(
          "Error getMenuByCurrentCook return data: ",
          err.response.data
        );
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

const initialState = {
  isSuccess: false,
  isError: false,
  errorMessage: null,
  dishes: [],
};

const cookMyMenuSlice = createSlice({
  name: "CookMyMenu",
  initialState,
  reducers: {
    cleanUpState: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getMenuByCurrentCook.fulfilled]: (state, action) => {
      state.dishes = action.payload;
      state.isSuccess = true;
    },
  },
});

export const { cleanUpState } = cookMyMenuSlice.actions;

export default cookMyMenuSlice.reducer;
