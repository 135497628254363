import styles from './LoginUser.module.scss';
import { useSelector, useDispatch } from 'react-redux';

import { toggleUserQuickMenu } from '../../../../redux/uiSlice';

import useClickOutSide from '../../../../hooks/useClickOutSide';

import UserQuickMenu from '../UserQuickMenu';

const LoginUser = () => {

    const dispatch = useDispatch();

    const { domRef } = useClickOutSide(() => {
        showUserQuickMenu && dispatch(toggleUserQuickMenu());
    });

    const { currentUser } = useSelector(state => state.auth);
    const { showUserQuickMenu } = useSelector(state => state.ui)

    const showQuickMenu = () => {
        dispatch(toggleUserQuickMenu());
    }

    return (
        <div ref={domRef} className={styles.login_user} onClick={showQuickMenu}>
            <div className={styles.inner_wrap}>
                <div className={styles.avatar}
                    style={currentUser.userPhoto ? (
                        { backgroundImage: 'url("' + currentUser.userPhoto.url + '")' }
                    ) : (currentUser.facebookPictureUrl && { backgroundImage: 'url("' + currentUser.facebookPictureUrl + '")' })}>
                    {
                        !currentUser.userPhoto && (
                            !currentUser.facebookPictureUrl && (<i className="las la-user-circle"></i>)
                        )
                    }
                </div>
                <p className={styles.greeting}> {currentUser.firstName} {showUserQuickMenu ? <i className="las la-sort-up"></i> : <i className="las la-sort-down"></i>}</p>
            </div>
            {showUserQuickMenu && <UserQuickMenu></UserQuickMenu>}
        </div>
    )
}

export default LoginUser