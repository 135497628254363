import styles from "./SignUpCook.module.scss";
import logo from "../../assets/images/logo-vertical.svg";
import SubmitButton from "../../components/SubmitButton";
import HeaderStandard from "../../components/HeaderStandard";
import HeaderTeasing from "../../components/HeaderTeasing";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  validationSchema as CookSignUpValidation,
  validationSchemaFU as CookSignUpValidationFU,
} from "../../validations/cookSignUp";
import InputPlacesAutoComplete from "../../components/InputPlacesAutoComplete";

import { useEffect } from "react";

import Loading from "../../components/Loading";
import {
  cookRegisterAndSignUp,
  cookRegisterFromUser,
  cleanUpState,
  cookInterest,
} from "./signUpCookSlice";
import Footer from "../../components/Footer";

const SignUpCook = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuth, currentUser } = useSelector((state) => state.auth);
  const { isSuccess, isError, errorMessage } = useSelector(
    (state) => state.signUpCook
  );
  const { isLoading } = useSelector((state) => state.ui);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(CookSignUpValidation),
  });

  const {
    register: registerFU,
    handleSubmit: handleSubmitFU,
    formState: { errors: errorsFU },
    setValue: setValueFU,
  } = useForm({
    resolver: yupResolver(CookSignUpValidationFU),
  });

  const onSignUpSubmit = ({
    email,
    firstName,
    lastName,
    password,
    address,
    coordinates,
    mobile,
    stage,
    postCode,
    suburb,
  }) => {
    let SignUpData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      location: coordinates,
      address: address,
      phone: mobile,
      stage: stage,
      postCode: postCode,
      suburb: suburb,
    };
    console.log("email", email);
    console.log("firstName", firstName);
    console.log("lastName", lastName);
    console.log("password", password);
    console.log("address", address);
    console.log("coordinates", coordinates);
    // dispatch(cookRegisterAndSignUp(SignUpData));
    dispatch(cookInterest(SignUpData));
  };

  const onSignUpSubmitFU = ({ address, coordinates, mobile }) => {
    let SignUpData = {
      location: coordinates,
      address: address,
      phone: mobile,
    };
    console.log("address", address);
    console.log("coordinates", coordinates);
    dispatch(cookRegisterFromUser(SignUpData));
  };

  useEffect(() => {
    if (isSuccess) history.push("/welcome");
    return () => {
      dispatch(cleanUpState());
    };
  }, [isSuccess]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <HeaderStandard />
      <div className={styles.mainWrapper}>
        {isLoading && <Loading isOverlay={true}></Loading>}
        <h1 className={`${styles.pageHeader} title-h1`}>
          Become a Cook Partner
        </h1>

        <div className={styles.cookSignUpWrapper}>
          <div className={styles.formWrapper}>
            <p className={`${styles.formTitle} title-h3`}>
              Sign Up to become a Cook
            </p>
            {
              !isAuth ? ( // if not yet login then
                // Register Cook as new
                <form onSubmit={handleSubmit(onSignUpSubmit)}>
                  {/* Conditional Rendering Validation Errors */}
                  {Object.keys(errors).length !== 0 ? (
                    <div className={styles.errorMessages}>
                      {errors.firstName && <p>{errors.firstName.message}</p>}
                      {errors.lastName && <p>{errors.lastName.message}</p>}
                      {errors.mobile && <p>{errors.mobile.message}</p>}
                      {errors.email && <p>{errors.email.message}</p>}
                      {/* {errors.password && <p>{errors.password.message}</p>} */}
                      {errors.address && <p>{errors.address.message}</p>}
                    </div>
                  ) : null}

                  {/* Conditional Rendering SignUp Errors */}
                  {isError ? (
                    <div className={styles.errorMessages}>
                      <p>{errorMessage}</p>
                    </div>
                  ) : null}

                  <div className={styles.formRow}>
                    <input
                      type="text"
                      className={styles.textInput}
                      placeholder="First name"
                      {...register("firstName")}
                    />
                    <input
                      type="text"
                      className={styles.textInput}
                      placeholder="Last name"
                      {...register("lastName")}
                    />
                  </div>
                  <div className={styles.formRow}>
                    <input
                      type="text"
                      className={styles.textInput}
                      placeholder="Mobile number"
                      {...register("mobile")}
                    />
                  </div>
                  <div className={styles.formRow}>
                    <input
                      type="text"
                      className={styles.textInput}
                      placeholder="Email"
                      {...register("email")}
                    />
                  </div>
                  {/* <div className={styles.formRow}>
                  <input
                    type="password"
                    className={styles.textInput}
                    placeholder="Password"
                    {...register("password")}
                  />
                </div> */}
                  <div className={styles.formRow}>
                    <InputPlacesAutoComplete
                      customInputClass={styles.textInput}
                      placeholder="Address where you are cooking from"
                      hasIcon={false}
                      onChange={(selectedAddress) => {
                        setValue("address", selectedAddress.Address);
                        setValue("coordinates", selectedAddress.Coordinates);
                        if (selectedAddress.hasOwnProperty("Postcode")) {
                          setValue(
                            "postCode",
                            selectedAddress.Postcode.long_name
                          );
                        }
                        if (selectedAddress.hasOwnProperty("Stage")) {
                          setValue("stage", selectedAddress.Stage.short_name);
                        }
                        if (selectedAddress.hasOwnProperty("Suburb")) {
                          setValue("suburb", selectedAddress.Suburb.short_name);
                        }
                      }}
                    ></InputPlacesAutoComplete>
                    <input type="hidden" {...register("postCode")} />
                    <input type="hidden" {...register("suburb")} />
                    <input type="hidden" {...register("stage")} />
                    <input type="hidden" {...register("address")} />
                    <input type="hidden" {...register("coordinates")} />
                  </div>
                  {/* <div className={styles.formRow}>
                                <input type="text" className={styles.textInput} placeholder="Food's culture" />
                            </div> */}
                  <p className={styles.consentMess}>
                    By signing up, you have agreed to Mecook's{" "}
                    <a target="_blank" href="/terms">
                      {" "}
                      Terms of service{" "}
                    </a>{" "}
                    &{" "}
                    <a target="_blank" href="/privacy">
                      {" "}
                      Privacy Policy
                    </a>
                    .
                  </p>
                  <SubmitButton text="Submit"></SubmitButton>
                </form>
              ) : // If already Login then
              // Check if current account is cook?
              currentUser.isCook ? (
                history.push("/")
              ) : (
                <form onSubmit={handleSubmitFU(onSignUpSubmitFU)}>
                  {/* Conditional Rendering Validation Errors */}
                  {Object.keys(errorsFU).length !== 0 ? (
                    <div className={styles.errorMessages}>
                      {errorsFU.mobile && <p>{errorsFU.mobile.message}</p>}
                      {errorsFU.address && <p>{errorsFU.address.message}</p>}
                    </div>
                  ) : null}
                  <div className={styles.formRow}>
                    <input
                      type="text"
                      className={styles.textInput}
                      placeholder="Mobile number"
                      {...registerFU("mobile")}
                    />
                  </div>
                  <div className={styles.formRow}>
                    <InputPlacesAutoComplete
                      customInputClass={styles.textInput}
                      placeholder="Address or postcode"
                      hasIcon={false}
                      onChange={(selectedAddress) => {
                        setValueFU("address", selectedAddress.Address);
                        setValueFU("coordinates", selectedAddress.Coordinates);
                      }}
                    ></InputPlacesAutoComplete>
                    <input type="hidden" {...registerFU("address")} />
                    <input type="hidden" {...registerFU("coordinates")} />
                  </div>
                  <p className={styles.consentMess}>
                    By signing up, you have agreed to Mecook's{" "}
                    <a target="_blank" href="/terms">
                      {" "}
                      Terms of service{" "}
                    </a>{" "}
                    &{" "}
                    <a target="_blank" href="/privacy">
                      {" "}
                      Privacy Policy
                    </a>
                    .
                  </p>
                  <SubmitButton text="Submit"></SubmitButton>
                </form>
              )
              // Register cook from user
            }
          </div>
          <div className={styles.cookSignUpFeature}>
            <p className={`${styles.cookSignUpFeature__title} title-h2`}>
              Start earn more by doing what you love
            </p>
            <div className={styles.cookSignUpFeature__featureItemWrap}>
              <div>
                <div className={styles.cookSignUpFeature__icon}>
                  <i className="las la-money-bill-wave"></i>
                </div>
              </div>
              <div>
                <div className={styles.cookSignUpFeature__itemTitle}>
                  Make more income
                </div>
                <div className={styles.cookSignUpFeature__mess}>
                  It’s completely free to join and start earning.
                </div>
              </div>
            </div>

            <div className={styles.cookSignUpFeature__featureItemWrap}>
              <div>
                <div className={styles.cookSignUpFeature__icon}>
                  <i className="las la-calendar"></i>
                </div>
              </div>
              <div>
                <div className={styles.cookSignUpFeature__itemTitle}>
                  Be your own boss
                </div>
                <div className={styles.cookSignUpFeature__mess}>
                  Set your own menu and prices, then work whenever you want. We
                  help you out with marketing, payments, delivery and customer
                  support.
                </div>
              </div>
            </div>

            <div className={styles.cookSignUpFeature__featureItemWrap}>
              <div>
                <div className={styles.cookSignUpFeature__icon}>
                  <i className="las la-heart"></i>
                </div>
              </div>
              <div>
                <div className={styles.cookSignUpFeature__itemTitle}>
                  Serve beloved customers
                </div>
                <div className={styles.cookSignUpFeature__mess}>
                  Sharing your culture and story with customers across your
                  community with your passion.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default SignUpCook;
