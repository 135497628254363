import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../redux/config/axiosApiConfig";

export const saveDishPhoto = createAsyncThunk(
  "EditDish/saveDishPhoto",
  (data, thunkAPI) => {
    return api
      .post("/parse/files/" + data.file.name, data.file, {
        headers: {
          "Content-Type": "image/jpeg",
        },
      })
      .then((res) => {
        let pictureObj = {
          Photo: {
            name: res.data.name,
            url: res.data.url,
            __type: "File",
          },
        };
        return api
          .put("/parse/classes/Dish/" + data.id, pictureObj)
          .then((res) => {
            console.log("saveDishPhoto: ", res.data);
            return pictureObj;
          })
          .catch((err) => {
            console.log("Error saveDishPhoto: ", err);
            return thunkAPI.rejectWithValue(err.response.data);
          });
      })
      .catch((err) => {
        console.log(err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const saveDish = createAsyncThunk(
  "EditDish/saveDish",
  (data, thunkAPI) => {
    const updateData = {
      name: data.content.name,
      cuisineCulture: data.content.cuisineCulture,
      description: data.content.description,
      ingredients: data.content.ingredient,
      portionSize: data.content.portion,
      spicyLevel: data.content.spicy.toString(),
      weeklyAvailability: data.content.availableDays,
      price: parseFloat(data.content.price),
    };
    return api
      .put("/parse/classes/Dish/" + data.id, updateData)
      .then(() => {
        return updateData;
      })
      .catch((err) => {
        console.log("Error saveDish: ", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const linkDishToCook = createAsyncThunk(
  "EditDish/linkDishToCook",
  (dishId, thunkAPI) => {
    const currentUser = thunkAPI.getState().auth.currentUser;
    const currentUserId = currentUser.objectId;
    const menuDishes = currentUser.menuDishes;
    const updateMenuDishes = [
      ...menuDishes,
      {
        __type: "Pointer",
        className: "Dish",
        objectId: dishId,
      },
    ];
    const updateData = {
      menuDishes: updateMenuDishes,
    };
    return api
      .put("/parse/users/" + currentUserId, updateData)
      .then(() => {
        return updateData;
      })
      .catch((err) => {
        console.log("Error linkDishToCook: ", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const getDishContent = createAsyncThunk(
  "EditDish/getDishContent",
  (dishId, thunkAPI) => {
    return api
      .get(`/parse/classes/Dish/${dishId}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log("error getDishContent", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

const initialState = {
  isSuccess: false,
  isError: false,
  errorMessage: null,
  sendingRequest: false,
  dishContent: {},
};

const EditDishSlice = createSlice({
  name: "editDish",
  initialState,
  reducers: {
    cleanUpState: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getDishContent.fulfilled]: (state, action) => {
      state.dishContent = action.payload;
    },

    [saveDish.pending]: (state) => {
      state.sendingRequest = true;
    },
    [saveDish.fulfilled]: (state) => {
      state.sendingRequest = false;
      state.isSaved = true;
    },
    [saveDish.rejected]: (state) => {
      state.sendingRequest = false;
      state.isSaved = false;
    },
  },
});

export const { cleanUpState } = EditDishSlice.actions;
export default EditDishSlice.reducer;
