import styles from "./SideMenuCook.module.scss";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const SideMenuCook = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const { currentUser } = useSelector((state) => state.auth);
  return (
    <div className={styles.sideMenu}>
      {currentUser.applicationStatus === "Approved" && (
        <div
          className={`${pathName == "/cooking/order" ? styles.active : ""} ${
            styles.menuItem
          }`}
        >
          <Link to="/cooking/order">
            <i className="las la-fire-alt"></i>
            <span>Orders</span>
          </Link>
        </div>
      )}
      <div
        className={`${pathName == "/cooking/profile" ? styles.active : ""} ${
          styles.menuItem
        }`}
      >
        <Link to="/cooking/profile">
          <i className="las la-user"></i>
          <span>Profile</span>
        </Link>
      </div>
      <div
        className={`${pathName == "/cooking/menu" ? styles.active : ""} ${
          styles.menuItem
        }`}
      >
        <Link to="/cooking/menu">
          <i className="las la-utensils"></i>
          <span>Menu</span>
        </Link>
      </div>
      <div
        className={`${pathName == "/cooking/payment" ? styles.active : ""} ${
          styles.menuItem
        }`}
      >
        <Link to="/cooking/payment">
          <i className="las la-credit-card"></i>
          <span>Payout</span>
        </Link>
      </div>
      {currentUser.applicationStatus === "Approved" && (
        <div
          className={`${
            pathName == "/cooking/availability" ? styles.active : ""
          } ${styles.menuItem}`}
        >
          <Link to="/cooking/availability">
            <i className="las la-calendar-alt"></i>
            <span>My Availability</span>
          </Link>
        </div>
      )}
      <div
        className={`${pathName == "/cooking/support" ? styles.active : ""} ${
          styles.menuItem
        }`}
      >
        <Link to="/cooking/support">
          <i className="las la-life-ring"></i>
          <span>Support</span>
        </Link>
      </div>
    </div>
  );
};

export default SideMenuCook;
