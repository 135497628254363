import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("* First name is required"),
    lastName: Yup.string().required("* Last name is required"),
    phone: Yup.string().required("* Phone number is required").matches(phoneRegExp, 'Phone number is not valid'),
    intro: Yup.string().required("* Intro is required"),
    email: Yup.string().email("* Invalid email").required("* Email is required"),
    cuisine: Yup.array().min(1, "* Cuisine is required"),
    address: Yup.string().required("* Please select your pickup address from the dropdown list"),
    // profilePhoto: Yup.mixed()
    // .test("fileRequired","* Your profile photo is required", (value) => {
    //     return value && value.length>=1;
    // }),
})