import PageFooter from "../../layouts/pageFooter";
import PageHeader from "../../layouts/pageHeader";
import logo from "../../assets/images/logo-vertical.svg";

const InterestWelcome = () => {
  return (
    <>
      <PageHeader></PageHeader>
      <span className=" mt-24 block"></span>
      <div className="mx-auto min-h-[500px] max-w-main-wrapper px-4 tablet:px-10 desktop:px-16">
        <div className=" mx-auto max-w-2xl rounded-md bg-gray-light p-10">
          <h1 className=" mb-8 font-Heading text-h1-dt text-black">
            Thank you!
          </h1>
          <p className=" text-[20px] leading-8">
            😀 Thank you for joining our waiting list! We will keep you up to
            date with our launching. In the mean time, if you have any question
            just email us at support@mecook.app
          </p>
          <p className="mt-4 text-[20px] leading-8">Regards, </p>
          <p className="text-[20px] leading-8">The Mecook Team</p>
        </div>
      </div>
      <PageFooter></PageFooter>
    </>
  );
};

export default InterestWelcome;
