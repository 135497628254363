import styles from "./CookMyMenu.module.scss";
import styleDashboard from "../CookDashboard.module.scss";
import Loading from "../../../components/Loading";
import { Link } from "react-router-dom";

import {
  PhotoUpload,
  CuisineInput,
  WeeklyPickupTimeInput,
} from "../components/InputField";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SubmitButton from "../../../components/SubmitButton";

import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getMenuByCurrentCook,
  cleanUpState,
} from "../CookMyMenu/CookMyMenuSlice";
import { cleanUpState as cleanUpStateCreateDish } from "../../CookDashboard/CreateDish/CreateDishSlice";
import {
  saveMenuGeneral,
  cleanUpState as cleanUpStateCookProfile,
  uploadMenuMainPhoto,
} from "../CookProfile/CookProfileSlice";
import { validationSchema as cookMyMenuValidation } from "../../../validations/cookMyMenu.js";

const CookMyMenu = () => {
  const dispatch = useDispatch();
  const { dishes, isSuccess } = useSelector((state) => state.cookMyMenu);
  const { isLoading } = useSelector((state) => state.ui);
  const { currentUser } = useSelector((state) => state.auth);
  const { submittingForm, isSaved, uploadingMenuMainPhoto } = useSelector(
    (state) => state.cookProfile
  );

  const handleUploadPicture = (file) => {
    const SUPPORTED_FORMATS = [
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png",
    ];
    const maxFileSize = 1000 * 1024; // 1000 kilobytes / 1 megabyte
    let fileType = file.type;
    if (SUPPORTED_FORMATS.includes(fileType) && file.size <= maxFileSize) {
      // Checking file Type before upload
      dispatch(uploadMenuMainPhoto(file));
    } else {
      if (!SUPPORTED_FORMATS.includes(fileType)) {
        alert("Uploading error, file type not supported!");
      } else if (file.size > maxFileSize) {
        alert("Uploading error, file is too large");
      }
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(cookMyMenuValidation),
  });

  const menuGeneralInfoSubmit = ({ cuisine, weeklyPickupTime }) => {
    console.log("tony test", weeklyPickupTime);
    if (weeklyPickupTime === "") weeklyPickupTime = [];
    let saveData = {
      cuisine: cuisine,
      weeklyPickupTime: weeklyPickupTime,
    };
    console.log("menuGeneralInfoSubmit data get before sending", saveData);
    dispatch(saveMenuGeneral(saveData));
  };

  useEffect(() => {
    // cleanupState of Create Dish Slice
    dispatch(cleanUpStateCreateDish());
    dispatch(getMenuByCurrentCook());
    return () => {
      dispatch(cleanUpState());
    };
  }, []);

  return (
    <div className={styleDashboard.contentContainer}>
      <p className={styleDashboard.heading}>My Menu</p>
      {/* <div className={styles.dishFilter}>
                <span className={styles.active}>All Dish</span>
                <span>Live Dish</span>
            </div> */}
      <form
        onSubmit={handleSubmit(menuGeneralInfoSubmit)}
        className={styles.cookProfileForm}
      >
        {submittingForm && <Loading isOverlay={true}></Loading>}
        <div className={styleDashboard.section}>
          <p className={styleDashboard.sectionTitle}>General Info</p>
          <div className={styleDashboard.wrapperBox}>
            <div className={styles.formWrapper}>
              <div className={styles.row2Col}>
                <div className={styles.col}>
                  <PhotoUpload
                    photo={currentUser.menuCoverPhoto}
                    label="Menu main image"
                    isUploading={uploadingMenuMainPhoto}
                    instruction="This image will be used as your menu preview image. So pick the best one to be seen"
                    onChange={(e) => {
                      handleUploadPicture(e.target.files[0]);
                    }}
                  />

                  <CuisineInput
                    label="Cuisine's Culture"
                    preloadData={currentUser.cuisines}
                    register={register("cuisine")}
                    setValue={(data) => {
                      setValue("cuisine", data);
                    }}
                    instruction="Add one by one, all related Cuisine that you would cook. Ex: Asian, Japanese, Pakistani, Southeast Asian, Vietnamese"
                  ></CuisineInput>
                </div>
                <div className={styles.col}>
                  <WeeklyPickupTimeInput
                    label="Weekly Pickup Time"
                    preLoadData={currentUser.weeklyPickupTime}
                    instruction={
                      "Select time window that you available for pickup each day"
                    }
                    register={register("weeklyPickupTime")}
                    setValue={(value) => {
                      setValue("weeklyPickupTime", value);
                    }}
                  ></WeeklyPickupTimeInput>
                </div>
              </div>
              {Object.keys(errors).length !== 0 ? (
                <div className={styles.errorMessages}>
                  {errors.cuisine && <p>{errors.cuisine.message}</p>}
                </div>
              ) : null}

              {isSaved && isSaved ? (
                <div className={styles.successMessages}>
                  <p>Your changes have been saved, successfully!</p>
                </div>
              ) : (
                <div></div>
              )}
              <SubmitButton text="Save changes"></SubmitButton>
            </div>
          </div>
        </div>
      </form>
      <div className={styleDashboard.section}>
        <p className={styleDashboard.sectionTitle}>Menu Items</p>
        <div className={styleDashboard.wrapperBox}>
          <Link to="/cooking/dish/create" className={styles.newDish}>
            <i className="las la-plus-square"></i> <span>New dish</span>
          </Link>
          {isLoading ? (
            <Loading></Loading>
          ) : (
            <>
              {dishes.length > 0 ? (
                <div className={styles.dishList}>
                  {dishes.map((dish) => (
                    <div key={dish.objectId} className={styles.dishItem}>
                      <div className={styles.image}>
                        <div
                          className={styles.imageWrapper}
                          style={
                            dish.Photo && {
                              backgroundImage: 'url("' + dish.Photo.url + '")',
                            }
                          }
                        ></div>
                      </div>
                      <div className={styles.title}>
                        <p className={styles.colTitle}>Food's name</p>
                        <div className={styles.content}>
                          <p>{dish.name}</p>
                        </div>
                      </div>
                      <div className={styles.price}>
                        <p className={styles.colTitle}>Price</p>
                        <div className={styles.content}>
                          <p>${dish.price.toFixed(2)}</p>
                        </div>
                      </div>
                      <div className={styles.availability}>
                        <p className={styles.colTitle}>Availability</p>
                        <div className={styles.content}>
                          <div className={styles.available_list}>
                            {dish.weeklyAvailability.map((day, index) => {
                              return (
                                <span
                                  key={index}
                                  className={`${
                                    day.isAvailable ? styles.selected : null
                                  }`}
                                >
                                  {day.day}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className={styles.status}>
                        <p className={styles.colTitle}>Status</p>
                        <div className={styles.content}>
                          <p
                            className={`${
                              dish.status == "Approval Waiting" &&
                              styles.waiting
                            }`}
                          >
                            {dish.status}
                          </p>
                        </div>
                      </div>
                      <div className={styles.actions}>
                        <p className={styles.colTitle}>-</p>
                        <div className={styles.content}>
                          <Link
                            to={`/cooking/dish/${dish.objectId}`}
                            className={styles.edit}
                          >
                            <i className="las la-edit"></i>
                            <span>Edit</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                isSuccess && (
                  <p className={styles.noDish}> You have no Dish yet!</p>
                )
              )}
            </>
          )}
          {/* <div className={styles.dishList}>
                        <div className={styles.dishItem}>
                            <div className={styles.image}>
                                <p className={styles.colTitle}>Image</p>
                                <div className={styles.imageWrapper}></div>
                            </div>
                            <div className={styles.title}>
                                <p className={styles.colTitle}>Food's name</p>
                                <div className={styles.content}>
                                    <p>Tasty dish's name here</p>
                                </div>
                            </div>
                            <div className={styles.price}>
                                <p className={styles.colTitle}>Price</p>
                                <div className={styles.content}>
                                    <p>$9.50</p>
                                </div>
                            </div>
                            <div className={styles.availability}>
                                <p className={styles.colTitle}>Availability</p>
                                <div className={styles.content}>
                                    <div className={styles.available_list}>
                                        <span className={styles.selected}>Mon</span>
                                        <span className={styles.selected}>Tue</span>
                                        <span className={styles.selected}>Wed</span>
                                        <span className={styles.selected}>Thu</span>
                                        <span className={styles.selected}>Fri</span>
                                        <span>Sat</span>
                                        <span>Sun</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.status}>
                                <p className={styles.colTitle}>Status</p>
                                <div className={styles.content}>
                                    <p>Draft</p>
                                </div>
                            </div>
                            <div className={styles.actions}>
                                <p className={styles.colTitle}>-</p>
                                <div className={styles.content}>
                                <a href="#" className={styles.edit}><i className="las la-edit"></i><span>Edit</span></a>
                                </div>
                            </div>
                        </div>

                        <div className={styles.dishItem}>
                            <div className={styles.image}>
                                <p className={styles.colTitle}>Image</p>
                                <div className={styles.imageWrapper}></div>
                            </div>
                            <div className={styles.title}>
                                <p className={styles.colTitle}>Food's name</p>
                                <div className={styles.content}>
                                    <p>Tasty dish's name here</p>
                                </div>
                            </div>
                            <div className={styles.price}>
                                <p className={styles.colTitle}>Price</p>
                                <div className={styles.content}>
                                    <p>$9.50</p>
                                </div>
                            </div>
                            <div className={styles.availability}>
                                <p className={styles.colTitle}>Availability</p>
                                <div className={styles.content}>
                                    <div className={styles.available_list}>
                                        <span>Mon</span>
                                        <span>Tue</span>
                                        <span>Wed</span>
                                        <span>Thu</span>
                                        <span>Fri</span>
                                        <span>Sat</span>
                                        <span>Sun</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.status}>
                                <p className={styles.colTitle}>Status</p>
                                <div className={styles.content}>
                                    <p>Draft</p>
                                </div>
                            </div>
                            <div className={styles.actions}>
                                <p className={styles.colTitle}>-</p>
                                <div className={styles.content}>
                                    <a href="#" className={styles.edit}><i className="las la-edit"></i><span>Edit</span></a>
                                </div>
                            </div>
                        </div>

                        <div className={styles.dishItem}>
                            <div className={styles.image}>
                                <p className={styles.colTitle}>Image</p>
                                <div className={styles.imageWrapper}></div>
                            </div>
                            <div className={styles.title}>
                                <p className={styles.colTitle}>Food's name</p>
                                <div className={styles.content}>
                                    <p>Tasty dish's name here</p>
                                </div>
                            </div>
                            <div className={styles.price}>
                                <p className={styles.colTitle}>Price</p>
                                <div className={styles.content}>
                                    <p>$9.50</p>
                                </div>
                            </div>
                            <div className={styles.availability}>
                                <p className={styles.colTitle}>Availability</p>
                                <div className={styles.content}>
                                    <div className={styles.available_list}>
                                        <span>Mon</span>
                                        <span>Tue</span>
                                        <span>Wed</span>
                                        <span>Thu</span>
                                        <span>Fri</span>
                                        <span>Sat</span>
                                        <span>Sun</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.status}>
                                <p className={styles.colTitle}>Status</p>
                                <div className={styles.content}>
                                    <p>Draft</p>
                                </div>
                            </div>
                            <div className={styles.actions}>
                                <p className={styles.colTitle}>-</p>
                                <div className={styles.content}>
                                <a href="#" className={styles.edit}><i className="las la-edit"></i><span>Edit</span></a>
                                </div>
                            </div>
                        </div>

                        <div className={styles.dishItem}>
                            <div className={styles.image}>
                                <p className={styles.colTitle}>Image</p>
                                <div className={styles.imageWrapper}></div>
                            </div>
                            <div className={styles.title}>
                                <p className={styles.colTitle}>Food's name</p>
                                <div className={styles.content}>
                                    <p>Tasty dish's name here</p>
                                </div>
                            </div>
                            <div className={styles.price}>
                                <p className={styles.colTitle}>Price</p>
                                <div className={styles.content}>
                                    <p>$9.50</p>
                                </div>
                            </div>
                            <div className={styles.availability}>
                                <p className={styles.colTitle}>Availability</p>
                                <div className={styles.content}>
                                    <div className={styles.available_list}>
                                        <span>Mon</span>
                                        <span>Tue</span>
                                        <span>Wed</span>
                                        <span>Thu</span>
                                        <span>Fri</span>
                                        <span>Sat</span>
                                        <span>Sun</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.status}>
                                <p className={styles.colTitle}>Status</p>
                                <div className={styles.content}>
                                    <p>Draft</p>
                                </div>
                            </div>
                            <div className={styles.actions}>
                                <p className={styles.colTitle}>-</p>
                                <div className={styles.content}>
                                <a href="#" className={styles.edit}><i className="las la-edit"></i><span>Edit</span></a>
                                </div>
                            </div>
                        </div>

                        <div className={styles.dishItem}>
                            <div className={styles.image}>
                                <p className={styles.colTitle}>Image</p>
                                <div className={styles.imageWrapper}></div>
                            </div>
                            <div className={styles.title}>
                                <p className={styles.colTitle}>Food's name</p>
                                <div className={styles.content}>
                                    <p>Tasty dish's name here</p>
                                </div>
                            </div>
                            <div className={styles.price}>
                                <p className={styles.colTitle}>Price</p>
                                <div className={styles.content}>
                                    <p>$9.50</p>
                                </div>
                            </div>
                            <div className={styles.availability}>
                                <p className={styles.colTitle}>Availability</p>
                                <div className={styles.content}>
                                    <div className={styles.available_list}>
                                        <span>Mon</span>
                                        <span>Tue</span>
                                        <span>Wed</span>
                                        <span>Thu</span>
                                        <span>Fri</span>
                                        <span>Sat</span>
                                        <span>Sun</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.status}>
                                <p className={styles.colTitle}>Status</p>
                                <div className={styles.content}>
                                    <p>Draft</p>
                                </div>
                            </div>
                            <div className={styles.actions}>
                                <p className={styles.colTitle}>-</p>
                                <div className={styles.content}>
                                <a href="#" className={styles.edit}><i className="las la-edit"></i><span>Edit</span></a>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default CookMyMenu;
