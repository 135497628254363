import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../../redux/config/axiosApiConfig';
import stripeApi from '../../../redux/config/axiosStripeApiConfig';

export const connectNewStripeAccount = createAsyncThunk(
    'cookPayment/connectNewStripeAccount',
    (data,thunkAPI) => {
        return api.post('/parse/functions/createNewStripeConnectedAccount',{
            userId: data
        }).then((res) => {
            console.log(res.data);
            return res.data;
        }).catch((err) => {
            console.log('connectStripe Error', err);
            return thunkAPI.rejectWithValue(err.response.data);
        })
    }
);

export const getStripeConnectedLink = createAsyncThunk(
    'cookPayment/getStripeConnectedLink',
    (data,thunkAPI) => {
        return api.post('/parse/functions/createStripeConnectedLoginLink',{
            connectedAccount: data
        }).then((res) => {
            return res.data.result;
        }).catch((err) => {
            console.log('getStripeConnectedLink Error', err);
            return thunkAPI.rejectWithValue(err.response.data);
        })
    }
);


export const resumeConnectNewStripeAccount = createAsyncThunk(
    'cookPayment/resumeConnectNewStripeAccount',
    (data,thunkAPI) => {
        return api.post('/parse/functions/resumeStripeConnectedAccount',{
            userId: data
        }).then((res) => {
            return res.data;
        }).catch((err) => {
            console.log('connectStripe Error', err);
            return thunkAPI.rejectWithValue(err.response.data);
        })
    }
);

export const getStripeConnectedAccount = createAsyncThunk(
    'cookPayment/getStripeConnectedAccount',
    (data,thunkAPI) => {
        return stripeApi.get(`/v1/accounts/${data}`).then((res) => {
            console.log(res);
            return res.data;
        }).catch((err) => {
            console.log('getStripeConnectedAccount Error', err);
            return thunkAPI.rejectWithValue(err.response.data);
        })
    }
);

const initialState = {
    isSuccess: false,
    isError: false,
    errorMessage: null,
    isLoading: false
}

const cookPaymentSlice = createSlice({
    name: "cookPayment",
    initialState,
    reducers:{
        cleanUpState: () => {
            return initialState;
        }
    },
    extraReducers:{
        [getStripeConnectedLink.fulfilled]: (state, action) => {
            state.stripeConnectedLink = action.payload;
        },

        [getStripeConnectedAccount.fulfilled]: (state, action) => {
            state.connectedAccount = action.payload;
            state.isLoading = false;
        },
        [getStripeConnectedAccount.pending]: (state) => {
            state.isLoading = true;
        },
        [connectNewStripeAccount.fulfilled]: (state, action) => {
            state.accountLinkUrl = action.payload.result.url;
        },
        [resumeConnectNewStripeAccount.fulfilled]: (state, action) => {
            state.accountLinkUrl = action.payload.result.url;
        }
    }
})

export const {cleanUpState} = cookPaymentSlice.actions;
export default cookPaymentSlice.reducer;