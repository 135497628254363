import HeaderStandard from "../../components/HeaderStandard";
import HeaderTeasing from "../../components/HeaderTeasing";
import Footer from "../../components/Footer";
import styles from "./Terms.module.scss";
import PageHeader from "../../layouts/pageHeader";
import PageFooter from "../../layouts/pageFooter";
const Terms = () => {
  return (
    <>
      <PageHeader></PageHeader>
      <div className="informativePage spacing--standardHeader">
        <div className="mainContainer mainContainer--small">
          <h1 className="informativePage__title">Terms and Conditions</h1>
          <div className="informativePage__mainContent">
            <h2>
              <strong>1. Overview</strong>
            </h2>

            <p>
              Mecook aims to provide a secure and efficient online platform for
              our Cooks to sell the meals that are offered (as
              varied,&nbsp;from&nbsp;time to time) (<strong>Meals</strong>) to
              our Customers&nbsp;(the&nbsp;<strong>Service</strong>) throughout
              the Greater Melbourne areas (or as revised by us from time to
              time).
            </p>

            <p>Our online platform consists of:</p>

            <ul>
              <li>
                our website located at&nbsp;
                <a href="http://www.mecook.app/">mecook.app</a>&nbsp;(the
                Website); and
              </li>
              <li>
                any mobile applications (the&nbsp;<strong>App</strong>) that we
                make downloadable on the Apple App Store and Google Play,
              </li>
            </ul>

            <p>
              (collectively, our&nbsp;<strong>Platform</strong>), which is owned
              and operated by Mecook Pty Ltd (ABN 74 653 533 656) (
              <strong>Mecook, us, our, we</strong>).
            </p>

            <p>
              Before accessing and using our Platform, please read these Terms
              and Conditions (<strong>Terms</strong>) carefully as they form a
              contract between you and us (<strong>Agreement</strong>). By
              accessing and using our Platform you acknowledge you have read,
              understood and agree to comply with:
            </p>

            <p>a) these Terms and Conditions;</p>

            <p>b) our Privacy Policy; and</p>

            <p>
              c) any other terms, policies or notices published on our Platform,
            </p>

            <p>(collectively, the&nbsp;Terms).</p>

            <p>
              If you have any questions about the Terms, you can contact us
              at&nbsp;
              <a href="mailto:hello@mecook.app">hello@mecook.app</a>.
            </p>

            <p>
              If you do not agree to the Terms, as amended from time to time, in
              whole or in part, you must not access our Platform, use our
              Services or purchase any Meals.
            </p>

            <p>
              We reserve the right to amend these Terms at any time, and by
              continuing to use our Service (by purchasing Meals), you accept
              the Terms as they apply from time to time.
            </p>

            <h2>
              <strong>2. Registration and Access </strong>
            </h2>

            <p>To purchase Meals offered on our Platform, you must:</p>

            <p>
              a) register for an account with us (<strong>Account</strong>) and
              accept these Terms;
              <br />
              b) provide us with current, complete and accurate identification,
              and other information (some of which is not mandatory) including,
              but not limited to, your name, a valid email address and password
              (<strong>Registration Information</strong>); and
              <br />
              c) promptly update your Account if your
              Registration&nbsp;Information changes.
            </p>

            <p>
              By accessing our Platform and purchasing the Meals offered on our
              Platform, you represent and warrant that:
            </p>

            <p>
              a) you are over 18 years of age, or you have the authority from
              your parent or guardian to use our Platform if you are under 18
              years of age, are ordering the Meals and have the right, authority
              and capacity to agree to these Terms;
            </p>

            <p>
              b) you understand that we cannot guarantee the availability,
              suitability or quality of the Meals offered on our Platform;
            </p>

            <p>
              c) your access to and use of our Platform is non-transferable;
            </p>

            <p>
              d) you understand that we may deny anyone access to an Account or
              the ability to use our Platform at any time where you have
              breached any provision of our Terms;
            </p>

            <p>
              e) you understand that while we may monitor, review, verify, edit,
              modify or delete material, content, data or information created,
              generated or transmitted by our Cooks through the Platform (
              <strong>Cook Content</strong>), we are not responsible for the
              Cook Content;
            </p>

            <p>
              f) you understand that our Cooks are responsible for Cook Content,
              and we do not control and are not responsible for the accuracy of
              Cook Content, including but not limited to any descriptions,
              health-related information or representation as to the presence or
              absence of allergens;
            </p>

            <p>
              g) if you are registering an Account with Mecook or using our
              Platform on behalf of a company, that company is taken to have
              accepted the Terms and you are taken to have been duly authorised
              to bind the company; and
            </p>

            <p>
              h) you will use our Platform in a manner consistent with any and
              all applicable laws, regulations and all Mecook policies.
            </p>

            <h2>
              <strong>3. Use of Platform</strong>
            </h2>

            <p>By accessing our Platform, you will:</p>

            <p>a) use our Platform for lawful purposes only;</p>

            <p>
              b) make purchases from our Platform for personal use and not for
              resale or trade;
            </p>

            <p>
              c) not create Accounts with us through unauthorised means,
              including by using an automated device, script, bot or other
              similar means. This prohibition includes any Accounts made for the
              purpose of interfering with the integrity of our Platform,
              including, but not limited to, by hacking, transmitting any
              viruses, spyware, malware or any other code of a destructive or
              disruptive nature; and
            </p>

            <p>
              d) not infringe the intellectual property rights of Mecook in our
              Platform or any intellectual property rights of any third party on
              our Platform.
            </p>

            <h2>
              <strong>
                4. Cooks, Meals and Purchases made on our Platform
              </strong>
            </h2>

            <p>
              Our Platform enables us to provide the Services (in other words,
              it enables Cooks to sell pre-prepared Meals) to you. By providing
              the Services, we act as an intermediary between you and our Cooks.
            </p>

            <p>
              We do not prepare any Meals offered for purchase on our Platform.
              We do not become a party to or participant in any contractual
              relationship between our Cooks and you. We are not acting as an
              agent in any capacity for any Cook.
            </p>

            <h2>
              <strong>5. Payment Terms</strong>
            </h2>

            <p>Our prices include GST.</p>

            <p>
              In these Terms, Payment Method means the method that you have or
              will use to make purchases on our&nbsp;Platform via
              your&nbsp;Account, such as a credit card, debit card, Stripe or
              PayPal account.
            </p>

            <p>We reserve the right to:</p>

            <p>
              a) change the Payment Methods that can be used on our Platform at
              any time and without notice to you;
            </p>

            <p>
              b) change the pricing of any Meals offered on our Platform at any
              time and without notice to you;
            </p>

            <p>
              c) delay or cancel any payment for purposes of preventing unlawful
              activity, fraud, risk assessment, security or investigation; and
            </p>

            <p>
              d) take steps to rectify any payment processing errors of which we
              become aware. These steps may include crediting or debiting (as
              appropriate) the same Payment Method used for the original payment
              by you, so that you ultimately receive or pay the correct amount.
            </p>

            <p>You will be responsible for:</p>

            <p>
              a) ensuring that your nominated Payment Method has sufficient
              funds to make any payment;
              <br />
              b) paying all bank fees, including fees charged by your bank
              (including those charged for any failed payments) as well as any
              reasonable administration fees; and
              <br />
              c) compensating us for any collection agency costs in the event
              that you fail to pay any amount owed to us within 7 days of
              request.
            </p>

            <h2>
              <strong>6. Vouchers, Discounts and Coupons</strong>
            </h2>

            <p>
              a) We may from time to time offer gift cards, discount promotions,
              coupons and other types of vouchers (<strong>Voucher</strong>)
              which must be activated through your Account. These Terms become
              an Agreement between us when you redeem the Voucher by creating an
              Account or purchasing a Product.
            </p>

            <p>
              b) If purchased, the Voucher is deemed to have been sold at the
              time of payment. Any Voucher may only be used until its value has
              been expended. Vouchers may not be copied, reproduced, distributed
              or published either directly or indirectly. Vouchers cannot be
              refunded once purchased. Only one Voucher is allowed to be applied
              per order.
            </p>

            <p>
              c) We reserve the right to withdraw or cancel any Voucher (other
              than a paid-up gift Voucher) for any reason at any time.
            </p>

            <p>
              d) Vouchers may only be redeemed through making purchases via the
              Platform and not through any other website or method of
              communication. To use a Voucher you will be required to enter its
              unique code at the online checkout and use of such code will be
              deemed confirmation of your agreement to these Terms and any
              special conditions that may be attached to the Voucher.
            </p>

            <p>
              e) Unless otherwise specifically stated on the Voucher, any
              discounts related to any Voucher applies to the price of any
              ordered Meals only and not to delivery charges, which will be
              charged at normal rates.
            </p>

            <p>
              f) We reserve the right to exclude the use of the Voucher on
              specific products offered for purchase via the Platform.
            </p>

            <h2>
              <strong>7. Cancellations and Refunds</strong>
            </h2>

            <p>
              We handle and process refunds of our fees in accordance with the
              Australian Consumer Law:
            </p>

            <p>
              a) refunds are made at our discretion and are subject to any
              guarantees that cannot be excluded under the Australian Consumer
              Law; and
            </p>

            <p>
              b) except as required by the Australian Consumer Law, we will only
              facilitate a refund if we are unable to facilitate the completion
              of the Service or, if we determine, in our discretion, it is
              reasonable to do so.
            </p>

            <p>
              Where a cancellation or refund is requested and approved by
              Mecook, we will provide credits to the value of the price of the
              Meals you would like refunded.
            </p>

            <p>
              If you would like to cancel your order, you must do so more than
              24 hours before the estimated delivery date, or we may not be able
              to offer you the full monetary value of credits for your cancelled
              order.
            </p>

            <h2>
              <strong>8. Intellectual Property Rights</strong>
            </h2>

            <p>
              In these Terms, Intellectual Property Rights means all
              intellectual property rights, including all copyright, patents,
              trademarks, design rights, trade secrets, circuit layouts, domain
              names, know-how and other rights of a similar nature worldwide,
              whether registered or not, and any applications for registration
              or rights to make such an application.
            </p>

            <p>
              We own or are the licensee of all rights, title and interest
              (including Intellectual Property Rights) in our Platform or to the
              material (including all text information and content, graphics,
              logos, type forms and software) made available to you on our
              Platform (collectively, Platform Content). Your use of and access
              to our Platform does not grant or transfer any rights, title or
              interest to you in relation to our Platform Content.
            </p>

            <p>
              You may access our Platform using a web browser or mobile device,
              and electronically copy and print hardcopy our Platform Content
              solely for your personal, non-commercial use.
            </p>

            <p>
              You must not modify, copy, distribute, transmit, display, perform,
              reproduce, publish, license, commercially exploit, reverse
              engineer, create derivative works from, transfer, or sell any
              Platform Content or any other material in whatever form contained
              within our Platform unless expressly stated otherwise in these
              Terms or as agreed in writing by us.
            </p>

            <h2>
              <strong>9. Platform Content</strong>
            </h2>

            <p>
              We may enable other customers to make comments and/or ratings in
              relation to the Services, Meals and Platform provided by us
              (Comments). If you choose to publish your Comments on the
              Platform, you agree that such opinions are your own and that of no
              other individual or persons.
            </p>

            <p>
              While we may monitor Comments, we have no responsibility for
              policing, monitoring or editing such content. We cannot be
              responsible for any Comments published on the Platform that may be
              offensive or harmful to others.
            </p>

            <p>
              Our Platform may contain links and other pointers to Internet
              websites or applications operated by third parties. We do not
              control these linked websites and are not responsible for the
              contents of any linked website. Your access to any such website is
              entirely at your own risk. You should contact the relevant third
              party directly to enquire as to the accuracy and completeness of
              that information prior to entering into a transaction in relation
              to the third party products and services.
            </p>

            <h2>
              <strong>10. Acknowledgements</strong>
            </h2>

            <p>
              To the fullest extent permitted by law, you agree and acknowledge
              that:
            </p>

            <p>
              a) the Meals that you purchase via our Platform are provided
              &ldquo;as is&rdquo; and &ldquo;as available&rdquo; and the entire
              risk arising out of your use of the Meals offered on our Platform
              remains solely with you;
            </p>

            <p>
              b) we do not control, endorse and are not responsible for any Cook
              Content;
            </p>

            <p>
              c) we provide nutrition information panels on our Platform for
              Meals as a guide only and we make no warranty or representations
              as to the accuracy, quality, nutrition, inclusion or absence of
              ingredients (including allergens or meat) of the Meals offered on
              our Platform;
            </p>

            <p>
              d) we make no warranty or representation that the Meals will be
              delivered to you in your designated time slot;
            </p>

            <p>
              e) in the event that the Meals are delivered when you are not
              home, we will not be responsible for the condition of the Meals if
              they are left outside, especially due to environmental factors
              which are out of our control. We will also not be responsible for
              Meals that are stolen or go missing;
            </p>

            <p>
              f) risk in the Meals will pass to you at the time of delivery;
            </p>

            <p>
              g) we retain complete control over our Platform and may alter,
              amend or cease the operation of our Platform in our sole
              discretion;
            </p>

            <p>
              h) we make no warranty or representation that our Platform will be
              continuous, uninterrupted, accurate, fault-free, virus-free,
              secure or accessible at all times;
            </p>

            <p>
              i) we are not liable for any loss if our Platform is not
              accessible due to third party actions, including but not limited
              to maintenance services, internet service providers, Third Party
              servers, nefarious hacks or distributed denial of service attacks;
              and
            </p>

            <p>
              j) we may remove any content, material and/or information,
              including any Cook Content, without giving any explanation or
              justification for removing the material and/or information.
            </p>

            <h2>
              <strong>11. Exclusion and Limitation of Liability</strong>
            </h2>

            <p>
              To the fullest extent permitted by law, we will not be liable to
              you or to anyone else for any direct or indirect loss
              or&nbsp;damage, including but not limited to consequential loss,
              loss of data, reputation, goodwill and opportunity, arising out of
              or in connection with:
            </p>

            <p>a) your use of our Platform or Services;</p>

            <p>b) your reliance on information provided by the Cooks;</p>

            <p>c) the Cook Content;</p>

            <p>
              d) any Cook breaching or failing to comply with any applicable
              laws; and
            </p>

            <p>
              e) any information provided by a Cook that could or does result in
              loss, injury or death,
            </p>

            <p>even if we have been advised of the possibility of such loss.</p>

            <p>
              In the event we are liable for any of the above types of loss
              under this Agreement, our total liability to you will not exceed
              the fees paid or payable by you for the Services in the preceding
              month, or up to a maximum of $1,000, whichever is less.
            </p>

            <p>
              To the fullest extent permitted by law, we exclude all
              representations, warranties or terms (whether express or implied)
              other than those set out in these Terms. However, we do not
              exclude any claims relating to:
            </p>

            <p>
              a) a breach of our obligations set out in the Competition and
              Consumer Act 2010 (Cth) or any other similar applicable
              legislation; and
            </p>

            <p>
              b) any other matter for which it would be illegal for us to
              exclude our liability.
            </p>

            <p>
              Our liability for breach of a guarantee conferred by the
              Australian Consumer Law (other than those conferred by s51 to s53
              of the Australian Consumer Law) is limited at our option to:
            </p>

            <p>a) the re-supply of the services or products; or</p>

            <p>
              b) the payment of the direct cost of having the services or
              products resupplied.
            </p>

            <h2>
              <strong>12. Privacy</strong>
            </h2>

            <p>
              We will collect, use and disclose any personal information you
              provide us when accessing or using our Platform in accordance with
              our&nbsp;
              <a href="https://www.mecook.app/privacy" target="_blank">
                Privacy Policy.
              </a>
              &nbsp;For more information on our information collection and
              handling practices, please view our&nbsp;
              <a href="https://www.mecook.app/privacy" target="_blank">
                Privacy Policy
              </a>
              .
            </p>

            <h2>
              <strong>13. Indemnity</strong>
            </h2>

            <ol></ol>

            <p>
              You agree to indemnify us for all losses, damages, liabilities,
              claims and expenses (including reasonable legal costs) incurred by
              us arising out of or in connection with your use of our Platform,
              purchase and consumption of the Meals, the Cook Content, your
              breach of the Terms or any rights of third parties, except to the
              extent we directly caused or contributed to the loss, damage,
              liability, claim or expense.
            </p>

            <h2>
              <strong>14. Termination</strong>
            </h2>

            <ol></ol>

            <p>We have the right to terminate this Agreement at any time.</p>

            <p>We reserve the right to:</p>

            <p>
              a) cease operating our Platform, without notice and for any
              reason; and/or
            </p>

            <p>
              b) terminate our relationship with you without notice and with
              immediate effect if you, in any way, breach the Terms.
            </p>

            <p>
              If one of the events described in this clause 14 occurs, then:
            </p>

            <p>
              a) you will not have any access to our Platform, your Account or
              to make a purchase of any Meals; and
            </p>

            <p>
              b) we will not be liable for any costs, losses or damages arising
              as a result of terminating your access to our Platform.
            </p>

            <h2>
              <strong>15. Complaint Management</strong>
            </h2>

            <ol></ol>

            <p>
              If you are dissatisfied about any aspect of our Service, the
              Platform, the Meals or your purchases on the Platform, please
              write to us at&nbsp;
              <a href="mailto:hello@mecook.app">hello@mecook.app</a>
              &nbsp;setting out the details of your complaint (Complaint). All
              Complaints must be made to us within 7 days of receiving the order
              to which it relates.
            </p>

            <p>
              Upon receiving notification of your Complaint, we will investigate
              the circumstances and attempt to facilitate a resolution to your
              satisfaction.
            </p>

            <p>
              All communications concerning negotiations made by the Disputants
              in connection with this Complaint resolution clause are
              confidential and to the extent possible, must be treated as
              &ldquo;without prejudice&rdquo; negotiations for the purpose of
              the applicable law of evidence.
            </p>

            <h2>
              <strong>16. General Provisions</strong>
            </h2>

            <p>a) Waiver</p>

            <ol></ol>

            <p>
              No waiver of rights under this Agreement shall constitute a
              subsequent waiver of this or any other right under this agreement.
              Any failure on our part to enforce any right or provision of these
              Terms will not constitute a waiver of future enforcement of that
              right or provision.
            </p>

            <p>b) Severability</p>

            <p>
              If any provision of our Terms is unenforceable or invalid, it will
              be ineffective to the extent it is unenforceable or invalid and
              will not affect the enforceability or validity of the remaining
              provisions.
            </p>

            <p>c) Force Majeure</p>

            <p>
              We cannot be responsible for any failure to perform, delay in
              performance of our obligations caused by events outside our
              reasonable control.
            </p>

            <p>d) Law and Jurisdiction</p>

            <p>
              These Terms are governed by and construed in accordance with the
              laws of New South Wales, Australia. You submit to the
              non-exclusive jurisdiction of the Courts of New South Wales for
              determining any Complaint concerning these Terms.
            </p>

            <p>e) Feedback and Contact</p>

            <p>
              Your feedback is important to us and our community. We welcome and
              encourage you to provide feedback, reviews, comments and
              suggestions for improvements to our Platform, our Service and the
              Meals offered on our Platform (Feedback). You may submit Feedback
              by contacting us at&nbsp;
              <a href="mailto:hello@mecook.app">hello@mecook.app</a>.
            </p>
          </div>
        </div>
      </div>
      <PageFooter></PageFooter>
    </>
  );
};

export default Terms;
