import styles from "./LocationSelectModal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { closeLocationModal } from "../../redux/uiSlice";
import { useRef, useEffect, useState } from "react";
import { updateDeliveryLocation } from "../../redux/generalSlice";
const LocationSelectModal = () => {
  const dispatch = useDispatch();

  const { deliveryLocation } = useSelector((state) => state.general);
  const [address, setAddress] = useState(deliveryLocation);

  const options = {
    componentRestrictions: { country: ["au"] },
    types: ["address"],
  };

  const handleAutocompleteSelect = async (address, placeId) => {
    if (placeId) {
      dispatch(closeLocationModal());
      setAddress(address);
      const geocoderResults = await geocodeByAddress(address);
      const Coordinate = await getLatLng(geocoderResults[0]);
      const [place] = await geocodeByPlaceId(placeId);
      const data = {
        deliveryLocation: address,
        deliveryCoordinates: Coordinate,
        deliverySuburb: place.address_components.find((c) =>
          c.types.includes("locality")
        ).short_name,
        placeId: placeId,
      };
      dispatch(updateDeliveryLocation(data));
      // onLocationSelect && onLocationSelect({Coordinate,address});
    }
  };

  const inputRef = useRef();

  const handleClose = () => {
    dispatch(closeLocationModal());
  };

  useEffect(() => {
    inputRef.current.focus();
    return () => {
      // cleanup
    };
  }, []);

  return (
    <>
      <div className={styles.modalOverlay}>
        <div className={styles.locationSelection}>
          <span className={styles.closeModal} onClick={handleClose}>
            <i className="las la-times"></i>
          </span>
          <p className={styles.title}>Enter your current address</p>
          <p className={styles.instr}>
            This will help to find local cook around your area
          </p>
          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleAutocompleteSelect}
            searchOptions={options}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => {
              return (
                <>
                  <div className={styles.inputWrapper}>
                    <i className="las la-map-marker-alt"></i>
                    <input
                      ref={inputRef}
                      type="text"
                      className={styles.locationInput}
                      {...getInputProps()}
                      value={address}
                    />
                  </div>
                  <div className={styles.suggest_box}>
                    {loading ? <div> Searching... </div> : null}
                    {suggestions.map((suggestion, index) => {
                      return (
                        <div
                          key={index}
                          {...getSuggestionItemProps(suggestion)}
                          className={suggestion.active ? styles.active : null}
                        >
                          <i className="las la-map-pin"></i>{" "}
                          <p>{suggestion.description}</p>
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            }}
          </PlacesAutocomplete>
        </div>
      </div>
    </>
  );
};

export default LocationSelectModal;
