import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../redux/config/axiosApiConfig";
import { WeeklyPickupTimeInput } from "../components/InputField";

export const uploadMenuMainPhoto = createAsyncThunk(
  "cookProfile/uploadMenuMainPhoto",
  (file, thunkAPI) => {
    return api
      .post("/parse/files/" + file.name, file, {
        headers: {
          "Content-Type": "image/jpeg",
        },
      })
      .then((res) => {
        let currentUserId = thunkAPI.getState().auth.currentUser.objectId;
        console.log("check return file after upload", res);
        let pictureObj = {
          menuCoverPhoto: {
            name: res.data.name,
            url: res.data.url,
            __type: "File",
          },
        };
        return api
          .put("/parse/users/" + currentUserId, pictureObj)
          .then((res) => {
            console.log("return uploadMenuMainPhoto: ", res.data);
            return pictureObj;
          })
          .catch((err) => {
            console.log("Error uploadMenuMainPhoto: ", err);
            return thunkAPI.rejectWithValue(err.response.data);
          });
      })
      .catch((err) => {
        console.log(err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const saveProfileUpdate = createAsyncThunk(
  "cookProfile/saveProfileUpdate",
  (data, thunkAPI) => {
    const updateData = {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      cookIntro: data.intro,
      address: data.address,
      postCode: data.postCode,
      stage: data.stage,
      suburb: data.suburb,
      password: data.password,
      location: {
        __type: "GeoPoint",
        latitude: data.location.lat,
        longitude: data.location.lng,
      },
    };
    const currentUserId = thunkAPI.getState().auth.currentUser.objectId;
    return api
      .put("/parse/users/" + currentUserId, updateData)
      .then(() => {
        // Request add contact to sendgrid
        const addingResult = api
          .post("/parse/functions/addNewCookContactToSendgrid", {
            email: thunkAPI.getState().auth.currentUser.email,
            phone: data.phone,
            firstName: data.firstName,
            lastName: data.lastName,
            address: data.address,
            stage: data.stage,
            postCode: data.postCode,
            suburb: data.suburb,
          })
          .then((res) => {
            return res.data.result;
          })
          .catch((err) => {
            console.log("error adding contact to sendgrid");
            return err.response.data;
          });
        return updateData;
      })
      .catch((err) => {
        console.log("Error save user update: ", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const saveMenuGeneral = createAsyncThunk(
  "cookProfile/saveMenuGeneral",
  (data, thunkAPI) => {
    console.log("database TONYYYY", data);
    const updateData = {
      cuisines: data.cuisine,
      weeklyPickupTime: data.weeklyPickupTime,
    };
    const currentUserId = thunkAPI.getState().auth.currentUser.objectId;
    return api
      .put("/parse/users/" + currentUserId, updateData)
      .then(() => {
        return updateData;
      })
      .catch((err) => {
        console.log("Error save user update: ", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

const initialState = {
  isSuccess: false,
  isError: false,
  errorMessage: null,
};

const cookProfileSlice = createSlice({
  name: "cookProfile",
  initialState,
  reducers: {
    cleanUpState: () => {
      return initialState;
    },
  },
  extraReducers: {
    [uploadMenuMainPhoto.pending]: (state) => {
      state.uploadingMenuMainPhoto = true;
    },
    [uploadMenuMainPhoto.fulfilled]: (state) => {
      state.uploadingMenuMainPhoto = false;
    },
    [uploadMenuMainPhoto.rejected]: (state) => {
      state.uploadingMenuMainPhoto = false;
    },

    [saveProfileUpdate.pending]: (state) => {
      state.submittingForm = true;
    },
    [saveProfileUpdate.fulfilled]: (state) => {
      state.isSuccess = true;
      state.submittingForm = false;
      state.isSaved = true;
    },
    [saveProfileUpdate.rejected]: (state) => {
      state.submittingForm = false;
      state.isSaved = false;
    },

    [saveMenuGeneral.pending]: (state) => {
      state.submittingForm = true;
    },
    [saveMenuGeneral.fulfilled]: (state) => {
      state.isSuccess = true;
      state.submittingForm = false;
      state.isSaved = true;
    },
    [saveMenuGeneral.rejected]: (state) => {
      state.submittingForm = false;
      state.isSaved = false;
    },
  },
});

export const { cleanUpState } = cookProfileSlice.actions;
export default cookProfileSlice.reducer;
