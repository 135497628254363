import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../redux/config/axiosApiConfig";

export const getMenuBy = createAsyncThunk(
  "cookMenu/getMenuBy",
  (cookId, thunkAPI) => {
    return api
      .get(`/parse/users/${cookId}`, {
        params: {
          include: "menuDishes",
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log("Error getMenuBy return data: ", err.response.data);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const loadUserFirstOrderPromotion = createAsyncThunk(
  "cookMenu/loadUserFirstOrderPromotion",
  (userID, thunkAPI) => {
    return api
      .get("/parse/classes/Order", {
        params: {
          where: {
            orderBy: {
              __type: "Pointer",
              className: "_User",
              objectId: userID,
            },
          },
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log("Error loadUserFirstOrderPromotion:", err.response.data);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const calculateDistance = createAsyncThunk(
  "cookMenu/calculateDistance",
  (data, thunkAPI) => {
    var userCoordinate = thunkAPI.getState().general.deliveryCoordinates;
    var cookCoordinate = thunkAPI.getState().cookMenu.cook.location;
    var parameterData = {
      from: { lat: userCoordinate.lat, lng: userCoordinate.lng },
      to: { lat: cookCoordinate.latitude, lng: cookCoordinate.longitude },
    };
    return api
      .post("/parse/functions/calculateDistance", parameterData)
      .then((res) => {
        return res.data.result;
      })
      .catch((err) => {
        console.log("calculateDistance error:", err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const sendOrder = createAsyncThunk(
  "cookMenu/sendOrder",
  (data, thunkAPI) => {
    let cart = data.order;
    let orderDate = new Date(data.date);
    return api
      .post("/parse/classes/Order", {
        cookBy: {
          __type: "Pointer",
          className: "_User",
          objectId: data.cookId,
        },
        orderBy: {
          __type: "Pointer",
          className: "_User",
          objectId: data.userId,
        },
        orderStatus: "waiting confirm",
        deliveryType: data.deliveryType,
        orderTotal: cart.total,
        deliveryFee: Number(data.deliveryFee),
        orderItems: cart.items,
        transferGroup: data.transferGroup,
        paymentID: data.paymentID,
        deliveryAddress: data.deliveryAddress,
        pickupTime: data.pickupTime,
        promotionValue: data.promotionValue,
        promotionCode: data.promotionCode,
        total:
          cart.total +
          Number(data.deliveryFee) -
          Number(data.discount) -
          Number(data.promoDiscount),
        orderDate: {
          __type: "Date",
          iso: orderDate.toISOString(),
        },
      })
      .then((res) => {
        console.log("sendOrder return data: ", res.data);
        return res.data;
      })
      .catch((err) => {
        // console.log("Error sendOrder return data: ", err.response);
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

const initialState = {
  saveCartLoaded: false,
  isSuccess: false,
  isError: false,
  errorMessage: null,
  distance: "",
  currentCart: {
    total: 0,
    itemCount: 0,
    items: [],
  },
  connectedAccountId: null,
  fees: {
    delivery: 5,
  },
  discount: 0,
};

const cookMenuSlice = createSlice({
  name: "cookMenu",
  initialState,
  reducers: {
    cleanUpState: () => {
      return initialState;
    },
    loadSaveCart: (state) => {
      const saveCart = JSON.parse(localStorage.getItem("saveCart"));
      if (saveCart != null) {
        state.currentCart = saveCart.cartContent;
        state.saveCookId = saveCart.byCookId;
      }
      state.saveCartLoaded = true;
    },
    clearCurrentCart: (state) => {
      state.currentCart.items = [];
      state.currentCart.total = 0;
      state.currentCart.itemCount = 0;
    },
    addToCart: (state, action) => {
      // Find index
      let objIndex = state.currentCart.items.findIndex(
        (obj) => obj.id == action.payload.id
      );
      if (objIndex > -1) {
        // increase qty for existing item
        state.currentCart.items[objIndex].qty = action.payload.qty;
        state.currentCart.items[objIndex].subTotal = action.payload.subTotal;
      } else {
        // added new item
        state.currentCart.items = [...state.currentCart.items, action.payload];
      }
      // update Cart Total
      let updatedTotal = 0;
      let updateItemCount = 0;
      state.currentCart.items.map((item) => {
        updatedTotal += item.subTotal;
        updateItemCount += item.qty;
      });
      state.currentCart.total = updatedTotal;
      state.currentCart.itemCount = updateItemCount;
      state.currentCart.deliveryDate = action.payload.deliveryDate;
    },
    loadCookAvailableDateRange: (state, action) => {
      const dishes = state.cook.menuDishes;
      const todayDate = action.payload.todayDate;
      const numberOfDateRange = action.payload.days;
      const dayOffset = action.payload.offset;
      let startOfDateRange = new Date(todayDate);
      startOfDateRange.setDate(startOfDateRange.getDate() + dayOffset);
      var tempCookAvailableRange = new Array();
      var cookAvailableDay = {
        Sun: false,
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
      };
      var weekdays = new Array(7);
      weekdays[0] = "Sun";
      weekdays[1] = "Mon";
      weekdays[2] = "Tue";
      weekdays[3] = "Wed";
      weekdays[4] = "Thu";
      weekdays[5] = "Fri";
      weekdays[6] = "Sat";

      if (dishes && dishes.length > 0) {
        // This is to check at least 1 dish available in a day to create cook available day range
        weekdays.map((weekday) => {
          for (let i = 0; i < dishes.length; i++) {
            const dish = dishes[i];
            if (
              dish.weeklyAvailability.filter(
                (e) =>
                  e.day === weekday &&
                  e.isAvailable &&
                  // check if pickup time has been set for this dish available day.
                  state.cook.weeklyPickupTime.filter(
                    (i) => i.day === e.day && i.time !== ""
                  ).length > 0
              ).length > 0
            ) {
              cookAvailableDay[weekday] = true;
              break;
            }
          }
        });
        for (let i = 0; i < numberOfDateRange; i++) {
          let nextDay = new Date(startOfDateRange);
          nextDay.setDate(startOfDateRange.getDate() + i);
          if (cookAvailableDay[weekdays[nextDay.getDay()]]) {
            tempCookAvailableRange = [
              ...tempCookAvailableRange,
              new Date(nextDay).toDateString(),
            ];
          }
        }
        state.cookAvailableDateRange = tempCookAvailableRange;
      }
    },
  },
  extraReducers: {
    [loadUserFirstOrderPromotion.fulfilled]: (state, action) => {
      // Comment out bellow to disable automatic discount $10 for first order.
      // if (action.payload.results.length === 0) {
      //   state.discount = 10; // Discount First Order
      // }
    },
    [getMenuBy.fulfilled]: (state, action) => {
      if (action.payload.deliveryType === "selfDelivery") {
        state.fees.delivery = parseInt(action.payload.deliveryFee);
      }
      if (action.payload.deliveryType === "Pick-up") {
        state.fees.delivery = 0;
      }
      // filter out available dish only before asign to cook stage
      action.payload.menuDishes = action.payload.menuDishes.filter(
        (e) => e.status === "Approved" || e.status === "Live - Approved"
      );
      state.cook = action.payload;
      state.saveCookId = state.cook.objectId;
      state.connectedAccountId = action.payload.stripeConnectedAccount;
      // let dishes = action.payload.menuDishes;
      // let today = new Date();
      // today.setDate(today.getDate() + 1);
      // let maxDayNumberInAdvance = 6;
      // var cookAvailableRange = new Array();
      // var cookAvailableDay = {
      //   Sun: false,
      //   Mon: false,
      //   Tue: false,
      //   Wed: false,
      //   Thu: false,
      //   Fri: false,
      //   Sat: false,
      // };
      // var weekdays = new Array(7);
      // weekdays[0] = "Sun";
      // weekdays[1] = "Mon";
      // weekdays[2] = "Tue";
      // weekdays[3] = "Wed";
      // weekdays[4] = "Thu";
      // weekdays[5] = "Fri";
      // weekdays[6] = "Sat";

      // if (dishes && dishes.length > 0) {
      //   // This is to check at least 1 dish available in a day to create cook available day range
      //   weekdays.map((weekday) => {
      //     for (let i = 0; i < dishes.length; i++) {
      //       const dish = dishes[i];
      //       if (
      //         dish.weeklyAvailability.filter(
      //           (e) =>
      //             e.day === weekday &&
      //             e.isAvailable &&
      //             // check if pickup time has been set for this dish available day.
      //             state.cook.weeklyPickupTime.filter(
      //               (i) => i.day === e.day && i.time !== ""
      //             ).length > 0
      //         ).length > 0
      //       ) {
      //         cookAvailableDay[weekday] = true;
      //         break;
      //       }
      //     }
      //   });
      //   for (let i = 1; i <= maxDayNumberInAdvance; i++) {
      //     let nextDay = new Date(today);
      //     nextDay.setDate(today.getDate() + i);
      //     if (cookAvailableDay[weekdays[nextDay.getDay()]]) {
      //       cookAvailableRange = [
      //         ...cookAvailableRange,
      //         new Date(nextDay).toDateString(),
      //       ];
      //     }
      //   }
      //   state.cookAvailableDateRange = cookAvailableRange;
      // }
    },
    [sendOrder.rejected]: (state, action) => {
      console.log("Error send Order", action.payload);
    },
    [calculateDistance.fulfilled]: (state, action) => {
      state.distance = action.payload.toFixed(1);
      console.log("return calculated distance", action.payload);
    },
  },
});

export const {
  cleanUpState,
  addToCart,
  clearCurrentCart,
  loadSaveCart,
  loadCookAvailableDateRange,
} = cookMenuSlice.actions;

export default cookMenuSlice.reducer;
