import styles from "./OrderCartLink.module.scss";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Link } from "react-router-dom";
const OrderCartLink = () => {
  const { currentCart } = useSelector((state) => state.cookMenu);
  return (
    <>
      {currentCart.items.length > 0 ? (
        <>
          <div className={styles.order_cart}>
            <span className={styles.cartItems}>{currentCart.itemCount}</span>
            <i className="las la-shopping-bag"></i>
          </div>
        </>
      ) : (
        <>
          <div className={styles.order_cart}>
            <i className="las la-shopping-bag"></i>
          </div>
        </>
      )}
    </>
  );
};

export default OrderCartLink;
