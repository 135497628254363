import styles from "./HowItWork.module.scss";
import HeaderStandard from "../../components/HeaderStandard";
import { useEffect, useRef, useState } from "react";
import choseDishImg from "../../assets/images/drawingChooseDish.svg";
import EnjoyFoodImg from "../../assets/images/drawingEnjoyFood.svg";
import PickADate from "../../assets/images/calendar.svg";

import PickDate from "../../assets/images/Mecook_UI/PickDate.svg";
import CustomerOrder from "../../assets/images/Mecook_UI/Order.svg";
import Cooking from "../../assets/images/Mecook_UI/Cook.svg";
import Packing from "../../assets/images/Mecook_UI/Package.svg";
import GetPaid from "../../assets/images/Mecook_UI/Paid.svg";

import { Link } from "react-router-dom";
const HowItWork = () => {
  return (
    <>
      <HeaderStandard />
      <div className={`${styles.mainWrapper} ${styles.offset_heading}`}>
        <div className={styles.howItWork__inner}>
          <h1 className="title-h2">How does cooking on Mecook work?</h1>
          <div className={styles.infoGroup}>
            <img src={PickDate} alt="" />
            <div className={styles.mainContent}>
              <p className="title-h4">
                Choose dates you want to cook & deliver
              </p>
              <p className={styles.content}>
                You choose the dates that you are available to cook and deliver.
              </p>
            </div>
          </div>
          <div className={styles.infoGroup}>
            <img src={CustomerOrder} alt="" />
            <div className={styles.mainContent}>
              <p className="title-h4">Customers place orders</p>
              <p className={styles.content}>
                Orders will be placed two days before your delivery day. So it
                gives you enough time to cook and pack
              </p>
            </div>
          </div>
          <div className={styles.infoGroup}>
            <img src={Cooking} alt="" />
            <div className={styles.mainContent}>
              <p className="title-h4">You cook and cool</p>
              <p className={styles.content}>
                You cook and cool down your food. We DO NOT support frozen food.
              </p>
            </div>
          </div>
          <div className={styles.infoGroup}>
            <img src={Packing} alt="" />
            <div className={styles.mainContent}>
              <p className="title-h4">Package & Delivery</p>
              <p className={styles.content}>
                Package food and keep refrigerated until delivery.
              </p>
            </div>
          </div>
          <div className={styles.infoGroup}>
            <img src={GetPaid} alt="" />
            <div className={styles.mainContent}>
              <p className="title-h4">Get paid</p>
              <p className={styles.content}>
                You will receive a direct deposit soon after each day that you
                cook and deliver!
              </p>
            </div>
          </div>
          <Link
            to="/cookOnboarding"
            className={`${styles.button} ${styles.red}`}
          >
            Become a Cook
          </Link>
        </div>
        <div className={styles.footer}>
          © 2023 Mecook <a href="/terms">Terms of Service</a>{" "}
          <a href="/privacy">Privacy Policy</a>
        </div>
      </div>
    </>
  );
};

export default HowItWork;
