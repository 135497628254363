import styles from "./SubmitButton.module.scss";

const SubmitButton = ({ text, isCenter = false }) => {
  // todo: use isCenter prop for options in className for center style or not with submit button (default true is center)
  return (
    <>
      <button
        type="submit"
        className={`${styles.submitButton} ${
          isCenter && styles.submitButton__center
        }`}
      >
        {text}
      </button>
    </>
  );
};

export default SubmitButton;
