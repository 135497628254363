import styles from "./OrderCard.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  acceptOrder,
  declineOrder,
  setOrderReady,
  setOrderDelivered,
} from "../../CookOrder/CookOrderSlice";

const OrderCard = ({ order }) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const orderDateObj = new Date(order.orderDate.iso);
  const orderDateString =
    orderDateObj.getDate() +
    "/" +
    (orderDateObj.getMonth() + 1) +
    "/" +
    orderDateObj.getFullYear();
  const handleAcceptOrder = () => {
    dispatch(
      acceptOrder({
        orderId: order.objectId,
        email: order.orderBy.email,
        customerName: order.orderBy.firstName,
        orderItems: order.orderItems,
        pickupTime: order.pickupTime,
        orderDate: orderDateString,
        subTotal: order.orderTotal,
        paymentID: order.paymentID,
      })
    );
  };
  const handleDeclineOrder = () => {
    dispatch(
      declineOrder({
        orderId: order.objectId,
        email: order.orderBy.email,
        customerName: order.orderBy.firstName,
        orderItems: order.orderItems,
        pickupTime: order.pickupTime,
        orderDate: orderDateString,
        subTotal: order.orderTotal,
      })
    );
  };

  const handleOrderReady = () => {
    dispatch(
      setOrderReady({
        orderId: order.objectId,
        email: order.orderBy.email,
        customerName: order.orderBy.firstName,
        orderItems: order.orderItems,
        pickupTime: order.pickupTime,
        orderDate: orderDateString,
        subTotal: order.orderTotal,
      })
    );
  };

  const handleOrderDelivered = () => {
    if (order.deliveryType !== "selfDelivery") {
      dispatch(
        setOrderDelivered({
          orderId: order.objectId,
          groupTransfer: order.transferGroup,
          connectedAccount: currentUser.stripeConnectedAccount,
          amount: order.orderTotal,
        })
      );
    } else {
      // Cooks use selfDelivery so amount would include delivery Fee
      dispatch(
        setOrderDelivered({
          orderId: order.objectId,
          groupTransfer: order.transferGroup,
          connectedAccount: currentUser.stripeConnectedAccount,
          amount: order.orderTotal + order.deliveryFee,
        })
      );
    }
  };

  return (
    <div className={styles.order}>
      <div className={styles.orderHeader}>
        <div className={styles.title}>
          <span>Customer</span>
          <p>
            {order.orderBy.firstName} {order.orderBy.lastName}
          </p>
        </div>
        <div className={styles.orderDate}>
          <span>Delivery</span>
          <span className={styles.date}>
            {orderDateObj.getDate() +
              "/" +
              (orderDateObj.getMonth() + 1) +
              "/" +
              orderDateObj.getFullYear()}
          </span>
          {order.deliveryType === "selfDelivery" && (
            <span className={styles.address}>{order.deliveryAddress}</span>
          )}
          <span className={styles.time}>{order.pickupTime}</span>
        </div>
      </div>
      <div className={styles.orderList}>
        {order.orderItems.map((item) => {
          return (
            <div key={item.id} className={styles.orderItem}>
              <div className={styles.dishName}>
                <p className={styles.orderTitle}>Food's name</p>
                {item.name}
              </div>
              <div className={styles.qty}>
                <p className={styles.orderTitle}>Qty</p>
                {item.qty}
              </div>
              <div className={styles.subtotal}>
                <p className={styles.orderTitle}>Subtotal</p>$
                {item.subTotal.toFixed(2)}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.orderTotal}>
        Total: ${order.orderTotal.toFixed(2)}
        {order.orderStatus == "waiting confirm" && (
          <div className={styles.actions}>
            <span className={styles.decline} onClick={handleDeclineOrder}>
              <i className="las la-frown"></i>Decline
            </span>
            <span className={styles.accept} onClick={handleAcceptOrder}>
              <i className="las la-grin-beam"></i>Confirm
            </span>
          </div>
        )}
        {order.orderStatus == "confirmed" && (
          <div className={styles.actions}>
            <span className={styles.ready} onClick={handleOrderReady}>
              <i className="las la-check"></i>Ready
            </span>
          </div>
        )}
        {order.orderStatus == "ready" && (
          <div className={styles.actions}>
            <span className={styles.pickup} onClick={handleOrderDelivered}>
              <i className="las la-grin-beam"></i>Already pickup
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderCard;
