import styles from "./UserQuickMenu.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { userLogout } from "../../../../redux/authSlice";

const UserQuickMenu = () => {
  const { currentUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = async () => {
    await dispatch(userLogout());
    history.push("/login");
  };

  return (
    <div className={styles.menu_drop}>
      <Link to="/me/profile">
        <div className={styles.menu_item}>
          <i className="las la-user"></i> <p>Profile</p>
        </div>
      </Link>
      <Link to="/me/order">
        <div className={styles.menu_item}>
          <i className="las la-utensils"></i> <p>Orders</p>
        </div>
      </Link>
      {/* <Link to="/me/profile">
        <div className={styles.menu_item}>
          <i className="las la-bell"></i> <p>Notification</p>
        </div>
      </Link> */}
      {currentUser.isCook && currentUser.applicationStatus == "Approved" && (
        <a href="/cooking/order" target="_blank">
          <div className={`${styles.menu_item} ${styles.cookDash}`}>
            <i className="las la-mitten"></i> <p>Cook Dashboard</p>
          </div>
        </a>
      )}

      {((currentUser.isCook &&
        currentUser.applicationStatus == "Waiting on Submission") ||
        (currentUser.isCook &&
          currentUser.applicationStatus == "Pending Review")) && (
        <a href="/cooking/profile" target="_blank">
          <div className={`${styles.menu_item} ${styles.cookDash}`}>
            <i className="las la-id-badge"></i> <p>Your Application</p>
          </div>
        </a>
      )}
      <Link to="/faq">
        <div className={styles.menu_item}>
          <i className="las la-life-ring"></i> <p>Help & Support</p>
        </div>
      </Link>
      <div className={styles.menu_item} onClick={handleLogout}>
        <i className="las la-sign-out-alt"></i> <p>Sign Out</p>
      </div>
    </div>
  );
};

export default UserQuickMenu;
