import styles from "./CookMenu.module.scss";
import NoImage from "../../assets/images/default-food-vector.svg";
import SubmitButton from "../../components/SubmitButton";
import FoodModal from "./components/FoodModal";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  getMenuBy,
  cleanUpState,
  sendOrder,
  clearCurrentCart,
  calculateDistance,
  loadCookAvailableDateRange,
} from "./cookMenuSlice";
import Loading from "../../components/Loading";
import CartItem from "./components/CartItem";
import MenuDateFilter from "./components/MenuDateFilter";
import DateFilter from "../../components/DateFilter";
import { openLocationModal } from "../../redux/uiSlice";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Switch, Route, useRouteMatch } from "react-router-dom";

import DishCard from "./components/DishCard";

import Checkout from "./components/Checkout";
import { goCheckout } from "./components/Checkout/CheckoutSlice";

import PickUpTimeSelection from "./components/PickUpTimeSelection";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CookMenu = ({ location }) => {
  let { cookId } = useParams();
  const dispatch = useDispatch();
  let { path, url } = useRouteMatch();
  const {
    cook,
    currentCart,
    distance,
    fees,
    discount,
    saveCookId,
    cookAvailableDateRange,
  } = useSelector((state) => state.cookMenu);
  const { currentUser } = useSelector((state) => state.auth);
  const { todayDate, deliveryLocation, deliverySuburb, configDateRange } =
    useSelector((state) => state.general);
  const { isLoading } = useSelector((state) => state.ui);
  const { menuDateFilter } = useSelector((state) => state.menuDateFilter);
  const { dateFilter } = useSelector((state) => state.dateFilter);
  const { isCheckout } = useSelector((state) => state.checkout);

  const [dateSelected, setDateSelected] = useState("");
  const [pickupTimeSelected, setPickupTimeSelected] = useState("");

  const [isCartOpen, setIsCartOpen] = useState(false);

  const handleOpenLocationModal = () => {
    dispatch(openLocationModal());
    setIsCartOpen(false);
  };

  const handleGoCheckout = () => {
    if (cook.applicationStatus === "Approved") {
      if (cook.deliveryType === "Pick-up") {
        if (pickupTimeSelected !== "") {
          setIsCartOpen(false);
          dispatch(goCheckout({ menuDateFilter, pickupTimeSelected }));
        } else {
          alert("Please select Pickup Time");
        }
      } else {
        if (deliveryLocation !== "") {
          var deliveryArea = cook.deliverySuburb;

          if (deliveryArea.includes(deliverySuburb)) {
            setIsCartOpen(false);
            dispatch(goCheckout({ menuDateFilter, pickupTimeSelected }));
          } else {
            alert(
              "Sorry, your delivery address is not in delivery area of this cook!"
            );
          }
        } else {
          alert("Please enter your delivery address");
        }
      }
    } else {
      alert(
        "Sorry, this chef is currently unavailable to receive order, please try again later!"
      );
    }
  };

  const handleCartToggle = () => {
    setIsCartOpen(!isCartOpen);
  };

  // set dateSelected with right format when date filter change
  useEffect(() => {
    if (menuDateFilter != "") {
      const date = new Date(menuDateFilter);
      var weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";

      var month = new Array();
      month[0] = "Jan";
      month[1] = "Feb";
      month[2] = "Mar";
      month[3] = "Apr";
      month[4] = "May";
      month[5] = "Jun";
      month[6] = "Jul";
      month[7] = "Aug";
      month[8] = "Sep";
      month[9] = "Oct";
      month[10] = "Nov";
      month[11] = "Dec";
      var today = new Date(todayDate);
      var tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      var dayjs_menuDateFilter = dayjs(date);
      if (tomorrow.toDateString() == menuDateFilter)
        setDateSelected(
          "Tomorrow, " +
            dayjs_menuDateFilter.date() +
            " " +
            month[date.getMonth()]
        );
      else
        setDateSelected(
          weekday[date.getDay()] +
            ", " +
            date.getDate() +
            " " +
            month[date.getMonth()]
        );
    }

    return () => {
      // cleanup
    };
  }, [menuDateFilter]);

  useEffect(() => {
    if (deliveryLocation && cook) {
      dispatch(calculateDistance());
    }
  }, [deliveryLocation, cook]);

  useEffect(() => {
    if (location.state && location.state.isCartOpen) {
      setIsCartOpen(true);
    }
  }, []);

  useEffect(() => {
    if (isCartOpen) document.body.classList.add("overflow_hidden_responsive");
    else document.body.classList.remove("overflow_hidden_responsive");
  }, [isCartOpen]);

  useEffect(() => {
    // Check if Current Cart Date is not within Cook's available date range
    if (
      cookAvailableDateRange &&
      !cookAvailableDateRange.includes(currentCart.deliveryDate) &&
      currentCart.deliveryDate
    ) {
      alert(
        "Sorry, this Chef is not available on your current selected delivery date, please choose another date and start ordering!"
      );
      console.log(
        "test cound",
        cookAvailableDateRange.includes(currentCart.deliveryDate)
      );
      console.log(cookAvailableDateRange);
      console.log(currentCart.deliveryDate);
      dispatch(clearCurrentCart());
    }
  }, [cookAvailableDateRange]);

  useEffect(() => {
    if (cook) {
      var deliveryArea = cook.deliverySuburb;
      if (!deliveryArea.includes(deliverySuburb)) {
        alert(
          "Sorry, your delivery address is not in delivery area of this cook!"
        );
      }
    }
  }, [deliverySuburb]);

  useEffect(() => {
    if (cook) {
      const payloadAction = {
        days: configDateRange.days,
        offset: configDateRange.offset,
        todayDate: todayDate,
      };
      dispatch(loadCookAvailableDateRange(payloadAction));
    }
  }, [cook]);

  useEffect(() => {
    // Check if is there a current cart going with another different cook
    if (currentCart.items.length > 0) {
      // Your current cart is NOT empty -- So check are you choosing the same cook?
      if (cookId !== saveCookId) {
        // NOT the same cook - warning that current cart will be discard.
        if (
          window.confirm(
            "You have a current cart with another cook, go to another cook's menu will discard your current one. Are you sure?"
          )
        ) {
          dispatch(cleanUpState());
          dispatch(getMenuBy(cookId));
        } else {
          // go back to home page
        }
      } else {
        // It's OK cause same cook selected - So start loading cook
        dispatch(getMenuBy(cookId));
      }
    } else {
      dispatch(getMenuBy(cookId));
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    return () => {
      // dispatch(cleanUpState());
      setIsCartOpen(false);
    };
  }, []);

  return (
    <div className="contentTopSpacing">
      {isLoading && <Loading isOverlay={true}></Loading>}

      {cook && (
        <div className={styles.cookMenu}>
          <div className={styles.mainContent}>
            {isCheckout ? (
              <Elements stripe={stripePromise}>
                <Checkout />
              </Elements>
            ) : (
              <>
                <div
                  className={styles.previewPhoto}
                  style={
                    cook.menuCoverPhoto && {
                      backgroundImage: 'url("' + cook.menuCoverPhoto.url + '")',
                    }
                  }
                ></div>
                <div className={styles.cookInfos}>
                  <div
                    className={styles.cookPhoto}
                    style={
                      cook.userPhoto && {
                        backgroundImage: 'url("' + cook.userPhoto.url + '")',
                      }
                    }
                  ></div>
                  <div className={styles.infoContent}>
                    <p className={styles.cookName}>
                      {cook.firstName}'s Kitchen{" "}
                      {cook.deliveryType === "Pick-up" && (
                        <span>Pickup Only</span>
                      )}
                    </p>
                    <p className={styles.cuisine}>
                      {cook.cuisines.replaceAll(",", ", ")}
                    </p>
                    <div className={styles.rating}>
                      {/* <i className="las la-star"></i><i className="las la-star"></i><i className="las la-star"></i><i className="las la-star"></i><i className="las la-star"></i>4.9 <span>(39)</span> */}
                    </div>
                    <div className={styles.cookIntro}>{cook.cookIntro}</div>
                  </div>
                </div>
                <MenuDateFilter />
                <div className={styles.menuWrap}>
                  <p className={styles.title_h4}>
                    Dishes available on <span>{dateSelected}</span>
                  </p>
                  <div className={styles.listWrap}>
                    {cook.menuDishes &&
                      cook.menuDishes.map((dish) => {
                        if (
                          dish.weeklyAvailability.filter(
                            (e) =>
                              e.day === menuDateFilter.substring(0, 3) &&
                              e.isAvailable
                          ).length > 0
                        ) {
                          return (
                            <DishCard
                              key={dish.objectId}
                              dish={dish}
                              linkTo={`${url}/${dish.objectId}`}
                              openMobileCart={setIsCartOpen}
                            />
                          );
                        }
                      })}
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className={`${styles.orderCartWrapper} ${
              isCartOpen && styles.opened
            }`}
          >
            <div className={styles.orderCart}>
              <div className={styles.close} onClick={handleCartToggle}>
                <i className="las la-times"></i>
              </div>
              <div className={styles.cartContentWrapper}>
                <div className={styles.cartTop}>
                  <p className={styles.orderTitle}>
                    <span>Your order with</span>
                    {cook.firstName}'s kitchen
                  </p>
                </div>
                <div className={styles.cartItemWrapper}>
                  {currentCart.items.length > 0 ? (
                    <div className={styles.cartContent}>
                      <p className={styles.deliveryDate}>
                        Delivery on <strong>{dateSelected}</strong>
                      </p>
                      {currentCart.items.map((item) => {
                        return <CartItem key={item.id} item={item} />;
                      })}
                      {/* <div className={styles.fees}>
                    <div>Delivery Fee</div>{" "}
                    <div>${fees.delivery.toFixed(2)}</div>
                  </div> */}
                    </div>
                  ) : (
                    <>
                      <img className={styles.noimage} src={NoImage} alt="" />
                      <p className={styles.emptyMess}>
                        Your cart is currently empty
                      </p>
                    </>
                  )}
                  {!isCheckout && currentCart.items.length > 0 && (
                    <>
                      {cook.deliveryType === "Pick-up" ? (
                        <>
                          <div className={styles.timePickupWrapper}>
                            <PickUpTimeSelection
                              onChange={(value) => {
                                setPickupTimeSelected(value);
                              }}
                            ></PickUpTimeSelection>
                          </div>
                          <div className={styles.infoWrapper}>
                            <div className={styles.info}>
                              <p className={styles.label}>Pickup Location:</p>
                              <div className={styles.innerWrapper}>
                                {deliveryLocation ? (
                                  <>
                                    <i className="las la-people-carry"></i>{" "}
                                    <span>~{distance} km </span> from{" "}
                                    <em onClick={handleOpenLocationModal}>
                                      {deliveryLocation}
                                    </em>
                                    <p className={styles.locationNote}>
                                      For privacy, correct pickup address will
                                      be presented after you place order.
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className={styles.inputLocation}
                                      onClick={handleOpenLocationModal}
                                    >
                                      <i className="las la-map-marker"></i>
                                      Select your current location to get
                                      estimated distance for pickup
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={styles.infoWrapper}>
                            <div className={styles.info}>
                              <p className={styles.label}>Delivery To:</p>
                              <div
                                className={`${styles.innerWrapper} ${styles.blueText}`}
                              >
                                {deliveryLocation ? (
                                  <>
                                    <i className="las la-shipping-fast"></i>{" "}
                                    <em onClick={handleOpenLocationModal}>
                                      {deliveryLocation}
                                    </em>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className={styles.inputLocation}
                                      onClick={handleOpenLocationModal}
                                    >
                                      <i className="las la-map-marker"></i>
                                      Please enter your delivery address to
                                      proceed to checkout
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className={styles.cartBottom}>
                  {currentCart.items.length > 0 && (
                    <>
                      {!isCheckout && (
                        <>
                          <div
                            className={styles.placeOrder}
                            onClick={handleGoCheckout}
                          >
                            <SubmitButton
                              text={`Go to Checkout | $${currentCart.total.toFixed(
                                2
                              )}`}
                              isCenter={true}
                            ></SubmitButton>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.orderCartButton} onClick={handleCartToggle}>
            <i className="las la-shopping-bag"></i>
            <div className={styles.messWrapper}>
              <div>View Cart ({currentCart.itemCount})</div>
            </div>
          </div>
        </div>
      )}
      <Switch>
        <Route path={`${path}/:dishId`} component={FoodModal} />
      </Switch>
    </div>
  );
};

export default CookMenu;
